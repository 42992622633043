.productTermsPopup-container{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: rgba(51, 51, 51, 0.63);
    z-index: 50;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.productTermsPopup-content{
    width: 80%;
    max-width: 700px;
    max-height: 90dvh;
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 12px;
    min-width: 320px;
}

.productTermsPopup-description{
    font-size: 14px;
    font-weight: 500;
    white-space: pre-wrap;
}