.supplier-popup-close-wrapper{
    position:fixed;
    background-color:grey;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.33;
    z-index: 12;
}
.supplier-popup-wrapper{
    display: flex;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 460px;
    height: 460px;
    z-index: 13;
    opacity: 1;
    padding: 0;
    background-color: white;
    border-radius: 23px;
}
.supplier-popup-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    padding: 0;
    width: 100%;
}
.supplier-popup-title{

    align-items: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    color: #333333;
    max-width: 375px;
    overflow: hidden;
    text-align: center;
}

.supplier-popup-image{
    width: 277px;
    height: 277px;
    filter: drop-shadow(0px 4px 20px rgba(51, 51, 51, 0.08));
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}
.supplier-popup-button{
    width: 225px;
    height: 48px;

    background: #333333;
    border-radius: 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 48px;
    text-align: center;
    overflow: hidden;
    color: #FFFFFF;
    cursor: pointer;
}