.termsPopup-container{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: rgba(51, 51, 51, 0.63);
    z-index: 50;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.termsPopup-content{
    font-size: 16px;
    width: 50%;
    max-width: 700px;
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 12px;
}

.termsPopup-content > #checkbox-label{
    font-size: 18px;
    margin: 10px auto;

}

.termsPopup-description{
    font-family: "Montserrat", sans-serif;
    display: flex;
    width: 100%;
    height: 300px;
    padding: 15px;
    border-radius: 12px;
    border: 1px solid #B6BABD;
    line-height: 21px;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    outline: none;
    resize: none;
}

.termsPopup-button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #333333;
    border-radius: 12px;
    max-width: 50%;
    margin: 0 auto;
    height: 48px;
    font-family: "Montserrat", sans-serif;
    line-height: 22px;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
    color: #fff;
    border: 1px solid #333333;
    transition: 300ms;
}

.termsPopup-button:hover{
    color: #333333;
    background: #FFFFFF;
}

.termsPopup-bonuses{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.termsPopup-input{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.termsPopup-input-hint{
    font-size: 11px;
    color: #7a7a7a;
}