.chat-unread-messages {
    border: solid 2px #FF4804;
    border-radius: 40px;
    color: black;
    font-size: 12px;
    font-weight: 500;
    margin-left: auto;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chat-image {
    width: 20px;
    height: 20px;
    margin-right: 4px;
}
.chat-profile-img{
    width: 20px;
    height: 20px;
    object-fit: cover;
    border-radius: 50%;
}
.chat-name {
    font-family: 'Montserrat';
    max-width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    text-align: start;
    white-space: nowrap;
    color: #000000;
}

.chat-message {
    font-weight: 300;
    font-size: 10px;
    line-height: 22px;
    color: #000000;
    white-space: nowrap;
    max-width: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
}
.chat-card-unread:hover{
    background: rgb(236, 236, 236);
}