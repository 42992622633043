.container{
  margin: 30px 0;
}

.buttons{
  width: 30%;
  margin-top: 10px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: end;
}

.button{
  all: unset;
  cursor: pointer;
  font-family: Montserrat, "sans-serif";
  font-weight: 500;
  text-align: center;
  width: min-content;
  height: min-content;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #333333;
  padding: 15px 15px;
  background-color: #333333;
  transition: 300ms;
  color: white;
}

.button:hover{
  background-color: white;
  color: #333333;
}

.button[disabled]{
  cursor: not-allowed;
  color: white;
  background-color: #8e8e8e;
}

.tableButtons{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.tableButtons > img {
  cursor: pointer;
}

.buttonEdit{
  all: unset;
  cursor: pointer;
  font-family: Montserrat, "sans-serif";
  font-weight: 500;
  text-align: center;
  width: min-content;
  height: min-content;
  font-size: 12px;
  border-radius: 10px;
  border: 1px solid #333333;
  padding: 5px 10px;
  background-color: #333333;
  color: white;
}

.buttonEdit:hover{
  background-color: white;
  color: #333333;
}