.container {
  width: inherit;
  height: inherit;
}

.header {
  padding-top: 22px;
  padding-bottom: 11px;
  background-color: white;
}

.headerH {
  font-size: 14px;
  line-height: 17px;
  text-align: center;
}

.headerTitle {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  width: 234px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.clearFilter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > button {
    all: unset;
    margin-top: 15px;
    font-size: 18px;
    cursor: pointer;
    background-color: #FFFFFF;
    border-radius: 12px;
    color: #333333;
    border: 1px solid #333333;
    padding: 10px 15px 10px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    > img{
      width: 20px;
      margin-right: 10px;
    }
  }
}

.background {

}

.cart {
  background: url("../../../../../public/Pictures/korzina.png");
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 220px;
  display: block;
  height: 220px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 34px;
}