#favorite-img-default {
    width: 20px;
    height: 20px;
    /*padding-left: 30px;*/
    /*margin-left: -30px;*/
    /*padding-bottom: 30px;*/
    /*margin-bottom: -30px;*/
    object-fit: fill;
    position: absolute;
    right: 0;
}
#favorite-img-Cart {
    /*padding-left: 40px;*/
    /*padding-bottom: 20px;*/
    /*padding-top: 20px;*/
    /*margin-top: 20px;*/
    /*position: absolute;*/
    /*right: 40px;*/
    width: 20px;
    height: 20px;
    margin-left: auto;
}