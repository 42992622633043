.admin-filter-basic{
    padding: 6px 18px;
    margin: 0 9px 0px 0;
    font-size: 14px;
    color: white;
    background-color: grey;
    border-radius: 20px;
}
.admin-filters-container{
    margin-top:7px;
}
.admin-filters-container > .dropdown-list-div{
    max-height: 130px;
}
.admin-filter-button-1{
    width: 170px;
    padding: 8px;
    margin-bottom: 50px;
    text-align: center;
    margin-top: 30px;
}
.admin-filter-button-2{
    margin-right: 20px;
    padding: 8px;
    margin-bottom: 50px;
    width: 200px;
    text-align: center;
}