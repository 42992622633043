.myorders-columns{
    vertical-align: top;
}
.myorders-first-column{
    padding-top: 0;
    width: 2%;
}
.myorders-second-column{
    width: 58%;
    padding-left: 3px;
    text-align: left;
}
.myorders-third-column{
    width: 20%;
}
.myorders-fourth-column{
    width: 20%;
    text-align: right;
    margin-left: 20px;
}
.myorders-dropdown-wrapper{
    min-height: 20px;
    width: 100%;
}
.myorders-header-button-wrapper{
    display: flex;
}
.myorders-header-item-title{
    font-weight: 600;
}
.myorders-header-button{
    width: 8px;
    height: 9px;
    padding-top: 1px;
    margin-top: 3px;
    padding-left: 5px;
}
.myorders-main-wrapper{
    display: flex;
    margin-top: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
}
.myorders-table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 8px;
}