.supDropdownListDiv {
  height: max-content;
  position: absolute;
  top: 100%;
  z-index: 3;
  font-size: 14px;
  font-weight: 400;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #B6BABD;
  background-color: white;
  border-radius: 10px;
  min-width: 100%;
  box-sizing: border-box;
  max-height: 200px;
  overflow: auto;

}

.supDropdownListDivContent{
  overflow-y: auto;
  max-height: 120px;
  margin-right: 5px;

}

.supDropdownDisplay{
  box-sizing: border-box;
  position: relative;
  padding: 11px 5px 11px 9px;
  font-size: 14px;
  font-weight: 500;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #B6BABD;
  appearance: auto;
  border-radius: 7px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 9px;

}
.supDropdownDisplayText{
  height: max-content;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: initial;
  text-align: center;
}
.supDropdownListDiv::-webkit-scrollbar {
  display: none;
}
.supDropdownListDiv > div:first-child{
  margin-top:5px;
}
.supDropdownListDiv > div:last-child{
  margin-bottom:5px;
}
.supDropdownListDiv[data-hide="true"]{
  display:none;
}
.supDropdownListDiv[data-hide="false"]{
  display:block;
}
.supDropdownListDiv[data-size="small"]{
  margin-top: 15px;
  padding: 10px 0;
}

.supDropdownItemName{
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 20px;
  height: auto;
  overflow-y: hidden;
  font-weight: 600;
  text-overflow: ellipsis;
  position: relative;
  z-index: 3;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 8px;
  margin-left: 5px!important;
  margin-right: 5px;

}
.supDropdownLocation{
  justify-content: start;
}
.supDropdownItemNameDisable{
  cursor: not-allowed;
  color: rgba(51, 51, 51, 0.49);
}
.supDropdownItemName:hover{
  background-color: #B6BABD50;
}
.supDropdownInternalContainer{
  flex: none;
  display: block;
  position: relative;
  width: 100%;
  margin-top: 2rem;
}
.supDropdownLocation{
  position: absolute;
  left: 0;
  margin-left: 24px;
  color: #333333;
  top: calc(50% - 7px);
}
.supDropDownImage{
  position: absolute;
  right: 0;
  margin-right: 24px;
  color: #333333;
  top: calc(50% - 5px);
}
.supDropDownImage[data-hide = "false"]{
  rotate: -180deg;
  transition: 300ms;
}
.supDropDownImage[data-hide = "true"]{
  transition: 300ms;
}

