.moderationReklama{
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 1000px;
    margin: 0 auto;
}

.moderationReklama-types{
    margin: 20px auto 0 auto;
    display: inline-flex;

    gap: 20px;
}

.moderationReklama-types button{
    all: unset;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    text-align: center;
    padding: 5px 0;
    width: 200px;
    border: 1px solid #333333;
    border-radius: 12px;
    cursor: pointer;
}

.moderationReklama-types button[data-active="true"]{
    background-color: #333333;
    color: #FFFFFF;
}

.moderationReklama-types button:hover{
    background-color: rgba(51, 51, 51, 0.82);
    color: #FFFFFF;
}

.moderationReklama-openAll button{
    all: unset;
    margin-top: 20px;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    text-align: center;
    padding: 5px 0;
    width: 200px;
    border: 1px solid #333333;
    border-radius: 12px;
    cursor: pointer;
}

.moderationReklama-openAll button[data-active="true"]{
    background-color: #333333;
    color: #FFFFFF;
}

.moderationReklama-openAll button:hover{
    background-color: rgba(51, 51, 51, 0.82);
    color: #FFFFFF;
}

.moderationReklama-reklama-container{
    width: 100%;
    display: flex;
    flex-direction: column;

}

.moderationReklama-reklama-header{
    cursor: pointer;
    margin-top: 30px;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

.moderationReklama-reklama-header div:first-child::after{
    content: url("/public/Pictures/ArrowBottomBlack.svg");
    position: absolute;
    top: 5%;
    rotate: -90deg;
    margin-left: 20px;
}

.moderationReklama-reklama-container[open] .moderationReklama-reklama-header div:first-child::after{
    rotate: 0deg;
    top: -10%;
}

.moderationReklama-reklama{
    margin-top: 20px;
    border: 1px solid #989898;
    border-radius: 12px;
    padding: 20px 40px;
    display: inline-flex;
    gap: 40px;
}

.moderationReklama-reklama-hint{
    font-size: 16px;
}

.moderationReklama-reklama-images{
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 20px;
    min-width: 50%;
}

.moderationReklama-reklama-photo-image-desktop,
.moderationReklama-reklama-photo-image-mobile{
    margin-top: 10px;
    width: 100%;
    border: 1px solid #333333;
    border-radius: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.moderationReklama-reklama-photo-image-desktop{
    aspect-ratio: 3;
}

.moderationReklama-reklama-photo-image-mobile{
    width: 50%;
    aspect-ratio: 1.95;
}

.moderationReklama-reklama-photo-image-mobile[data-type="1"]{
    width: 100%;
}

.moderationReklama-reklama-photo-image-desktop img,
.moderationReklama-reklama-photo-image-mobile img{
    width: 100%;
    object-fit: fill;
}

.moderationReklama-reklama-photo-image-desktop img{
    aspect-ratio: 3;
}

.moderationReklama-reklama-photo-image-mobile img{
    aspect-ratio: 1.95;
}

.moderationReklama-reklama-photo-image-dragEnter{
    border-color: transparent;
    outline: 3px dashed black;
    outline-offset: 0;
}

.moderationReklama-reklama-image-text{
    z-index: -1;
}

.moderationReklama-reklama-preview-buttons{
    margin-top: 10px;
    width: 100%;
    display: inline-flex;
    justify-content: start;
    gap: 20px;
}

.moderationReklama-reklama-preview-buttons button{
    all: unset;
    cursor: pointer;
    height: 35px;
    border-radius: 10px;
    border: 1px solid #333333;
    padding: 0 10px;
}

.moderationReklama-reklama-preview-buttons button:hover{
    background: #333333;
    color: #FFFFFF;
}

.moderationReklama-reklama-settings{
    width: calc(50% - 40px);
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.moderationReklama-reklama-setting{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.moderationReklama-reklama-setting textarea{
    all: unset;
    font-family: "Montserrat", sans-serif;
    display: flex;
    min-width: 90%;
    height: 106px;
    padding: 15px;
    border-radius: 12px;
    border: 1px solid #B6BABD;
    line-height: 21px;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    outline: none;
    resize: none;
}

.moderationReklama-reklama-settings-buttons{
    width: 100%;
    display: inline-flex;
    justify-content: end;
    gap: 20px;
}

.moderationReklama-reklama-settings-buttons button,
.moderationReklama-reklama-settings-buttons a{
    all: unset;
    white-space: nowrap;
    cursor: pointer;
    height: 35px;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    padding: 0 20px;
    background-color: #333333;
    color: #FFFFFF;
    border-radius: 12px;
}

.moderationReklama-reklama-settings-buttons button:hover{
    background-color: rgba(51, 51, 51, 0.82);
    color: #FFFFFF;
}

.moderationReklama-reklama-settings-buttons button[disabled]{
    cursor: not-allowed!important;
    background-color: #7A7A7A!important;
}
