.supplierMagazines{
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

.supplierMagazines-add-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
}

.supplierMagazines-add{
    font-weight: 500;
    padding: 4px 12px;
    border-color: #333333;
    border-style: solid;
    border-radius: 20px;
    border-width: 1px;
    max-width: 150px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
}

.supplierMagazines-add:hover{
    color: white;
    background-color: #333333;
}

.supplierMagazines-region{
    width: 100%;
    background-color: #fafafa;
    border-radius: 15px;
    margin: 10px 0;
    padding: 10px 10px;
    box-sizing: border-box;
}

.supplierMagazines-region > h2{
    font-size: 24px;
}

.supplierMagazines-city > h3{
    font-weight: 500;
}

.supplierMagazines-edit{
    padding: 0 5px;
    border: 1px solid #333333;
    border-radius: 11px;
}

.supplierMagazines-tableButtons{
    display: flex;
    gap: 5px;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end;
}

.supplierMagazines-edit:hover{
    background-color: #333333;
    color: white;
}

.supplierMagazines-delete{
    height: 24px;
    cursor: pointer;
}
.supplierMagazines-delete > img:last-child{
    display: none;
}
.supplierMagazines-delete:hover > img:first-child{
    display: none;
}
.supplierMagazines-delete:hover > img:last-child{
    display: block;
}

.supplierMagazines-terms-cell{
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.supplierMagazines-terms-cell > img{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.ant-table-cell:has(.supplierMagazines-deliveryPoints-container){
    padding: 10px 15px!important;
    background-color: white!important;
}

.supplierMagazines-deliveryPoints-container{
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.supplierMagazines-deliveryPoints{
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    border-radius: 11px;
    padding: 0 5px;
    background-color: #FFFFFF;
    border: 1px solid #333333;
}

.supplierMagazines-deliveryPoints-button-edit{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #333333;
    border-radius: 100%;
    width: 20px;
    height: 20px;
}

.supplierMagazines-deliveryPoints > .supplierMagazines-delete{
    display: flex;
    align-items: center;
}

.supplierMagazines-deliveryPoints > .supplierMagazines-delete > img{
    width: 20px !important;
    height: 20px !important;
}

.supplierMagazines-deliveryPoints-item-add{
    cursor: pointer;
    width: 20px;
    height: 20px;
}