.reservationReklama{
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.reservationReklama-types{
    display: inline-flex;
    gap: 20px;
    margin: 0 auto;
}

.reservationReklama-types-sec{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}

.reservationReklama-type{
    all: unset;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    text-align: center;
    padding: 5px 0;
    width: 200px;
    border: 1px solid #333333;
    border-radius: 12px;
    cursor: pointer;
    white-space: nowrap;
}

.reservationReklama-type:hover{
    background-color: rgba(51, 51, 51, 0.82);
    color: #FFFFFF;
}

.reservationReklama-type[data-sel="true"]{
    background-color: #333333;
    color: #FFFFFF;
}

.reservationReklama-type[data-sel="true"][data-onlyMy="true"]{
    background-color: #c7efef;
    color: #333333;
}

.reservationReklama-type[data-onlyMy="true"]:hover{
    background-color: rgba(204, 237, 237, 0.59);
    color: #333333;
}

.reservationReklama-type[data-sel="true"][data-hidden="hide"]{
    visibility: hidden;
}

.reservationReklama-type[disabled]{
    background-color: #7A7A7A!important;
    color: #FFFFFF!important;
    cursor: not-allowed;
}

.reservationReklama-hint-text{
    font-size: 15px;
}

.reservationReklama-types-regions{
    float: left;
}

.reservationReklama-types-regions .reservationReklama-type{
    margin: 2px;
    width: min-content;
    padding: 5px 5px;
}

.reservationReklama-types-regions .reservationReklama-type:first-child{
    width: 150px;
}

.reservationPopup{
    box-sizing: border-box;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(51, 51, 51, 0.62);
}

.reservationPopup-content{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 13;
    width: 65%;
    max-width: 800px;

    max-height: 500px;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.reservationPopup-title{
    width: 100%;
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    font-size: 24px;
}

.reservationPopup-title img{
    width: 12px;
}

.reservationPopup-title p{
    white-space: nowrap;
}

.reservationPopup-reservation{
    width: 100%;
    display: inline-flex;
    gap: 20px;
    overflow: hidden;
    flex: 1;
}

.reservationPopup-reservation-userReklamas-container{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.reservationPopup-reservation-userReklamas{
    width: 100%;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.reservationPopup-reservation-userReklamas[data-region="global"]{
    max-height: 200px;
}

.reservationPopup-reservation-userReklama{
    width: 100%;
    max-width: 100%;
    font-size: 18px;
    border-bottom: 1px solid #333333;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    position: relative;
    cursor: pointer;
    min-height: 56px;
}

.reservationPopup-reservation-userReklama > div{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: right;
}

.reservationPopup-reservation-userReklama > div > span{
    font-size: 10px;
}

.reservationPopup-reservation-userReklama[data-dis="true"]{
    cursor: not-allowed;
    background: rgba(204, 237, 237, 0.59);
    color: #333333;
}

.reservationPopup-reservation-userReklama:first-child{
    border-top: 1px solid #333333;
}

.reservationPopup-reservation-userReklama:hover{
   /* background: rgba(51, 51, 51, 0.31);*/
}

.reservationPopup-reservation-userReklama[data-sel="true"]{
    background: #333333!important;
    color: #FFFFFF!important;
}

.reservationPopup-reservation-userReklama > img{
    margin-left: 10px;
    cursor: pointer;
    width: 25px;
}

.reservationPopup-reservation-preview-button{
    all: unset;
    cursor: pointer;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    height: 32px;
    padding: 0 10px;
    background: #333333;
    color: #FFFFFF;
    border-radius: 10px;
    margin-right: 10px;
}

.reservationPopup-reservation-userReklama[data-sel="true"] .reservationPopup-reservation-preview-button{
    background: #FFFFFF;
    color: #333333;
}

.reservationPopup-reservation-slides{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    padding-right:5px;
}

.reservationPopup-reservation-slide{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.reservationPopup-reservation-slide-title{
    font-size: 16px;
}

.reservationPopup-reservation-slide-preview{
    overflow: hidden;
    border: 4px solid transparent;
    border-radius: 12px;
    height: 160px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-align: center;
    position: relative;
}

.reservationPopup-reservation-slide[data-sel="true"] .reservationPopup-reservation-slide-preview{
    border: 4px solid #7A7A7A;
}

.reservationPopup-reservation-slide-preview-image{
    width: 100%;
    height: 160px;
    object-fit: fill;
}

.reservationPopup-reservation-slide-preview-star{
    all: unset;
    position: absolute;
    top: 2%;
    right: 2%;
}

.reservationPopup-reservation-apply{
    display: inline-flex;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.reservationPopup-reservation-apply button{
    all: unset;
    cursor: pointer;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    height: 32px;
    padding: 0 20px;
    background: #333333;
    color: #FFFFFF;
    border-radius: 10px;
}

.reservationPopup-reservation-apply button[disabled]{
    background: #7A7A7A;
    color: #FFFFFF;
    cursor: not-allowed;
}

.reservationPopup-reservation-bonuses{
    display: inline-flex;
    font-size: 14px;
}

.reservationPopup-createNewReklama {
    height: 32px;
    min-height: 32px;
    border: 1px solid #333;
    border-radius: 10px;
    margin: 5px auto;
    padding: 0px 10px;
    white-space: nowrap;
    width: min-content;
    font-size: 16px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.reservationPopup-createNewReklama:hover{
    background-color: #333333;
    color: #FFFFFF;
}

.reservstionPopup-check{
    display: flex;
    flex-direction: row!important;
    gap: 10px;
}
.reservstionPopup-check > img{
    width: 30px;
}