
.cat-header {
	padding: 2px 0;
	border-style: solid;
	border-width: 1px 0 0 0;
	border-color: #ECECEC;
}

.cat-header:last-child {
	padding: 2px 0;
	border-style: solid;
	border-width: 1px 0 1px 0;
}

.cat-summary {
	display: flex;
	margin: 9px;
	padding: 5px 40px 5px 14px;
	border-radius: 7px;
	font-size: 14px;
	position: relative;
}

.cat-summary-text {

}

.cat-links {

}

.cat-link-box {
	padding: 7px 20px 7px 30px;
	font-weight: 500;
	max-width: 300px;
	position: relative;
}

.cat-link-box:last-child {
	padding: 5px 20px 15px 30px;
}

.cat-link-box:after {
	content: url("../../../../public/Pictures/strelochka.svg");
	transform: scale(-0.7, 0.6);
	position: absolute;
	right: -10px;
}

#swiper-container-catalog {
	padding-top: 15px;
	padding-bottom: 15px;
}

#swiper-container-catalog > .swiper {
	box-sizing: border-box;
	margin: 0 -20px !important;
}

.swiper-container-catalog > .swiper > .swiper-wrapper {
	align-items: center;
}

summary {
	list-style: none;
}

.cat-header[open] summary {
	background-color: #ECECEC;
}

summary::-webkit-details-marker {
	display: none;
}

.cart-title {
	margin-left: auto;
	margin-right: auto;
	padding: 0 25px;
	text-align: center;
}

#cart-title-div {
	justify-content: inherit;
}

.cat-header summary::after {
	content: url("../../../../public/Pictures/arrow-down.svg");
	position: absolute;
	right: 24px;
	/* you can style it however you want, use background-image for example */
}

.view-selector-shadow {
	/*box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);*/
}

.cat-header[open] summary::after {
	content: url("../../../../public/Pictures/arrow-up.svg");
	position: absolute;
	right: 24px;
}

#card-container {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	row-gap: 20px;
	/*display: grid;
	grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));*/
	padding: 20px 0 0;
	/*grid-row-gap: 20px;
	justify-items: center;*/
}

#viewSelectorBox {
	display: flex;
	align-items: center;
	margin-left: 10px;
	margin-right: 10px;
	position: sticky;
	top: 134px;
	z-index: 40;
	background-color: #FFFFFF;
}

.viewSelectorItem {
	margin: 2px;
}

.catalog_searchBox {
	padding: 5px 0px 5px;
}

.catalog_searchBox .Searchbar .SearchbarInner .SearchbarInput {
	font-family: 'Montserrat';
	font-size: 14px;
}

.catalog_searchBox .Searchbar {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px;
	width: 100%;
}

#CatSelect[data-isadmin="true"] {
	padding: 16px 130px 15px;
}

.filterSelectionBoxForCatalog {

}

.filterButtonBoxForCatalog {
	min-width: 356px;
	max-width: 600px;
	margin-top: 40px;
	background-color: white;
}

.filterPopupButton {
	font-weight: 600 !important;
}

.filterForCatalog-categories-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.filterForCatalog-category {
	width: 100%;
	margin-right: 5px;
	margin-bottom: -16px;
}

.filterForCatalog-hint-text {
	margin: 5px 0 5px 0;
	font-weight: 400;
	font-size: 16px;
	line-height: 14px;
}

.filterForCatalog-dropDown {
	height: 60px;
	max-width: 600px;
}

.admin-filter-for-caralog-button-1 {
	margin: auto;
	padding: 8px;
	margin-bottom: 50px;
	margin-top: 2px;
	width: 150px !important;
	text-align: center;
}

.admin-filter-for-caralog-button-2 {
	margin: auto;
	padding: 8px;
	margin-bottom: 50px;
	margin-top: 2px;
	width: 150px !important;
	text-align: center;
}

.admin-filter-for-caralog-button-close {
	margin-left: calc(100% - 20px);
	padding: 2px;
	margin-bottom: -4px;
	margin-top: 20px;
	width: 8px !important;
	height: 17px;
	font-size: 16px !important;
}

/*@keyframes filter-popup-animation {*/
/*    from {*/
/*        left: calc(-100vw);*/
/*    }*/
/*    to {*/
/*        left: 0px;*/
/*    }*/
/*}*/

.filter-container {
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
	height: 30px;
}

.filter-sel-index {
	background-color: #fb7070;
	padding: 3px 8px;
	border-radius: 11px;
	font-size: 14px;
	color: #FFFFFF;
}

.filter-sel-resetBtn {
	all: unset;
	cursor: pointer;
	background-color: #FFFFFF;
	border-radius: 7px;
	color: #333333;
	border: 1px solid #333333;
	padding: 4px 5px 4px 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
}

.filter-sel-resetBtn > img {
	width: 15px;
}

.filterPopupElement {
	display: flex;
	justify-content: center;
	position: fixed;
	background-color: white;
	top: 50px;
	width: 100%;
	height: 100%;
	left: 0px;
	z-index: 29;
	overflow-y: scroll;
	transition: all linear 0.5s;
}

.filter-open {
	left: 0;
}

.filter-close {
	left: -100%;
}

.filterPopupElement::-webkit-scrollbar {
	display: none;
}

.filterPopupElement-container {
	margin: 10px;
	min-width: 356px;
	max-width: 600px;
	overflow-y: scroll;
	scrollbar-width: none;
	overscroll-behavior: contain;
	-ms-overflow-style: none;
}

.filterPopupElement-container::-webkit-scrollbar {
	display: none;
}

.visibleAfterSecond {
	animation-duration: 3s;
	animation-name: visibleAfterS1;
}

@keyframes visibleAfterS1 {
	0% {
		opacity: 0;
	}
	90% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

#CatSelectBox > #navigate-title-div {
	width: 100%;
	display: inline-grid !important;
	grid-template-columns: 1fr 1fr 1fr !important;
	
}

#CatSelectBox > #navigate-title-div > img {
	position: unset;
}

#CatSelectBox > #navigate-title-div > .find-div {
	margin: 0 auto;
}

@media screen and (max-width: 450px) {
	#CatSelectBox > #navigate-title-div > .find-div {
		min-width: unset;
		max-width: unset;
	}
	
	#CatSelectBox > #navigate-title-div {
		margin-top: 5px;
	}
	
	.catalog_searchBox .Searchbar {
		padding: 0;
		width: 100%;
	}
	
	#swiper-container-catalog .categories-div {
		height: 24px;
	}
	
	#swiper-container-catalog > .swiper {
		padding: 15px 20px !important;
	}
	
	#card-container {
		justify-content: center;
	}
}

.sticky-search-filter {
	position: sticky;
	padding-top: 2px;
	margin-left: -20px;
	margin-right: -20px;
	padding-bottom: 7px;
	margin-top: -7px;
	z-index: 29;
	background-color: #FFFFFF;
}

.sticky-search-filter > .catalog_searchBox,
.sticky-search-filter > .filter-container,
.sticky-search-filter > #viewSelectorBox
{
	padding: 0 20px;
}

.sticky-search-filter[data-isauth='true' ] {
	top: 84px
}

.sticky-search-filter[data-isauth='false' ] {
	top: 52.5px
}

@media screen and (max-width: 450px) {
	.sticky-search-filter{

	}
}