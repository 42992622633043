.burgerIcon_dealer {
    width: 32px;
    height: 32px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #333333;
    position: relative;
    transition: 0.5s; /* Добавлено свойство transition */
    box-sizing: border-box;
}

.burgerIcon_dealer span,
.burgerIcon_dealer span::before,
.burgerIcon_dealer span::after {
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 50%;
    margin-left: -7px;
    width: 14px;
    height: 2px;
    background-color: #333333;
    transition: inherit; /* Унаследован transition от .burgerIcon_dealer */
}

.burgerIcon_dealer span::before,
.burgerIcon_dealer span::after {
    content: '';
    display: block;
}

.burgerIcon_dealer span::before {
    transform: translateY(-6px);
}

.burgerIcon_dealer span::after {
    transform: translateY(6px);
}

.burgerIcon_dealer:hover {
    transform: rotate(90deg);
}

.burgerIcon_dealer:hover span::before {
    transform: rotate(35deg);
    transform-origin: 12px 8px;
    width: 8px;
}

.burgerIcon_dealer:hover span::after {
    transform: rotate(-35deg);
    transform-origin: 12px -7px;
    width: 8px;
}

.burgerBackendTransparent_dealer{
    /*position:fixed;*/
    background-color:grey;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    animation-name: toTransparent;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
}
.burgerBackendGrey_dealer{
    position:fixed;
    background-color:grey;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    animation-name: toGrey;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
}
@keyframes toGrey {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.33;
    }
}
@keyframes toTransparent {
    0% {
        opacity: 0.33;
        z-index: 3;
    }
    99% {
        opacity: 0;
        z-index: 3;
    }
    100% {
        z-index: -1;
        background-color:white;
    }
}
.burgerWrapperClose_dealer{
    display: block;
    width: 292px;
    max-height: 100vh;
    background-color: white;
    position: absolute;
    top: 0px;
    left: -292px;
    z-index: 3;
    list-style-type: none;
    transition: transform 0.5s
}
.burgerWrapperOpen_dealer {
    padding: 10px 10px 20px;
    display: block;
    width: 292px;
    position: absolute;
    top: 0;
    left: -292px;
    z-index: 4;
    list-style-type: none;
    transform: translate(100%, 0);
    transition: transform 0.5s;
    background: #FFFFFF;
    border: 1px solid #333333;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
}

.a-link_burger_dealer {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 5px 15px;
    background: #F5F5F5;
    border-radius: 10px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    box-sizing: border-box;
}

.burgerLi_dealer {
    width: 100%;
}

.a-link_burger_footer_dealer {
    justify-content: center;
}

.burgerFooter_dealer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.img_nameLi_container_dealer {
    display: flex;
    align-items: center;
    gap: 15px;
}

.img_nameLi_container_dealer > img {
    width: 22px;
    object-fit: contain;
}

.burgerListCenter_dealer {
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    padding: 10px 0 0;
    margin-bottom: 10px;
}

.burgerWrapperClose_dealer li{
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}
.burgerPadding_dealer {
    max-height: calc(100vh - 20px - 158px);
    overflow-y: scroll;
    margin-top: 158px;
}
#seller-burger-scroll::-webkit-scrollbar{
    width: 0px;
    background-color: white;
}
.burger-close_dealer {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 1px solid #333333;
    transition: 0.5s; /* Добавлено свойство transition */
    box-sizing: border-box;
}

.burger-close_dealer span,
.burger-close_dealer span::before,
.burger-close_dealer span::after {
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 50%;
    margin-left: -7.5px;
    width: 15px;
    height: 2px;
    background-color: #333333;
    transition: inherit; /* Унаследован transition от .burgerIcon_dealer */
}

.burger-close_dealer span {
    height: 0;
}

.burger-close_dealer span::before {
    transform: rotate(45deg);
}

.burger-close_dealer span::after {
    transform: rotate(-45deg);
}

.burger-close_dealer span::before,
.burger-close_dealer span::after {
    content: '';
    display: block;
}

.burger-close_dealer:hover {
    background-color: #333333;
}

.burger-close_dealer:hover span::before,
.burger-close_dealer:hover span::after {
    background-color: #FFFFFF;
}

.burgerProfile_dealer{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}
.burgerFullName_dealer{
    display: flex;
    margin-left: 0;
    flex-direction: column;
    align-items: center;
}

.burgerProfilePictures_dealer{
    width: 60px;
    height: 60px;
    filter: drop-shadow(0px 13px 5px rgba(0, 0, 0, 0.01)) drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.02)) drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.04)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.04));
    border-radius: 100px;
}

.dealer {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */


    color: #333333;
}

.DealerName {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */


    color: #333333;
}
