.training-wrapper {
    width: 100%;
    padding-bottom: 30px;
    overflow: hidden;

    box-sizing: border-box;
}

.training-container {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: auto;

    margin: 0 auto;
    max-width: 1440px;
}

.training-navigateBack{
    position: absolute;
    left: 40px;
    top: 95px;
    z-index: 10;
    width: 15px;
    cursor: pointer;
}

.training-header {
    margin-top: 20px;
    margin-bottom: 20px;
}

.training-header img {
    display: inline-block;
    rotate: 180deg;
    height: 27px;
    width: 12px;
    float: left;
}

.training-header p {
    display: flex;
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.training-dropdowns {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-column-gap: 20px;
    margin-bottom: 30px;
    grid-row-gap: 15px;
    margin-top: 20px;
}

.training-dropdowns > .dropdown-internal-container {
    height: 60px;
}

.training-dropdown, .training-dropdown-none {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 10px;
}

.training-dropdown p, .training-dropdown-none p {
    font-size: 1em;
    font-weight: 400;
    margin-left: 1px;
}

.training-dropdown-block {
    display: grid;
    grid-auto-columns: 1fr auto;
    grid-auto-flow: column;
    grid-column-gap: 10px;
}

.training-dropdown-block button {
    display: none;
    border: 1px solid #B6BABD;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    line-height: 18px;
    background: #fff;
    font-weight: 500;
    cursor: pointer;
    font-size: 18px;
    padding: 10px 20px;
}

.training-calendar {
    margin-bottom: 20px;
}

.training-footer {
    display: grid;
    grid-auto-flow: row;
}

.training-footer-btn a {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #333333;
    border-radius: 12px;
    margin-bottom: 20px;
    padding: 18px 24px;
    line-height: 22px;
    font-weight: 700;
    font-size: 22px;
    cursor: pointer;
    color: #fff;
}

.training-footer-event {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 20px;
}

@media (max-width: 991px) {
    .training-dropdowns {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
    }

    .training-dropdown-block button {
        display: flex;
    }
}

@media (max-width: 768px) {
    .training-container {
        display: block;
    }

    .training-footer-btn a {
        padding: 14px 22px;
        font-size: 20px;
    }

    .training-header p {
        font-size: 30px;
    }
}

/*@media (max-width: 600px) {
    .training-dropdowns {
        grid-row-gap: 10px;
        margin-bottom: 20px;
    }

    .training-dropdowns > .dropdown-internal-container {
        height: 50px;
    }

    .training-dropdowns > .dropdown-internal-container > .DropdownDisplay {
        height: 50px;
    }

    .training-dropdowns > .dropdown-internal-container > .dropdown-list-div {
        margin-top: 25px !important;
    }

    .training-footer-btn a {
        padding: 12px 20px;
        font-size: 18px;
        margin-bottom: 10px;
    }

    .training-header p {
        font-size: 28px;
    }
}*/

@media (max-width: 450px) {

    .training-container > .training-footer {
        display: block;
    }

    .training-container > .training-footer > .trainingCart-wrapper > .trainingCart-container {
        display: block;
    }

    .training-container > .training-footer > .trainingCart-wrapper > .trainingCart-container > .trainingCart-bottom {
        display: flex;
        flex-direction: column;
    }

    .training-container > .training-footer > .trainingCart-wrapper > .trainingCart-container > .trainingCart-bottom > .trainingCart-bottom-block  {
        display: flex;
        margin-left: 0px;
        flex-direction: column;
        flex: 1;
    }

    .trainingCart-image-container {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 25px;
    }

    .training-dropdowns {
        grid-row-gap: 5px;
        margin-bottom: 5px;
    }

    .training-dropdowns > .dropdown-internal-container {
        height: 32px;
    }

    .training-dropdowns > .dropdown-internal-container > .DropdownDisplay {
        font-size: 16px;
        height: 32px;
        border-radius: 10px;
        margin-bottom: 0px;
    }

    .training-dropdowns > .dropdown-internal-container > .dropdown-list-div {
        margin-top: 15px !important;
    }
    .training-header{
        margin: 5px 0;
    }
    .training-header p{
        font-size: 20px;
        line-height: 20px;
    }
    .training-calendar{
        margin-bottom: 10px;
    }
    .training-navigateBack{
        top: 85px;
        width: 10px;
    }
}

@media (max-width: 1200px) {

    .training-container {
        padding: 0 16px
    }
    .training-navigateBack{
        left: 10px;
    }
}

@media (max-width: 1200px) {
    .training-container {
        padding: 0 16px
    }
}