.container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: auto;

}

.viewContainer {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.viewItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.viewItemActive {
  color: #01aba2;

  > svg {
    > rect, > line {
      stroke: #01aba2 !important;
    }
  }
}

.filter {
  width: 250px;
  flex: 1;
  padding: 10px 5px 10px 10px;
  border-radius: 12px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  height: calc(100dvh - 150px);
}

.filterLoader{
  top: 0;
  left: 0;
  background: #FFFFFF80;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex!important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 10;
  pointer-events: auto;
}

.filterTitleContainer {
  display: flex;
  width: 100%;
}

.filterTitle {
  font-weight: 500;
  font-size: 16px;
  margin-right: 12px;
}
.filterIndicator {
  height: 16px;
  width: 16px;
  background: #da0000;
  color: white;
  border-radius: 100%;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterButtonIndicator{
  height: 18px;
  width: 18px;
  background: #da0000;
  color: white;
  border-radius: 100%;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterContent {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  overflow: auto;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 5px;
  }
}

.filterButtons{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;

  > button{
    all: unset;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 30px;
    background-color: #333333;
    border: 1px solid #333333;
    color: #FFFFFF;
    padding: 0 5px;
    border-radius: 12px;
  }
}


.filterItemsContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: #eeeeee;
  border-radius: 12px;
  padding: 5px;
  height: auto;
  
  .filterItemsTitle {
    font-size: 14px;
    margin-right: 10px;
  }
  
  .filterItems {
    max-height: 90px;
    overflow: auto;
    
    .filterItem, .filterItemDisable {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: start;
      gap: 5px;
      justify-content: start;
      font-weight: 500;
    }
    
    .filterItemDisable {
      opacity: 80%;
    }
  }
  
  .filterItems::-webkit-scrollbar {
    width: 4px;
  }
}

.filterItemsTitleSel{
  color: #01aba2!important;
}
.searchText {
  align-self: center;
  font-size: 12px;
}
