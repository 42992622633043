.container{
	box-sizing: border-box;
	width: 100%;
	display: -webkit-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex;
	background-color: white;
	border: 1px solid rgb(220 220 220);
	border-radius: 9px;
	padding: 4px;
	margin-bottom: 5px;
	align-items: center;
}
.input {
	height: 20px;
	margin-left: 10px;
	position: relative;
	border-style: none;
	width: calc(100% - 50px);
	font-size: 14px;
}

.input::-webkit-search-cancel-button {
	-webkit-appearance: none;
	appearance: none;
}

.input::-moz-search-clear-button {
	display: none;
}

.input::-ms-clear {
	display: none;
}

.input:focus{
	outline: 0;
}

.searchbarLogo1 {
	width: 10px;
}

.searchbarLogo2{
	width: 15px;
}