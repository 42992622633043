.myorders-main-div{
    position: relative;
    display: flex;
    justify-content: space-between;
}
.myorders-location{
    padding-bottom: 3px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #2F2121;
}

.myorders-index{
    white-space: nowrap;
    padding-bottom: 3px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #2F2121;
}

.myorders-status-red{
    margin-bottom: 2px;
    padding: 0 11px 0 12px;
    height: 24px;
    line-height: 23px;
    font-size: 12px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    color: #F7F7F7;
    background-color: #C38989;
    border-radius: 41px;
}
.myorders-status-green{
    background-color: #508E46;
}
.myorders-cancel{
    position: absolute;
    right: 0;
    top: 11px;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
    text-decoration-line: underline;
}
.myorders-date-item-string{
    margin-top: 4px;
    text-align: center;
    font-weight: 400;
    font-size: 9px;
    line-height: 10.97px;
    color: #333333;
}
.myorders-amount{
    display: flex;
    flex-direction: row;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}

.myorders-bonuses{
    color: #01aba2;
    margin-left: 10px;
    white-space: nowrap;
}

.myorders-supplier-div{
    display: flex;
    position: relative;
    vertical-align: bottom;
    margin: 6px 0 34px 0;
}
.myorders-payment-button{
    width: 151px;
    position: absolute;
    bottom: -30px;
    right: 0;
}
.myorders-supply-company{
    position: relative;
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 11px;
    color: #2F2121;
}
.myorders-supply-img{
    position: absolute;
    bottom:1px;
    width: 15px;
    height: 14px;
}
.myorders-more{
    position: absolute;
    right:0;
    top: 7px;
    width: 117px;
    height: 24px;
    line-height: 23px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    background-color: #333333;
    color: #F7F7F7;
    border-radius: 41px;
}
.myorders-date{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #333333;
}