/* Основные стили для страницы */
.myOrdersClosed{
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.myOrdersClosed-filters {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.myOrdersClosed-date-filters {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.myOrdersClosed-date-filters input[type="date"] {
    background-color: #fff;
    border: 1px solid #b6babd;
    border-radius: 7px;
    box-sizing: border-box;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 42px;
    line-height: 21px;
    padding: 3px 2px 3px 9px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
}

.myOrdersClosed-date-filters button {
    cursor: pointer;
    background: #333333;
    border: 1px solid #333333;
    border-radius: 50px;
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;
    width: min-content;
    white-space: nowrap;
}

.myOrdersClosed-date-filters button:hover {
    background-color: #FFFFFF;
    color: #333333;
}

/* Адаптивность для полей фильтрации */
@media (min-width: 768px) {
    .myOrdersClosed-filters {
        align-items: center;
    }

    .myOrdersClosed-date-filters {
        flex-direction: row;
    }

    .myOrdersClosed-date-filters input[type="date"] {
        width: 200px;
    }
}

/* Стили для итоговой суммы */
.myOrdersClosed-footer {
    font-size: 18px;
    font-weight: bold;
    text-align: right;
    padding-top: 15px;
}

/* Адаптивность таблицы и заголовков */
.myOrdersClosed-table {
    width: 100%;
    overflow-x: auto;
}

.myOrdersClosed-title{
    width: 100%;
    font-size: 32px;
    text-align: center;
}

@media (max-width: 600px) {
    .myOrdersClosed{
        gap: 10px;
    }

    .myOrdersClosed-title{
        font-size: 18px;
    }

    .myOrdersClosed-header {
        flex-direction: column;
        align-items: flex-start;
    }
    .myOrdersClosed-date-filters{
        gap: 5px;
    }
    .myOrdersClosed-date-filters input[type="date"],
    .myOrdersClosed-date-filters button {
        box-sizing: border-box;
    }

    .myOrdersClosed-filters {
        flex-direction: column;
    }

    .myOrdersClosed-filters >  .Searchbar{
        padding: 5px!important;
    }

    .myOrdersClosed-footer {
        text-align: center;
    }
}

.myOrdersClosed-footer-bonuses{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.myOrdersClosed-footer-bonuses-sum{
    color: #01aba2;
}