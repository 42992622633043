.dropdownLocation-container{
    position: relative;
    height: 40px;
    width: 300px;
}

.order-header-item-dd-locations{
    position: relative;
    width: 23em;
    cursor: pointer;
}
.dropdown-menu-wrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 40px;
    background-color: white;
    border-radius: 12px;
    padding: 0 5px;
    border: 1px solid #333333;
    width: 100%;
}

.header-wrapper {
    display: inline-flex;
    align-items: center;

    font-weight: 500;
    font-size: 16px;
    padding: 0 5px;
    justify-content: space-between;
    /*max-width: 178px;*/
    /*border: 1px solid #333333;*/
    width: 100%;
    box-sizing: border-box;

}
.header-location-button {
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
}
.header-button {
    border: none;
    background-color: #FFFFFF;
    cursor: pointer;
}
.location-header-show-button{
    width: 8px;
    height: 8px;
    padding-bottom: 3px;
}
.items-wrapper {
    position: absolute;
    z-index: 10;
    top: 100%;
    width: 100%;
    margin-top: 10px;
    background-color: #FFFFFF;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 13px;
    /*overflow: hidden;*/
    border-radius: 10px;
    box-shadow: rgba(51, 51, 51, 0.5) 0 1px 10px;
}
.items-list {
    /*gap: 4px;*/
    /*scrollbar-width: thin;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*gap: 3px;*/
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-height: 120px;
    overflow-y: auto;
}
.items-location-button{
    /*overflow: hidden;*/
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
}
.item {
    justify-content: space-between;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 10px;
    font-size: 13px;
    padding: 4px 7px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
}
.item:hover {
    background-color: #dadada;
}
.items-drop-button {
    font-size: 12px;
}

.item-kind-of-delivery {
    /*height: 27px;*/
    /*width: 23px;*/
}
.item-img-of-kind{
    padding: 2px;
    height: 20px;
    width: 20px;
}
.item_changeAdress {
    font-size: 12px;
    padding: 5px 8px;
    justify-content: center;
}

@media screen and (max-width: 450px){
    .dropdownLocation-container{
        width: 100%;
    }
}