$colorGray: #333333;

.suppliersCitiesWrapper{
    scrollbar-color: gray #e0e0e0;
    scrollbar-width: thin;
}
.supCard{
    padding: 20px 0 20px 0;
}
.supData{
    padding: 0.8rem 1.4rem 0.8rem 1.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: 0.5rem;
    background-color:#F5F5F5;
    margin-bottom:1rem;
    margin-top: 1rem;
}
.supName{
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;

}
.supAddress{
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
}
.supContacts{
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
}
.supTelephone{
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    white-space: nowrap;
}
.supButton{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    border: 1px solid $colorGray;
    border-radius: 19px;

}
.supChat{
    width: 150px;
    height: 28px;

}
.supInfo{
    width: 280px;
    height: 28px;
}
.supButton:hover,.supButton:active{
    background-color: $colorGray;
    color: white;
    transition: 0.3s;

}
.supCompanyItemInfoIcon{
    padding-left: 0.5rem;
    width: 1.9rem;

}


.linkWrapper {
    text-decoration: none;
    transition: color 0.3s;

}

.linkWrapper:hover .infoIcon {
    color: white;
}

.infoIcon {
    padding-left: 0.5rem;
    color: black;
    transition: color 0.3s ease
}
.supLinks{
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: end;
}
.supInfo{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.supIndex{
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
    width: 2%;
}


.supCompanyName{
    width: 55%;
    padding-left: 0.5rem;
}
.supTelephone{
    width: 25%;
}
.supCompanyList{
    overflow:auto;
    height: calc(100dvh - (53px + 18px + 150px));
}
.supNotFound{
    flex-direction: column;
    width: 100%;
    justify-content: center;
    display: flex;
    height: calc(100dvh - (53px + 18px + 150px));
    align-items: center;
}
.wrapperNotification{
    z-index: 100;
    position: absolute;
    display: flex;
    opacity: 0;
    //left: 100%;
    left: 40%;
    top: 0;
    width: 20%;
    background-color: #333333;
    color: #ffffff;
    border-radius: 1.5rem;
    padding: 0.5rem;
    //margin-left: 2rem;
    margin-top: 1rem;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: opacity 0.3s ease;
    pointer-events: none;
}
@media (max-width : 450px){
    .wrapperNotification{
        border-radius: 2rem;
        padding: 1rem;
        //margin-left: 2rem;
        margin-top: 1rem;
        width: 50%;
        left: 25%;
        top: 0;
    }

    .supData{
        text-align: start;
        display: flex;
        flex-flow: column wrap;
    }
    .supLinks{
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
    }
    .supTelephone{
        width: 100%;
    }
    .linkWrapper{
        width: 100%;

    }
    .supChat{
        margin: 0 auto;
        min-width: min-content;
        width: 50%;
        font-size: 14px;
    }

    .supInfo{
        display: flex;
        width: 100%;
        font-size: 14px;
        margin-bottom: 1rem;
    }
    .supCompanyName{
        width: 100%;

    }
    .supIndex{
        width: 2%;
    }
    .supHr{
        margin: 7px 0 7px 0;
    }
    .supTelephone{
        font-size: 14px;
        line-height: 14px;
    }
    .supCompanyList{
        overflow:scroll;
        height: calc(100dvh - (53px + 18px + 150px));
    }
    .supNotFound{
        width: 100%;
        justify-content: center;
        display: flex;
        height: calc(100dvh - (53px + 18px + 80px));
        align-items: center;
    }
}
