.custom-input{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    position: relative;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    opacity: 1;
    cursor:pointer;
    display:block;
    width:15px;
    height: 25px;
    text-align:center;
    position:relative;
    background: transparent url("/public/Pictures/spinInputButton.svg") no-repeat 50% 50%;
}
input[type=number][data-numberbuttons="false"]::-webkit-inner-spin-button{
    display: none;
}

.custom-input-field{
    font-family: "Montserrat", sans-serif;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    height:100%;
    background-color: #fff;
    border: 1px solid #B6BABD;
    border-radius: 10px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #333333;
    padding-left: 21px;
    box-sizing: border-box;
}
.custom-input-field[data-size="small"]{
    box-sizing: border-box;
    height: 48px;
    font-size: 16px;
    border-radius: 10px;
}
.custom-input-field[data-size="large"]{
    box-sizing: border-box;
    height: 60px;
    font-size: 18px;
    border-radius: 12px;
}
.custom-input-image{
    position: absolute;
    right: 0;
    margin-right: 21px;
    color: #333333;
}
.сustom-input-error{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: 0;
    margin-right: 50px;
}
.сustom-input-error-image{
    color: #333333;
    width: 20px;
    height: 20px;
}
.сustom-input-error-text{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 150%;
    right: -150%;
    max-width: 250px;
    background-color: #FFFFFF;
    padding: 5px 10px;
    color: #333333;
    border-radius: 6px;
    z-index: 1;
    text-align: center;
    visibility: hidden;
    border: 1px solid #db2020;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    white-space: nowrap;

}
/*.сustom-input-error-text::after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    bottom: 100%;*/
/*    left: 50%;*/
/*    margin-left: -5px;*/
/*    border-width: 5px;*/
/*    border-style: solid;*/
/*    border-color:transparent  transparent #333333 transparent;*/
/*}*/
.сustom-input-error:hover .сustom-input-error-text{
    visibility: visible;
}
.сustom-input-error:target .сustom-input-error-text{
    visibility: visible;
}

.productDetails-inputField{
    height: 48px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    box-sizing: border-box;
}


