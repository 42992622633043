#popup-close-wrapper{
    position:fixed;
    background-color:grey;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.33;
}
#popup-wrapper{
    display: flex;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 460px;
    z-index: 13;
    opacity: 1;
    padding: 0;
    background-color: white;
    border-radius: 23px;
}
#popup-container{
    display: block;
    align-items: center;
    position: relative;
    padding: 0;
    width: 100%;
}
#popup-title{
    margin: 20px auto 20px auto;
    align-items: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    color: #333333;
    max-width: 375px;
    overflow: hidden;
    text-align: center;
}
#popup-text{
    width: 70%;
    margin-left: 15%;
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
}
#popup-product-card{
    padding-left: 117px;
    padding-right: 118px;
    justify-content: center;
}
#popup-button{
    width: 225px;
    height: 48px;
    background: #333333;
    border-radius: 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 48px;
    text-align: center;
    overflow: hidden;
    color: #FFFFFF;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}