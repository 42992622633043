.CalendarWrapper {
    padding: 20px;
    border: 1px solid #000000;
    border-radius: 12px;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    box-sizing: border-box;
}

.CalendarItem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: auto;
    aspect-ratio: 5/4;
    border: 2px solid transparent;
    border-radius: 12px;
}

.Calendar-font {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
}

.Calendar-Holiday {
    color: #333333;
}

.Calendar-PastDays {
    color: #333333;
}

.Calendar-FutureDays {
    color: #333333;
}

.CDayOfTheWeekTh {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #333333;
    margin: 0 auto;
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    color: #FFFFFF;
    height: 60px;
}

.CDayOfTheWeekThPN {
    border-radius: 12px 0 0 12px;
    background: #333333;
}

.CDayOfTheWeekThSB {
    border-radius: 12px 0 0 12px;
    background: #606060;
}

.СBackgroundColorSB {
    background: #333333;
    z-index: 0;
}

.CDayOfTheWeekThVSK {
    background: #606060;
    border-radius: 0 12px 12px 0;
}

.CTBody:before {
    content: "@";
    display: block;
    line-height: 19px;
    text-indent: -99999px;
}

#CDayTd {
    width: 22px;
    height: 22px;
}

#CimgLocation {
    width: 14px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
}

.CLocationWrapper {
    display: flex;
    white-space: nowrap;
}

.CalendarContainer {
    display: grid;
    grid-template-columns: 36px 250px 36px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    grid-column-gap: 10px;
}

.CimgLocationWrapper {
    display: flex;
    justify-content: center;
    padding: 10px;
    border: 1px solid #333333;
    border-radius: 100px;

}

#CLocationText {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    user-select: text;
}

.CimgChevron {
    width: 24px;
    height: 24px;
    margin-top: auto;
    margin-bottom: auto;
}

.CimgChevronLeft {

}

.CimgChevronRight {

}

.CEmptyDiv {
}

#CTextMonth {
    white-space: nowrap;
    width: 120px;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
}

.CTheadWrapper {
    border-radius: 12px;
    background-color: black;
    width: 100%;
}

#CDay {
    width: 10%;
    aspect-ratio: 5/4;
}

.CEventDiv {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 0;
    right: 0;
    top: 0;
    margin-right: 5%;
    margin-top: 5%;
}

.CBorderNone {
    border: none;
}

.BgEvent {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #E0E0E0;
    border: 6px solid #C2C2C2;
    border-radius: 10px;
    position: relative;
}

.Calendar-eventCount-img{
    max-width: 35px;
}

@media (max-width: 768px) {
    .Calendar-font{
        font-size: 18px;
    }
    .Calendar-eventCount{
        font-size: 15px!important;
    }
    .CDayOfTheWeekTh{
        height: 50px;
    }
    .CLocationWrapper{
        display: none;
    }
    .CEmptyDiv{
        display: none;
    }
    .CalendarContainer{
        display: flex;
        justify-content: center;
    }
    .Calendar-eventCount-img {
        max-width: 20px;
    }
}
@media (max-width: 600px) {
    .Calendar-font{
        font-size: 12px;
    }
    .Calendar-eventCount-text{
        font-size: 8px;
    }
    .CDayOfTheWeekTh{
        height: 40px;
        font-size: 18px;
    }
    #CTextMonth{
        font-size: 20px;
    }
    .CimgChevron{
        width: 20px;
        height: 20px;
    }
    .CalendarWrapper{
        padding: 5px;
    }
    .Calendar-eventCount-img {
        max-width: 15px;
    }
}
@media (max-width: 450px) {
    .CalendarContainer{
        margin-bottom: 5px;
    }
    .CTBody:before{
        line-height: 5px!important;
    }
    .Calendar-font{
        font-size: 12px;
    }
    .CEventDiv{
        margin-top: -2px;
    }
    .CDayOfTheWeekThPN{
        border-radius: 9px 0 0 9px;
    }
    .CDayOfTheWeekThSB{
        border-radius: 9px 0 0 9px;
    }
    .CDayOfTheWeekThVSK{
        border-radius: 0 9px 9px 0;
    }
    .Calendar-eventCount-text{
        font-size: 6px;
    }
    .Calendar-eventCount-img {
        max-width: 10px;
    }

    .BgEvent{
        border-width: 3px;
        border-radius: 5px;
    }
    .CalendarItem{
        border-width: 1px;
        border-radius: 7px;
    }
    .CDayOfTheWeekTh{
        font-size: 10px;
        height: 16px;
    }
    #CTextMonth{
        font-size: 18px;
    }
    .CimgChevron{
        width: 18px;
        height: 18px;
    }
}