.adminReservationReklama{
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.adminReservationReklama-types{
    display: inline-flex;
    gap: 20px;
    margin: 0 auto;
}

.adminReservationReklama-types-sec{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}

.adminReservationReklama-type{
    all: unset;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    text-align: center;
    padding: 5px 0;
    width: 200px;
    border: 1px solid #333333;
    border-radius: 12px;
    cursor: pointer;
    white-space: nowrap;
}

.adminReservationReklama-type:hover{
    background-color: rgba(51, 51, 51, 0.82);
    color: #FFFFFF;
}

.adminReservationReklama-type[data-sel="true"]{
    background-color: #333333;
    color: #FFFFFF;
}

.adminReservationReklama-type[disabled]{
    background-color: #7A7A7A!important;
    color: #FFFFFF!important;
    cursor: not-allowed;
}

.adminReservationReklama-types-regions{
    float: left;
}

.adminReservationReklama-types-regions .adminReservationReklama-type{
    margin: 2px;
    width: min-content;
    padding: 5px 5px;
}

.adminReservationPopup{
    box-sizing: border-box;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(51, 51, 51, 0.62);
}

.adminReservationPopup-content{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 13;
    width: 80%;
    max-width: 800px;
    max-height: 500px;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    gap: 20px;
}

.adminReservationPopup-title{
    width: 100%;
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    font-size: 24px;
}

.adminReservationPopup-title img{
    width: 12px;
}

.adminReservationPopup-title p{
    white-space: nowrap;
}

.adminReservationPopup-reservation{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    flex: 1;
}

.adminReservationPopup-reservation-slides{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    padding-right:5px;
}

.adminReservationPopup-reservation-slide{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.adminReservationPopup-reservation-preview{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.adminReservationPopup-reservation-reklama{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
    margin-left: 20px;
}

.adminReservationPopup-reservation-slide-title{
    font-size: 16px;
}

.adminReservationPopup-reservation-slide-preview{
    overflow: hidden;
    border: 4px solid transparent;
    border-radius: 12px;
    height: 160px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.adminReservationPopup-reservation-slide[data-sel="true"] .adminReservationPopup-reservation-slide-preview{
    border: 4px solid #7A7A7A;
}

.adminReservationPopup-reservation-slide-preview img{
    width: 100%;
    height: 160px;
    object-fit: fill;
}

.adminReservationPopup-reservation-reklama-title{
    margin-top: 30px;
    padding: 25px 0;
    width: 100%;
    text-align: center;
    font-size: 20px;
    border-bottom: 1px solid #333333;
    border-top: 1px solid #333333;
    background: #D9D9D9;
}

.adminReservationPopup-reservation-reklama-buttons{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.adminReservationPopup-reservation-reklama-buttons button:first-child{
    all: unset;
    cursor: pointer;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    height: 32px;
    padding: 0 20px;
    background: #333333;
    color: #FFFFFF;
    border-radius: 10px;
    border: 1px solid transparent;
}

.adminReservationPopup-reservation-reklama-buttons button{
    all: unset;
    cursor: pointer;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    height: 32px;
    padding: 0 20px;
    background: #FFFFFF;
    color: #333333;
    border: 1px solid #333333;
    border-radius: 10px;
}

.adminReservationPopup-reservation-reklama-buttons button:hover{
    background: #7A7A7A;
    color: #FFFFFF;
    border: 1px solid transparent;
}

.adminReservationPopup-reservation-reklama-buttons img{
    cursor: pointer;
}