.selectProduct{
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.selectProduct-searchbar{
  font-size: 18px;
}

.selectProduct-searchbar > .Searchbar{
  width: 100%;
  box-sizing: border-box;
}

.selectProduct-searchbar > .Searchbar > .SearchbarInner > .SearchbarInput{
  font-size: 16px;
}

/*Searchbar*/
.SearchbarSP .Searchbar {
  width: 100%;

  padding: 0;
  margin: 10px 0 0 0;
  height: 48px;

  border: 1px solid #B6BABD;
  border-radius: 12px;
  background-color: white;
  display: flex;
  align-items: center;
}

.SearchbarSP .SearchbarInner{
  background-color: white;
  border-radius:12px;
  width: 100%;
  height: 94%;
  padding: 0 0 0 10px;
}

.SearchbarSP .SearchbarInput {
  position: relative;
  height: 100%;

  /*Текст*/
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
}

.SearchbarSP .SearchbarLogo {
  margin: 0;
  padding: 0;

}

/*h1 h2*/

.TitleTextAndSVG {
  display: flex;
}

.SVGArrowTitle {
  display: flex;
}

.H1Title {
  margin: 0 auto;
}

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 100%;

  text-align: center;

  color: #333333;
}

h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;

  text-align: center;

  color: #333333;
}

/*Текст*/

.label_text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #333333;
  margin-bottom: 10px;
}

.inp_txt {
  margin-top: 5px;
  border: 1px solid #b6babd;
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
  height: 48px;

  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #333333;
  padding-left: 15px;
}

.inp_txt::placeholder {
  color: #333333;
}

/*Дропдауны*/

.frame_category {
  width: 100%;
  margin-top: 10px;
  display: inline-grid;
  grid-template-columns: 1fr 1fr min-content;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.frame {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;

  box-sizing: border-box;
}

/*Создать новый товар*/

.cont_NewProduct {
  display: flex;

}

.cont_NewProduct p{
  display: flex;
  align-content: center;
  align-items: center;
}

.btn_NewProduct {
  all: unset;

  width: 230px;

  background: #333333;
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  height: 48px;
  margin-bottom: 9px;
}

.btn_NewProduct:hover{
  background-color: rgba(51, 51, 51, 0.84);
  color: #FFFFFF;
}

.product_card_container{
  margin-left: 5px;
}

.selectProduct-dropBlock{

}

.selectProduct-dropBlock > summary{
  padding: 5px 30px 5px 10px;
  border: 1px solid #333333;
  border-radius: 10px;
  width: min-content;
  white-space: nowrap;
  position: relative;
  display: inline-flex;
  height: 48px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.selectProduct-dropBlock > summary > span::after{
  content: url("/public/Pictures/ArrowBottomBlack.svg");
  position: absolute;
  top: 30%;
  rotate: 0;
  margin-left: 10px;
}

.selectProduct-dropBlock[open] > summary> span::after{
  content: url("/public/Pictures/ArrowBottomWhite.svg");
  rotate: -90deg;
  top: 30%;
}

.selectProduct-dropBlock[open] summary{
  background-color: #333333;
  color: #FFFFFF;
}

.selectProduct-dropBlock > summary:hover{
  background-color: rgba(51, 51, 51, 0.84);
  cursor: pointer;
  color: #FFFFFF;
}

.selectProduct-dropBlock > summary:hover > span::after{
  content: url("/public/Pictures/ArrowBottomWhite.svg");
}

.selectProduct-allProducts{
  width: 100%;
  margin: 0 auto;
  height: 650px;
  overflow: visible;
  overflow-y: auto;
  overflow-x: hidden;
  /*display: flex;
  flex-wrap: wrap;
  gap: 2%;
  align-items: center;
  justify-content: left;*/

  display: grid;
  grid-auto-flow: row;
  grid-template-rows: repeat(auto-fill,200px);
  grid-template-columns: repeat(auto-fill,min(25%,180px));
  justify-content: space-between;
  box-sizing: border-box;
  gap: 5px;
  padding: 15px;
}

.selectProduct-allProducts > .swiper-slide{

}
