.custom-ant-table {
    border-radius: 10px;

}

.custom-ant-table .ant-table-thead > tr > th {
    background-color: #F3F3F3;
    text-align: center;
    font-family: 'Montserrat', sans-serif !important;
}

.custom-ant-table .ant-table-thead > tr > .ant-table-cell::before{
    background-color: #a5a5a5 !important;
}

.custom-ant-table .ant-table-thead > tr > th,
.custom-ant-table .ant-table-tbody > tr {
    transition: background-color 0.3s;
}

.custom-ant-table .ant-table-tbody > tr:hover {
    background-color: #f5f5f5;
}

.custom-ant-table .ant-table-tbody > tr > td {
    font-weight: 400!important;
}

.custom-ant-table .ant-table-container{
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
}

.custom-ant-table .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
}

.custom-ant-table .ant-table-tbody > tr > td::before{
    position: absolute;
    top: 50%;
    inset-inline-end: 0;
    width: 1px;
    height: 1.6em;
    background-color: #a5a5a5;
    transform: translateY(-50%);
    transition: background-color 0.2s;
    content: "";
}

.custom-ant-table .ant-table-tbody > tr > td:last-child::before{
    all: unset;
}