.supplierReklama{
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 1000px;
    margin: 0 auto;
}

.supplierReklama-types{
    margin: 20px auto 0 auto;
    display: inline-flex;

    gap: 20px;
}

.supplierReklama-types button{
    all: unset;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    text-align: center;
    padding: 5px 0;
    width: 200px;
    border: 1px solid #333333;
    border-radius: 12px;
    cursor: pointer;
}

.supplierReklama-types button[data-active="true"]{
    background-color: #333333;
    color: #FFFFFF;
}

.supplierReklama-types button:hover{
    background-color: rgba(51, 51, 51, 0.82);
    color: #FFFFFF;
}

.supplierReklama-openAll button{
    all: unset;
    margin-top: 20px;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    text-align: center;
    padding: 5px 0;
    width: 200px;
    border: 1px solid #333333;
    border-radius: 12px;
    cursor: pointer;
}

.supplierReklama-openAll button[data-active="true"]{
    background-color: #333333;
    color: #FFFFFF;
}

.supplierReklama-openAll button:hover{
    background-color: rgba(51, 51, 51, 0.82);
    color: #FFFFFF;
}

.supplierReklama-reklama-container{
    width: 100%;
    display: flex;
    flex-direction: column;

}

.supplierReklama-reklama-header{
    cursor: pointer;
    margin-top: 30px;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

.supplierReklama-reklama-header div:first-child::after{
    content: url("/public/Pictures/ArrowBottomBlack.svg");
    position: absolute;
    top: 5%;
    rotate: -90deg;
    margin-left: 20px;
}

.supplierReklama-reklama-container[open] .supplierReklama-reklama-header div:first-child::after{
    rotate: 0deg;
    top: -10%;
}

.supplierReklama-reklama{
    margin-top: 20px;
    border: 1px solid #989898;
    border-radius: 12px;
    padding: 20px 40px;
    display: inline-flex;
    gap: 40px;
}

.supplierReklama-reklama-hint{
    font-size: 16px;
    display: flex;
    flex-direction: column;
}

.supplierReklama-reklama-hint > div{
    font-size: 10px;
    color: #7A7A7A;
}

.supplierReklama-reklama-images{
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 20px;
}

.supplierReklama-reklama-photo-image-desktop,
.supplierReklama-reklama-photo-image-mobile{
    margin-top: 10px;

    border: 1px solid #333333;
    border-radius: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.supplierReklama-reklama-photo-image-desktop{
    aspect-ratio: 3;
    width: 100%;
}

.supplierReklama-reklama-photo-image-mobile{
    aspect-ratio: 1.95;
    width: 50%;
}

.supplierReklama-reklama-photo-image-mobile[data-type="1"]{
    width: 100%;
}

.supplierReklama-reklama-photo-image-desktop img,
.supplierReklama-reklama-photo-image-mobile img{
    width: 100%;
    object-fit: fill;
}

.supplierReklama-reklama-photo-image-desktop img{
    aspect-ratio: 3;
}

.supplierReklama-reklama-photo-image-mobile img{
    aspect-ratio: 1.95;
}

.supplierReklama-reklama-photo-image-dragEnter{
    border-color: transparent;
    outline: 3px dashed black;
    outline-offset: 0;
}

.supplierReklama-reklama-image-text{
    z-index: -1;
    text-align: center;
    font-size: smaller;
}

.supplierReklama-reklama-preview-buttons{
    margin-top: 10px;
    width: 100%;
    display: inline-flex;
    justify-content: end;
    gap: 20px;
}

.supplierReklama-reklama-preview-buttons button{
    all: unset;
    cursor: pointer;

    height: 35px;
}

.supplierReklama-reklama-preview-buttons button:first-child{
    border-radius: 8px;
    background-size: 35px;
    padding: 0 10px;
    border: 1px solid #333333;
}

.supplierReklama-reklama-preview-buttons button:last-child{
    width: 35px;
    border-radius: 8px;
    background: url("/public/Pictures/editWhite.svg") no-repeat center center;
    background-color: #333333;
}

.supplierReklama-reklama-preview-buttons button[disabled]:last-child{
    cursor: not-allowed!important;
    background-color: #7A7A7A!important;
}

.supplierReklama-reklama-settings{
    flex:1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.supplierReklama-reklama-setting{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.supplierReklama-reklama-setting > .custom-input > .custom-input-field{
    height: 40px!important;
}

.supplierReklama-reklama-setting textarea{
    all: unset;
    font-family: "Montserrat", sans-serif;
    display: flex;
    min-width: 90%;
    height: 73px;
    padding: 15px;
    border-radius: 12px;
    border: 1px solid #B6BABD;
    line-height: 21px;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    outline: none;
    resize: none;
}

.supplierReklama-reklama-hint-status{
    font-size: 14px;
}

.supplierReklama-reklama-hint-status[data-status="0"]{
    color: #7A7A7A;
}

.supplierReklama-reklama-hint-status[data-status="1"]{
    color: red;
}

.supplierReklama-reklama-hint-status[data-status="2"]{
    color: green;
}

.supplierReklama-reklama-hint-status[data-status="3"]{
    color: yellow;
}

.supplierReklama-reklama-hint-status[data-status="4"]{
    color: red;
}

.supplierReklama-reklama-settings-buttons{
    width: 100%;
    display: inline-flex;
    justify-content: end;
    gap: 20px;
}

.supplierReklama-reklama-settings-buttons button{
    all: unset;
    cursor: pointer;
    height: 35px;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    padding: 0 20px;
    background-color: #333333;
    color: #FFFFFF;
    border-radius: 12px;
    white-space: nowrap;
}

.supplierReklama-reklama-settings-buttons button:hover{
    background-color: rgba(51, 51, 51, 0.82);
    color: #FFFFFF;
}

.supplierReklama-reklama-settings-buttons button[disabled]{
    cursor: not-allowed!important;
    background-color: #7A7A7A!important;
}

.supplierReklama-reklama-delete-button{
    background: url("/public/Pictures/Trash-white.svg") no-repeat center center!important;
    background-color: #e00000 !important;
    border: 1px solid #333333!important;
}

.supplierReklama-reklama-delete-button:hover{
    background-color: rgba(224, 0, 0, 0.73) !important;
}