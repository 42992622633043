.popUpTrainingAttenders-wrapper {
	width: 100%;
	position: fixed;
	top:0;
	left: 0;
	background-color: rgba(51, 51, 51, 0.63);
	z-index: 20;
	height: 100%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
}


.popUpTrainingAttenders-container{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	position: relative;
	padding: 20px 40px;
	width: 600px;
	border-radius: 12px;
	min-height: 500px;
	max-height: 500px;
	background: #fff;
}
.popUpTrainingAttenders-title {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 30px;
	margin-bottom: 20px;
}
.popUpTrainingAttenders-title p{
	color: #333;
	text-align: center;
	font-family: Montserrat;
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: 100%;
}
.popUpTrainingAttenders-title img{
	cursor: pointer;
}

.popUpTrainingAttenders-list{
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	overflow: auto;
}
.popUpTrainingAttenders-item{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 20px;
	border-radius: 10px;
	background: rgb(245, 245, 245);
	height: 40px;
	padding: 8px 20px;
	font-size: 14px;
}
.popUpTrainingAttenders-item-name{
	flex: 1 0 auto;
}
.popUpTrainingAttenders-empty {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	border-radius: 10px;
	background: rgb(245, 245, 245);
	height: 40px;
	padding: 8px 20px;
	font-size: 14px;
	margin-top: 30%;
}

.popUpTrainingAttenders-sendBonuses-container{
	margin-top: 10px;
}

.popUpTrainingAttenders-sendBonuses-button{
	all: unset;
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 5px;
	background: #333333;
	border: 1px solid #333333;
	border-radius: 12px;
	font-size: 16px;
	height: 48px;
	transition: 300ms;
	color: #FFFFFF;
}

.popUpTrainingAttenders-sendBonuses-button:hover{
	background: #FFFFFF;
	color: #333333;
}

.popUpTrainingAttenders-sendBonuses-button:disabled{
	color: #FFFFFF;
	cursor: not-allowed;
	background: #7A7A7A;
}

.popUpTrainingAttenders-sendBonuses{
	display: flex;
	flex-direction: row;
	gap: 15px;
	font-size: 20px;
	align-items: center;
	justify-content: center;
}

.popUpTrainingAttenders-sendBonuses > .custom-input{
	width: 25%;
}