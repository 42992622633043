.container {

}

.filterTopBlock{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.viewContainer {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.viewItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.viewItemActive {
  color: #01aba2;

  > svg {
    > rect, > line {
      stroke: #01aba2 !important;
    }
  }
}

.filterButton {
  background-color: #333333;
  color: white;
  border-radius: 10px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  padding: 3px 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.filterButtonIndicator{
  height: inherit;
  background: #da0000;
  color: white;
  border-radius: 100%;
  padding: 2px 6px;
  font-size: 10px;
}

.filterPopup {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  max-height: 100dvh;
  background-color: #fff;
  display: grid;
  flex-direction: column;
  grid-template-rows: 60px calc(100dvh - 112px) 52px;
  transition: left 0.3s ease-in-out;
  z-index: 50;
}

body:has(.filterPopup.open) {
  overflow: hidden; /* Блокировка прокрутки */
}

.filterPopup.open {
  left: 0;
}

.filterHeader{
  width: 100%;
  padding: 10px;
  height: min-content;
  border-bottom: 1px solid #eee;
}

.filterContentContainer{
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
}

.filterSidebar {
  width: 200px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #eee;
}

.menuButton {
  all: unset;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #eaeaea;
  color: #333333;
  background-color: transparent;
  cursor: pointer;
  text-align: left;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  word-wrap: anywhere;
  hyphens: manual;
}

.menuButton.active {
  background-color: #dddddd;
  color: #333333;
}

.menuButtonSel{
  color: #01aba2!important;
}

.menuButtonIndicator{
  background: #da0000;
  color: white;
  border-radius: 100%;
  padding: 0 6px;
  font-size: 10px;
}

.filterContent {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: inherit;
  padding: 10px;
  overflow-y: auto;
  position: relative;
  justify-content: space-between;

  &::-webkit-scrollbar{
    width: 5px;
  }
}

.filterLoader{
  top: 0;
  left: 0;
  background: #FFFFFF80;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex!important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 10;
  pointer-events: auto;
}

.filterTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.filterItem, .filterItemDisable{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 5px;
  justify-content: start;
  font-weight: 500;
}

.filterItemDisable{
  opacity: 40%;
}

.footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 10px;
  border-top: 1px solid #ddd;
  height: min-content;
}

.clearButton {
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 30px;
  background-color: #333333;
  border: 1px solid #333333;
  color: #FFFFFF;
  padding: 0 5px;
  border-radius: 12px;
}

.applyButton {
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 30px;
  background-color: #333333;
  border: 1px solid #333333;
  color: #FFFFFF;
  padding: 0 5px;
  border-radius: 12px;
}

.searchText {
  margin-top: 15px;
  text-align: center;
}