/*DeleteProductButton*/
.trash-img {
    padding-right: 10px;
    cursor: pointer;
    height: 15px;
    margin-left: auto;
}
/*ChangeSupplierDropdawn*/
.change-supplier{
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;

    text-decoration-line: underline;
    border: none;
    background-color: transparent;
    padding: 0;
    color: #2F2121;
}
.card-item-supply-logo {
    height: 20px;
    width: 20px;
}

.void {
    width: 20px;
    height: 20px;
}

.card-item-company {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 11px;
    width: 110px;
}

.card-item-amount{
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;
    margin-right: auto;
}
.supply-company-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5px;
    background-color: white;
    height: min-content;
}

.first-element{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: none;
}

.supply-company-div:nth-child(n + 2) {
    border-top: 1px solid #F8F8F8;
    border-bottom: 1px solid #F8F8F8;
    border-right: 1px solid #F8F8F8;
}

.last-element::-webkit-scrollbar{
    width: 5px;
    background-color: white;
}

.last-element{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 5px 5px;
}

.last-element > .card-item-company{
    width: 100%;
    padding: 3px 5px;
    border-radius: 10px;
}

@media(hover: hover) {
    .last-element > .card-item-company:hover{
        background: #dddddd;
    }
}

.last-element > .card-item-company:hover:active{
    background: #dddddd;
}

.waitinglist-shadow {
    background-color: #FFFFFF;
    opacity: 0.5;
}
.cart-card{
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 9px;
    padding: 9px 12px 11px 6px;
}
.display-flex{
    display: flex;
}
.checkbox-container{
    margin-top: 10px;
}
.card-item-container {
    margin-top: 5px;
    display: flex;
    gap: 15px;
    width: 100%;
}
.card-item-img{
    height: 80px;
    width: 80px;
}
.card-item-text{
    display: block;
}
.card-item-name {
    font-size: 18px;
    line-height: 15px;
    max-width: 202px;
    padding-left: 5px;
}
.dropdown-div {
    padding-top: 5px;
    margin-top: 5px;
    position: absolute;
    z-index: 2;
}

.dropdown-shadow {
    box-shadow: 0px 0px 3px rgb(0 0 0 / 25%);
    border-radius: 5px;
    background-color: #FFFFFF;
}
.card-item-amount {
    margin-top: 40px;
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
    margin-right: auto;
    padding-left: 5px;
}
.card-item-footer {
    display: grid;
    flex-direction: row;
    grid-template-columns: 50% 40% 10%;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px 0 5px;
    justify-items: start;
    gap: 5px;
}

.card-item-price-bonuses{
    display: flex;
    flex-direction: row;
    align-items: end;
}

.card-item-price{
    display: flex;
    flex-direction: column;
}

.card-item-bonuses{
    font-size: 16px;
    line-height: 20px;
    color: #01aba2;
    margin-left: 10px;
}

.price-changeAmountBar {
    display: flex;
    gap: 13px;
    align-items: end;
}

.card-item-price1{
    font-size: 10px;
    line-height: 12px;
    color: #333333;
    /*margin-top: 14px;*/
    text-decoration: line-through;
    text-decoration-color: #DB2F2F;
}
.card-item-price2 {
    font-size: 16px;
    line-height: 20px;
    margin-top: 5px;
    color: #333333;
}

.dropdown-heart {
    display: flex;
    width: 195px;
    justify-content: space-between;
}

.search-dropdown {
    font-weight: 500;
    font-size: 9px;
    line-height: 11px;

    color: #2F2121;

    display: flex;
    padding: 5px;
    align-items: center;
    gap: 4px;

    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    margin: 5px;
}

.search-dropdown img {
    width: 8px;
    height: 8px;
}

@media (max-width: 450px) {
    .card-item-price-bonuses{
        flex-direction: column;
    }
    .supply-company-div{

        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}