.addTraining-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.addTraining-container {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    padding: 16px;
    margin: 0 auto;
    max-width: 1440px;
    position: relative;
    box-sizing: border-box;
}

.addTraining-header {
    margin-bottom: 20px;
}

.addTraining-header img {
    display: inline-block;
    rotate: 180deg;
    height: 27px;
    width: 12px;
    float: left;
}

.addTraining-header p {
    display: flex;
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    align-items: center;
    justify-content: center;
}

.addTraining-inputs {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    grid-template-rows: repeat(2, min-content);
    margin-bottom: 20px;
    grid-gap: 20px;
}

.addTraining-dropdown,
.addTraining-input{
    height: 60px;
    margin: 10px 0;
    box-sizing: border-box;
}

.addTraining-dropdown p,
.addTraining-input p {
    font-size: 16px;
    font-weight: 400;
}
.addTraining-input > .custom-input input{
    height: 60px;
    font-size: 18px;
}
.addTraining-description {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.addTraining-description p {
    margin: 0 0 10px 1px;
    font-weight: 400;
    font-size: 16px;
}

.addTraining-description textarea {
    font-family: "Montserrat", sans-serif;
    display: flex;
    min-width: 90%;
    height: 300px;
    padding: 15px;
    border-radius: 12px;
    border: 1px solid #B6BABD;
    line-height: 21px;
    font-weight: 500;
    font-size: 18px;
    color: #333333;
    outline: none;
    resize: none;
}

.addTraining-description textarea:is(:focus,:valid) {
    border-color: #222222;
}

.addTraining-description textarea::-webkit-scrollbar {
    width: 0;
}

.addTraining-choiceTime {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 10px 0;
    grid-gap: 10px;
}

.addTraining-choiceTime-item {
    display: grid;
    grid-template-columns: 1fr;
}

.addTraining-choiceTime-item input {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 21px;
    border-radius: 12px;
    border: 1px solid #B6BABD;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #333333;
    font-family: 'Montserrat';
}

.addTraining-choiceTime-item input::-webkit-calendar-picker-indicator {
    cursor: pointer;
}

.addTraining-choiceTime-item p {
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 10px 1px;
}

.addTraining-uploadPhoto {
    display: flex;
    flex-direction: column;
    margin-bottom: 125px;
    width: 225px;
    margin-top: 10px;
}

.addTraining-uploadPhoto p {
    font-size: 18px;
    font-weight: 400;
    line-height: 17px;
    margin: 0 0 10px 1px;
}

.addTraining-uploadPhoto-move input#fileMove {
    display: none;
}

.addTraining-uploadPhoto-move label {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #B6BABD;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 10px;
    height: 225px;
}

.addTraining-uploadPhoto-overview input#fileClick {
    display: none;
}

.addTraining-uploadPhoto-button{
    width: 100%;
    height: 40px;
    background: #EFEFEF;
    border: 1px solid #B6BABD;
    border-radius: 12px;
    background-image: url('/public/Pictures/reviewIcon.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    cursor: pointer;
}

.addTraining-footer-btn input {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #333333;
    border-radius: 12px;
    margin-bottom: 20px;
    height: 60px;
    font-family: "Montserrat", sans-serif;
    line-height: 22px;
    font-weight: 700;
    font-size: 22px;
    cursor: pointer;
    color: #fff;
    border: 0
}

@media (max-width: 800px) {
    .addTraining-choiceTime {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
    }
}

.addTraining-photo{
    width: 20%;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
}

.addTraining-photo-image{
    height: 200px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #B6BABD;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
}
.addTraining-photo-image img{
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.addTraining-photo-image-dragEnter{
    border-color: transparent;
    outline: 3px dashed black;
    outline-offset: 0;
}
.addTraining-photo-image-text{
    z-index: -1;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}