/*верхняя панель с линком*/
.favorites-title-div{
    margin-bottom: 15px;
}
/*пустая страница*/
.fav-empty-button{
    display: flex;
    width: 244px;
    height: 41px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    border-radius: 47px;
    font-size: 14px;
    line-height: 17px;
}
/*основной контейнер*/
.favorites-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 7px 0px 7px;
    gap: 10px;
    justify-content: space-evenly;
}
.favorites-elem{

}
