.product_wrap-favorites{
    background: #FFFFFF;
    filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.12));
    /*box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);*/
    /*box-shadow: 4px 4px 1px rgb(0 0 0 / 25%), inset -2px -2px 10px rgb(255 255 255 / 25%), inset 1px 1px 5px rgb(0 0 0 / 10%);*/
    border-radius: 9px;
    min-width: 150px;
    width: 150px;
    max-width: 150px;
    height: 236px;
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    padding: 10px 8px 8px;
}
.product_wrap-favorites-extended{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}
.favorite-heart{
    position: absolute;
    right: 0;
}
#product_and_heart-favorites{
    display: flex;
    justify-content: center;
    position: relative;
}
#img_of_product-favorites{
    object-fit: fill;
    height: 90px;
    position: relative;
}
#description_containter-favorites{
    padding-top: 5px;
    overflow: hidden;
}
#description_of_product-favorites {
    display: block;
    font-weight: 500;
    height: 37px;
    line-height: 12px;
    font-size: 12px;
}
#price1_and_amount-favorites{
    display: flex;
    justify-content: space-between;
    /*padding-right: 20px;*/
    /*margin-top: 5px;*/
    width: 100%;
    color: #6667ab;
}
.price-div-favorites {
    height: 26px;
}
.price1-favorites{
    display: block;
    font-size: 10px;
    line-height: 10px;
    color: #B8B8B8;
    text-decoration: line-through;
    padding-top: 3px;
}
#amount-favorites{
    display: block;
    font-size: 12px;
    line-height: 12px;
    color: #6667AB;
}
.price2-favorites{
    display:block;
    font-size: 12px;
    line-height: 12px;
    color: #6667AB;
}
#supply-company-div-favorites {
    display: grid;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    gap: 5px;
    grid-template-columns: 20px 1fr 20px;
}
#text_supply_company-favorites {
    display: block;
    font-weight: 500;
    font-size: 11.5676px;
    line-height: 14px;
    text-overflow: ellipsis;
    max-width: 105px;
    white-space: nowrap;
    overflow: hidden;
}
#img_supply_company-favorites {
    height: 15px;
    width: 15px;
}