.container{
  max-width: 1165px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;
  box-sizing: border-box;
}

.mainContent{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.quantityCard{
  display: flex;
  flex-direction: column;
  height: 300px;
  background: url("../../../../../public/Pictures/bonusCardImage.svg") no-repeat center center;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  min-width: 458px;
  width: 40%;

  & > .quantity{
    margin-top: 40px;
    margin-right: 40px;
    text-align: right;
    font-weight: 600;
    font-size: 36px;
    line-height: 120%;
    color: #FFFFFF;
  }

  & > .quantityHold{
    margin-top: 10px;
    margin-right: 40px;
    text-align: right;
    font-weight: 600;
    font-size: 36px;
    line-height: 120%;
    color: #FFFFFF;
  }

  & > .quantityText{
    margin-top: 0;
    margin-right: 40px;
    text-align: right;
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    color: #FFFFFF;
  }
}

.historyOperationsContainer{
  width: 60%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > .historyOperationsHeader{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
  }

  & .historyOperationsButtons{
    display: flex;
    flex-direction: row;
    gap:10px;

    & > button{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 6px 12px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      background: #FFFFFF;
      border: 1px solid #333333;
      border-radius: 100px;
      cursor: pointer;
      transition: 500ms;
    }

    & > button[data-isactive = "true"]{
      transition: 500ms;
      background: #333333;
      border-color: #FFFFFF;
      color: #FFFFFF;
    }
    & > button[data-isactive = "true"] img{
      margin-left: 10px;
      animation: aniButtonIcon 0.5s forwards;
    }
    @keyframes aniButtonIcon {
      0%{max-width: 0}
      100%{max-width: 20px}
    }

  }

  & .historyOperationsWatchAll{
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    width: 100%;
    margin-top: 20px;
    text-align: center;

    & > a {
      text-decoration-line: underline;
      cursor: pointer;

      &:hover{
        text-decoration-line: none;
      }
    }
  }

}



.bonusesPageInput > .custom-input{
  width:680px;
}

@media screen and (max-width: 450px){
  .container{
    margin-top: 0;
  }
  .mainContent{
    margin-top: 10px;
    flex-direction: column;
    gap: 20px;
  }
  .quantityCard{
    min-width: 100%;
    width: 100%;
    height: auto;
    aspect-ratio: 460/300;
    object-fit: fill;
    background: url(https://localhost:3000/static/media/bonusCardImage.e82406bd8129a58e189a.svg) no-repeat center center;
    background-size: 100% auto;
    margin-bottom: 0;

    & > .quantity{
      margin-top: 20px;
      margin-right: 20px;
      text-align: right;
      font-weight: 600;
      font-size: 26px;
      line-height: 26px;
    }

    & > .quantityHold{
      margin-top: 10px;
      margin-right: 20px;
      text-align: right;
      font-size: 26px;
    }

    & > .quantityText{
      margin-top: 0;
      margin-right: 20px;
      text-align: right;
      font-size: 26px;
    }
  }

  .historyOperationsContainer{
    width: 100%;
    margin-top: 0;
    gap: 10px;

    & > .historyOperationsHeader{
      font-size: 18px;
      align-items: center;
    }

    & .historyOperationsButtons{
      display: flex;
      flex-direction: row;
      gap: 5px;

      & > button{
        padding: 5px 5px;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
      }
    }


  }

}