.navigate-container-from-chat {

}
.chat_container {
    height: inherit;
    width: 100%;
    flex: 1;
    display: grid;
    flex-direction: column;
    grid-template-rows: auto 57px;
    overflow: hidden;
}
.allMessages_container {

    overflow: auto;
}
.allMessages_container::-webkit-scrollbar{
    width: 4px;
}
.chatInput_container{
    /*width: calc(120px + 100 * ((100vw - 320px) / (2120 - 320)));*/
    width: 100%;
}

