.dropdown-list-div {
    height: max-content;

    position: absolute;
    top: 100%;
    z-index: 3;
    font-size: 14px;
    font-weight: 400;

    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #B6BABD;
    background-color: white;
    border-radius: 10px;
    min-width: 100%;
    box-sizing: border-box;
    max-height: 200px;
    overflow: auto;
}

.dropdown-list-div-content{
    overflow-y: auto;
    max-height: 120px;
    margin-right: 5px;
}

.DropdownDisplay{
    box-sizing: border-box;
    position: relative;
    padding: 11px 5px 11px 9px;
    font-size: 14px;
    font-weight: 500;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #B6BABD;
    appearance: auto;
    border-radius: 7px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 9px;
}
.DropdownDisplayText{
    height: max-content;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: initial;
}
.DropdownDisplayInput {
    font-family: 'Montserrat';
    border-style: none;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    outline-width: 0;
    height: 100%;
}

.DropdownDisplay[data-size="medium"]{
    box-sizing: border-box;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 21px;
    font-size: 16px;
    border-radius: 12px;
}

.DropdownDisplay[data-size="large"]{
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 21px;
    font-size: 18px;
    border-radius: 12px;
}

.DropdownDisplay[data-size="large"] > .DropdownDisplayInput {
    font-size: 18px;
}

.DropdownDisplay[data-hide="false"]{
    z-index: 4;
}
.dropdown-list-div::-webkit-scrollbar {
    display: none;
}
.dropdown-list-div > div:first-child{
    margin-top:5px;
}
.dropdown-list-div > div:last-child{
    margin-bottom:5px;
}
.dropdown-list-div[data-hide="true"]{
    display:none;
}
.dropdown-list-div[data-hide="false"]{
    display:block;
}
.dropdown-list-div[data-size="large"]{
    margin-top: 25px;
    padding: 10px 0;
}
.dropdown-list-div[data-size="medium"]{
    margin-top: 20px;
    padding: 10px 0;
}
.dropdown-list-div[data-size="small"]{
    margin-top: 15px;
    padding: 10px 0;
}

.dropdown-item-name{
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 20px;
    height: auto;
    overflow-y: hidden;
    font-weight: 600;
    text-overflow: ellipsis;
    position: relative;
    z-index: 3;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 8px;
    margin-left: 5px!important;
    margin-right: 5px;
}
.dropdown-item-disable{
    cursor: not-allowed;
    color: rgba(51, 51, 51, 0.49);
}
.dropdown-item-name:hover{
    background-color: #B6BABD50;
}



.dropdown-internal-container{
    flex: none;
    display: block;
    position: relative;
    width: 100%;
}
.supplier-catalog-dropDown{
    display: flex;
    justify-content: left;
    align-items: center;
    border-radius: 12px;
    padding: 0 44px 0 21px;
    height: 52px;
    max-height: 52px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    background-image: url("/public/Pictures/VectordropDownIcon.svg");
    background-repeat: no-repeat;
    background-position: right 24px top 50%;
    box-sizing: border-box;
}
.dropdown-div-search{
    width: 100%;
    margin-left: 9px;
}
.dropdown-div-search-scrollArea{
    -webkit-box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.32);
    -moz-box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.32);
    box-shadow: 0px 0px 11px -5px rgba(0, 0, 0, 0.32);
    border-radius: 10px;
    margin: 9px;
    padding: 5px 0 5px 0;
    max-height: 191px;
    overflow: auto;
}
.supplier-catalog-dropDown-list1{
    min-height: 104px;
    max-height: 480px;
}
.supplier-catalog-dropDown-list2{
    height: 30px;
    padding-top:16px;
    justify-content: left;
    align-items: center;
    vertical-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: #B6BABD;
}
.supplier-catalog-dropDown-list2:last-child{
    border:none;
}

.dropDown-image{
    position: absolute;
    right: 0;
    margin-right: 24px;
    color: #333333;
    top: calc(50% - 5px);
}
.dropDown-image[data-hide = "false"]{
    rotate: -180deg;
    transition: 300ms;
}
.dropDown-image[data-hide = "true"]{
    transition: 300ms;
}
.dropDown-error{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: 0;
    margin-right: 50px;

}
.dropDown-error-image{
    color: #333333;
    width: 20px;
    height: 20px;
}
.dropDown-error-text{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 150%;
    right: -150%;
    max-width: 250px;
    background-color: #FFFFFF;
    padding: 5px 10px;
    color: #333333;
    border-radius: 6px;
    z-index: 1;
    text-align: center;
    visibility: hidden;
    border: 1px solid #db2020;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    white-space: nowrap;

}
.dropdown-item-name-grey{
    color: grey;
}
.dropdown-item-name-grey > div{
    font-weight: 400;
}
.dropdown-item-display-back-grey{
    background-color: #c7c7c7 !important;
}

.dropDown-error:hover .dropDown-error-text{
    visibility: visible;
}
.dropDown-error:target .dropDown-error-text{
    visibility: visible;
}
.admin_qp_dd_display{
    width: 85%;
    border-radius: 20px;
}
.admin_qp_dd_list{
    width: 200%;
    max-height: 260px;
    overflow-y: hidden;
}