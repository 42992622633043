.header-div_dealer {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    padding: 20px;
    background: white;
    box-shadow: 0px 36px 21px rgba(0, 0, 0, 0.02), 0px 16px 16px rgba(0, 0, 0, 0.03), 0px 4px 9px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.03);
}

.cabinet_button_dealer {
    justify-content: center;
    display: flex;
    align-items: center;
    width: 108px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #333333;
    /* Медлайн/тень 1 хедер */

    box-shadow: 0px 13px 5px rgba(0, 0, 0, 0.01), 0px 7px 4px rgba(0, 0, 0, 0.02), 0px 3px 3px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 100px;

    color: #333333;
    font-family: 'Montserrat', 'sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
}

.cabinet_button_dealer:hover {
    background: #333333;
    color: #FFFFFF;
}

/*hamberher menu*/
.Hamburger-and-share_dealer {
    display: flex;
    align-items: center;
    justify-content: start;
}


/*buttons*/
.header-buttons_dealer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 13px;
}

.logo-and-homeButton_dealer {
    display: flex;
}

.img-logo-header_dealer {
    overflow: hidden;
    width: 140.92px;
    height: 34.51px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 9px;
}

.headerNotification_dealer {
    overflow: hidden;
    width: 32px;
    height: 32px;
    align-items: center;
    display: flex;
    justify-content: center;
}

svg:hover .bell {
    fill: #ffffff;
    transition: fill 0.5s;
}

svg:hover .bell_back {
    fill: #333333;
    transition: fill 0.5s;
}


.cash-p {
    margin-left: 6px;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
}

.header-p {
    position: absolute;
    top: 15px;
    right: 54px;
    background-color: #FB7070;
    border-radius: 20px;
    font-size: 8px;
    justify-content: center;
    display: flex;
    color: white;
    align-items: center;
    width: 13px;
    height: 13px;
}