.admin_qp_container{
    padding: 2% 3% 3% 3%;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1165px;
}
.admin_qp_container > #navigate-title-div{
    margin-bottom: 40px;
}
.admin_qp_main_container{
    display: flex;
    border: solid 1px #A7A7A7;
    border-radius: 10px;
    min-height: 1100px;
    overflow: hidden;
}
.admin_qp_name .custom-input-field{
    padding-top: 10px;
    padding-bottom: 10px;
}
.admin_qp_dd{
    width: 50%;
    margin-bottom: 20px;
}
.admin_qp_dd_list_item{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.admin_qp_dd_list_item_bold{
    font-weight: 600;
}
.admin_qp_first_column{
    padding: 22px 15px 20px 20px;
    width: 100%;
    overflow: hidden;
}
.admin_qp_small_content{

}
.admin_qp_header_1{
    line-height: 24px;
    font-size: 24px;
    margin-bottom: 19px;
}
.admin_qp_dealer_info{
    display: flex;
}
.admin_qp_dealer_info > div:first-child{
    font-size: 18px;
    margin-right: 5px;
}
.admin_qp_dealer_info > div:last-child{
    font-size: 18px;
    font-weight: lighter;
}
.admin_qp_header_2{
    font-size: 18px;
    line-height: 18px;
    margin-top: 30px;
    margin-bottom: 15px;
}
.admin_qp-description-input{
    font-family: "Montserrat", sans-serif;
    width: 100%;
    background-color: #fff;
    border: 1px solid #B6BABD;
    border-radius: 10px;
    color: #333333;
    padding: 15px 15px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 250px;
    position: relative;
    resize: none;
}
.admin_qp_photo_div{
    width: 100%;
    display: grid;
    flex-direction: row;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: end;
    gap: 10px;
    margin-bottom: 30px;
}
.admin_qp-photo{
    width: 45%;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
}
.admin_qp-photo-image{
    padding-top: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #B6BABD;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}
.admin_qp_view_first .admin_qp_mask{
    opacity: 0;
    transition: all 0.2s ease-in-out;
}
.admin_qp_view_first > img{
    transition: all 0.2s ease-in-out;
}
.admin_qp_view_first:hover .admin_qp_mask{
    opacity: 1;
}
.admin_qp_view_first:hover > img{
    opacity: 0;
}
.admin_qp-photo-image img{
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #FFFFFF;
    height: 100%;
    object-fit: contain;
}
.admin_qp_add_photo{

}
.admin_qp_add_photo > img{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    width: 60%;
    height: 60%;
}
.admin_qp_add_photo > div{
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.admin_qp_preview{
    margin-bottom: 30px;
}
.admin_qp_bottom_buttons{
    display: flex;
    width: 100%;
}
.admin_qp_bottom_buttons > div{
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;

    background-color: #333333;
    color: white;
    border-radius: 8px;
}
.admin_qp_bottom_buttons > div:first-child{
    padding: 15px 0 4px 0;
    margin-right: 2%;
    width: 60%;
    text-align: center;
}
.admin_qp_bottom_buttons > div:last-child{
    padding: 12px 14px 7px 14px;
}
