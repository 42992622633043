.Arrow-back {
    width: 8px;
    height: 14px;
    display: block;
}

.Arrow-back img {
    width: 100%;
    height: 100%;
}

.product-head {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin: 15px 0 0 0;

    color: #333333;
}

.product-provider {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #333333;

    display: flex;
    align-items: center;
    gap: 12px;
}

.product-provider img {
    width: 20px;
    height: 20px;
}

.bread-crumbs {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    height: 12px;
}
.product-card {
    /*min-height: calc(100vh - 18px - 53px);*/
    margin-bottom: 120px;
}
.bread-crumbs a {
    color: rgb(51, 51, 51, 0.7);
    white-space: nowrap;
}

.product-image-container {
    /*overflow: hidden;*/
    width: fit-content;
    max-width: 348px;
    height: 348px;
    margin: 10px auto;
    /*border: 0.5px solid #e2e2e2;*/
    display: flex;
    justify-content: center;
    position: relative;
}

.productPage-1SConnected {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    position: relative;
}

#productPage-1SConnected-img {
    width: 25px;
    height: 25px;
    object-fit: fill;
    /*position: absolute;*/
    z-index: 1;
    margin: auto;
    padding: 0px 0px 0px 10px;
}
#productPage-cursor-img {
    width: 18px;
    height: 18px;
    object-fit: fill;
    position: relative;
    z-index: 1;
    bottom: 0;
    right: 0;
}
.productPage-1SConnected-text {
    position: absolute;
    z-index: 1;

    top: 100%;
    left: -140px;
    background-color: white;
    border-radius: 12px;
    color: black;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 8px;
    border: 1px solid black;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 280px;
    white-space: pre-wrap;

    box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.48);
}

.product-find-analog {
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: min-content;
    max-width: 200px;
    background: #333333;
    color: #ffffff;
    border-radius: 15px;
    border: 1px solid #333333;
    padding: 5px 10px;
    font-weight: 500;
    white-space: nowrap;
}

.product-find-analog:hover {
    background: #ffffff;
    color: #333333;
}

.product-image-container img {
    width: inherit;
    object-fit: contain;
}
.popup-wrapper-nocard {
    height: 150px !important;
}

.popup-container {
    position: relative;
}

.popup-button {
    display: block;
    margin: 0 auto;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.product-popup {
    min-width: 300px;
    max-width: 680px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 10px;
    z-index: 101;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 90%;
    justify-content: start;
    max-height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.product-popup::-webkit-scrollbar {
    width: 0px;
    background-color: white;
}

.popup-header{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-direction: row;
    text-align: center;
    font-size: 16px;
}

.Supplier-comparison {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: rgba(220, 220, 220, 0.53);
    padding: 7px;
    border-radius: 15px;
}

.Supplier-comparison a button {
    cursor: pointer;
}

.supplier-comparison-info{
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    gap: 2px;

}

.supplier-comparison-amount-price{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.supplier-comparison-amount{
    color: #6667ab;
    font-size: 14px;
}

.supplier-comparison-bonuses{
    font-size: 14px;
    color: #01aba2;
    margin-left: 10px;
}

.supplier-comparison-price{
    display: flex;
    flex-direction: row;
    color: #6667ab;
    font-size: 14px;
}

.supplier-comparison-supplier-address{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.supplier-comparison-supplier{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
}

.productCard-1SConnected-img{
    width: 18px;
    height: 18px;
}

.productCard-supplierLogo{
    width: 25px;
    height: 25px;
    border-radius: 5px;
}

.name-order-popup {
    grid-gap: 0 7px;
    color: #333;
    display: flex;
    row-gap: 5px;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    grid-template-columns: repeat(2, 0fr);
    line-height: 12px;
    flex-wrap: wrap;
    max-width: 250px;
}

.item1 {
    grid-row: span 3 / auto;
    height: 40px;
    width: 40px;
    display: none;
}

.item2 {
    height: 14px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item3 {
    white-space: nowrap;
}

.popup-price {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 600;
    line-height: 13px;
    text-align: left;
    white-space: nowrap;
    margin-left: auto;
}

.popup-button {
    height: fit-content;
    padding: 2px 5px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    color: #333333;
    background-color: #ffffff;
    border: solid 1px;
    border-radius: 23px;
    margin-left: 5px;
}
.heart {
    border: none;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 7px;
    padding: 0;
    top: 7px;
}

.product-price {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.old-price {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    text-decoration-line: line-through;

    color: rgb(51, 51, 51, 0.7);
}

.new-price-amount-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.new-price {
    text-wrap: nowrap;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    /* identical to box height */
    margin: auto;

    color: #333333;
}

.productPage-bonuses{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;

    text-wrap: nowrap;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    /* identical to box height */
    margin-left: 10px;

    color: #01aba2;
    position: relative;
}

.productPage-bonuses-hint-button{
    cursor: pointer;
    height: 20px;
}

.productPage-bonuses-hint{
    position: absolute;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.48);
    z-index: 1;
    top: 100%;
    left: 5px;
    border-radius: 12px;
    color: black;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 180px;
    white-space: pre-wrap;
    line-height: 14px;
}

.quantity {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #333333;
}

.description-provider {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.charact {
    display: flex;
    justify-content: space-between;
}

.charact span {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    color: #333333;

    display: flex;
    gap: 7px;
    align-items: center;
}

.image_supply_company {
    width: 20px;
    height: 20px;
}

.effect {
    margin-top: 20px;

    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    color: #333333;
}

.product-buttons {
    padding: 15px 20px 15px 20px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.15);
    background-color: #ffffff;

    gap: 15px;
    width: 100%;
    left: 0;
    position: fixed;
    bottom: 0;
    z-index: 2;
    justify-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    align-items: center;
}
.product-buttons > .product-go-home {
    grid-row-start: 1;
    grid-column-start: 2;
}

.product-thumbnails {
    display: flex;
    gap: 7px;
    width: 100%;
    justify-content: center;
    margin: 5px 0;
}


@media only screen and (min-width: 800px) {
    .product-buttons {
    }
}

.product-button {
    padding: 5px 10px;
    width: 210px;
    height: 40px;
    border: none;
    border-radius: 24px;
    background-color: #333333;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
}

.product-button-two {
    background-color: #333333;
    color: #ffffff;
}

.product-button-one {
    padding: 5px 10px;
    width: 210px;
    height: 40px;
    border: none;
    border-radius: 24px;
    background-color: #333333;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
}

.product-detailed-description {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #333333;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
}

.detailed-description-head {
    color: #333333;
    margin-bottom: 0;
}

.description-list {
    color: rgb(51, 51, 51, 0.7);
    list-style-type: disc;
    list-style-position: inside;
    white-space: pre-wrap;
    word-wrap: break-word;
}
.product-detail-filterList {
    margin-top: 20px;
    margin-bottom: 5px;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.product-detail-filterItem {
    background-color: white;
    border-radius: 20px;
    color: black;
    font-size: 10px;
    font-weight: 400;
    padding: 2px 8px;
    border: 1px solid black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.historyPage-container > .swiper {
    margin-left: -20px;
    margin-right: -20px;
}

.history-head {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    margin: 0;
}

.productPage-alreadyInCart {
    text-shadow: 0 2px 15px #0085a4;
}

.product-detailed-terms{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    align-items: center;
}

.product-detailed-terms > button{
    cursor: pointer;
    background-color: #FFFFFF;
    border: 1px solid #333333;
    border-radius: 12px;
    font-size: 14px;
    padding: 3px 10px;
    margin-top: 10px;
    height: min-content;
    white-space: nowrap;
}

@media(hover: hover) {
    .product-detailed-terms > button:hover{
        background-color: #333333;
        color: #FFFFFF;
    }
}

.product-detailed-terms > button:active{
    background-color: #333333;
    color: #FFFFFF;
}

.product-detailed-deliveryPoints {
    margin-top: 10px;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    background-color: #FFFFFF;
}

.product-detailed-deliveryPoints h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
}

.product-detailed-deliveryPoints ul {
    list-style-type: disc;
    margin: 0;
    padding-left: 20px;
}

.product-detailed-deliveryPoints li {
    font-size: 16px;
    color: #555;
}



@media screen and (max-width: 450px) {
    .product-buttons {
        gap: 15px;
        padding: 10px;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
    .product-buttons > .product-go-home {
        grid-column-start: 1;
        grid-row-start: 2;
        grid-column: span 2;
    }
    .product-button-one,
    .product-button {
        height: 45px !important;
        font-size: 13px !important;
        width: 175px !important;
    }
    .product-go-home > img {
        width: 25px !important;
    }

    .productCard-supplierLogo{
        width: 20px;
        height: 20px;
    }

    .supplier-comparison-supplier-address{
        flex-direction: column-reverse;
        align-items: start;
    }

    .product-detailed-deliveryPoints li{
        font-size: 12px;
    }
}
