.RegionSelect_Item {
    cursor: pointer;
    padding: 4px 6px;
    background-color: white;
    border: solid 1px rgba(51, 51, 51, 1);
    border-radius: 5px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    white-space: pre-wrap;
}
.RegionSelect_Name{
    color: white;
    background-color: rgba(51, 51, 51, 1);
}
.RegionSelect_Box {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.25);
    left: 5px;
    max-height: fit-content;
    min-width: calc(100vw - 55px);
    padding: 20px;
    position: fixed;
    right: 5px;
    top: 100px;
    z-index: 10000;
    margin-left: 20px;
    margin-right: 20px;
}
.RegionSelect_Search {
    background-color: #fff;
    position: relative;
}
.RegionSelect_Search > .Searchbar{
    width:auto
}
.RegionSelect_Items {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;
    margin-top: 10px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    width: 100%;
}

@media (max-width: 450px) {
    .RegionSelect_Box {
        margin-left: 0;
        margin-right: 0;
    }
}