.container{
  width: 100%;
  box-sizing: border-box;
  height: calc(100dvh - 96px);

  display: flex;
  flex-direction: column;

  gap: 20px;
}

.filter{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;

  & .filterStatusContainer, .filterRegionContainer, .filterCityContainer, .filterWarehouseContainer{
    font-size: 16px;
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

