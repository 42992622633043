.add-item {
    display: flex;
    height: 25px;
    width: 100px;
    box-shadow: 1px 0px 3px rgb(0 0 0 / 10%);
    border-radius: 50px;
    justify-content: space-between;
    font-size: 14px;
    line-height: 12px;
    align-items: center;
}
.add-item-p {
    width: 20%;
    text-align: center;
}

.add-item > img{
    cursor: pointer;
    height: inherit;
}