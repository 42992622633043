.product-preview-mask{
	background: rgba(255, 255, 255, 0.76) !important;
}
.product-preview-mask-text {
	display: flex;
	gap: 15px;
	align-items: center;
	justify-content: space-between;
	height: 8%;
}
.product-preview-mask-text img{
	width: 100%;
	height: 100%;
}

.product-preview-mask-text p{
	color: black;
}