.admin-mainSetting-container{
    padding: 30px 0 70px 0;
    margin: 0 auto;
    max-width: 1165px;
}

.admin-mainSetting-settings-container{
    box-sizing: border-box;
    margin-top: 45px;
    width: 100%;
    border-radius: 12px;
    border: 1px solid #A7A7A7;
    padding: 30px;
}

.admin-mainSetting-input{
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.admin-mainSetting-input:first-child{
    margin-top: 0px;
}

.admin-mainSetting-hint-text{
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}
.admin-mainSetting-hint-text-small{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}
.admin-mainSetting-textarea{
    font-family: "Montserrat", sans-serif;
    width: 100%;
    background-color: #fff;
    border: 1px solid #B6BABD;
    border-radius: 10px;
    color: #333333;
    padding: 15px 15px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    height: 200px;
    position: relative;
    resize: none;
}
.admin-mainSetting-textarea-small{
    font-family: "Montserrat", sans-serif;
    width: 100%;
    background-color: #fff;
    border: 1px solid #B6BABD;
    border-radius: 10px;
    color: #333333;
    padding: 15px 15px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    height: 200px;
    position: relative;
    resize: none;
}
.admin-mainSetting-button{

    padding-left: 30px;
    padding-right: 30px;
    margin-top: 40px;
    height: 60px;
    
    border: 1px solid #333333;
    border-radius: 12px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    background-color: #333333;
    color: #FFFFFF;
}
.admin-mainSetting-button-small{

    padding-left: 30px;
    padding-right: 30px;
    margin-top: 40px;
    height: 48px;

    border: 1px solid #333333;
    border-radius: 12px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    background-color: #333333;
    color: #FFFFFF;
}

.admin-mainSetting-button:hover, .admin-mainSetting-button-small:hover{
    background-color: #FFFFFF;
    color: #333333;
    transition: 300ms;
}

.admin-mainSetting-button[data-isCanUpdate="false"]{
    background: rgb(142, 142, 142) !important;
    color: #FFF !important;
    border-color: #333 !important;
}

.admin-mainSetting-pricesTable-container{
    width: 80%;
    margin: 30px auto;
}
.admin-mainSetting-pricesTable-container > .table-container{
    margin: 0;
    width: 100%;
    max-height: 406px;
}
#TableSuppliers .custom-table-body .searchRow .custom-tbf > div{
    position: relative;
    height: 27px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#PricesTable .custom-table-body .searchRow > td > div{
    height: 27px;
}
#PricesTable .custom-table-body .searchRow > td > div .table-body-line{
    height: 27px;
    margin: auto;
}

#PricesTable .custom-table-head tr th > div{
    height: 27px;
}
#PricesTable .custom-table-head tr th > div .table-head-line{
    height: 27px!important;
    margin: auto;
}
#PricesTable .custom-table-head > tr .custom-thf > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    white-space: pre-wrap;
    height: 27px;
}
.admin-mainSetting-pricesTable-buttons{
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap: 30px
}

.admin-mainSetting-reklama-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: left;
}
.admin-mainSetting-reklama{
    width: 100%;
}

.admin-mainSetting-reklama-header{
    cursor: pointer;
    margin-top: 30px;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    position: relative;
}
.admin-mainSetting-reklama-header::after{
    content: url("/public/Pictures/ArrowBottomBlack.svg");
    position: absolute;
    top: -10%;
    margin-left: 20px;
}
.admin-mainSetting-reklama[open] .admin-mainSetting-reklama-header::after{
    rotate: 180deg;
    top: 25%;
}

.admin-mainSetting-reklama-setting{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 30px;
}

.admin-mainSetting-individual-reklama-container{
    width: 100%;
    display: inline-flex;
    gap: 30px;
}

.admin-mainSetting-reklama-price{
    width: 55%;
}

.admin-mainSetting-pricesTable-delete{
    cursor: pointer;
}

.admin-mainSetting-reklama-regions{
    flex: 1;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.25);
    display: flex;
    flex-direction: column;
}

.admin-mainSetting-reklama-regions-list{
    margin-top: 10px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height:340px;
    overflow-y: auto;
}

.addPrice-popup-close-wrapper{
    position:fixed;
    background-color:grey;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.33;
    z-index: 12;
}
.addPrice-popup-wrapper{
    display: flex;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 13;
    opacity: 1;
    padding: 0;
    width: 1025px;
    height: 150px;
    border-radius: 15px;
    background: #FFF;
}
.addPrice-popup-container{
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 20px;
    position: relative;
    padding: 30px;
    width: 100%;
}

.admin-mainSetting-button-disabled{
    background: rgb(142, 142, 142) !important;
    color: #FFF !important;
    border-color: #333 !important;
}