body {
  margin: 0;
  line-height: 1.6;
  color: #333333;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  font-style: normal;
}
*{
  box-sizing: border-box;

}
input{
  font-family: 'Montserrat';
  font-size: 14px;
}

@font-face {
  font-family: "Password";
  src: local("Password"),
  url("./font/Password.otf") format("truetype");
}
button input textarea{
  margin: 0;
  line-height: 1.6;
  color: #333333;
  font-family: 'Montserrat', monospace;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  font-style: normal;}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
p{
  margin: 0;
}
a{
  text-decoration: none; /* Убираем подчёркивание */
  color: #000; /* Цвет текста */
}
a:hover{
  text-decoration: none; /* Убираем подчёркивание */
  color: #000;
}
span{
  display: block
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.wrapper{
  flex-direction: column;
  display: flex;
  min-height: 500px;
  margin: 0;
  padding: 0;
}
.content{
  flex: 1 0 auto;
}
.loading{
  display: flex;
  justify-content: center;
  align-items: center;
}