.layout-div{
    /*z-index: 30;
    position: sticky;
    top: 0px;*/
}

.cookie-notice{
    box-sizing: border-box;
    padding: 20px;
    position: fixed;
    z-index: 16;
    bottom: 1.4%;
    left: 2.5%;
    width: 45%;
    background-color: #FFFFFF;
    font-size: 1em;
    font-weight: 400;
    display: flex;
    gap: 20px;
    border-radius: 20px 20px 20px 20px;
    box-shadow: 0px 2px 50px 5px rgb(0 0 0 / 38%);
    align-items: center;
    flex-direction: column;
}

.cookie-notice:hover {
    box-shadow: 0px 5px 30px 8px rgb(0 0 0 / 58%);
}

.cookie-notice div{
    text-align: justify;
    max-width: 900px;
}

.cookie-notice div b{
    cursor: pointer;
    color: #007bff;
    text-decoration: underline;
}

.cookie-notice button{
    all: unset;
    cursor: pointer;
    padding: 0 30px;
    height: 32px;
    background-color: #d7d7d7;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    width: 25%;
    text-align: center;
    font-family: "Montserrat", sans-serif;
}

.cookie-notice button:hover{
    background-color: #333333;
    color: #FFFFFF;
}

.cookie-notice div:last-child{
    position: relative;
}

.cookie-notice img{
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

.cookie-notice-popup{
    background-color: rgba(51, 51, 51, 0.63);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
}

.cookie-notice-popup-container{
    position: absolute;
    z-index: 12;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    display: flex;
    flex-direction: column;

    background-color: #FFFFFF;
    border-radius: 12px;
    width: 70%;
    max-width: 700px;
}

.cookie-notice-popup-header{
    width: 100%;
    display: inline-grid;
    grid-template-columns: 1fr 80% 1fr;
    align-items: center;
    justify-content: center;
    justify-items: center;
    font-size: 18px;

    margin-bottom: 10px;
}

.cookie-notice-popup-content{
    text-indent: 40px;
    color: #656565;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cookie-notice-popup-content ul{
    display: flex;
    flex-direction: column;
    gap: 10px;
}


@media (max-width: 768px) {
    .cookie-notice{
        padding: 10px;
        font-size: 8px;
        gap: 10px;
    }
    .cookie-notice button{
        font-size: 10px;
        padding: 0 10px;
        height: 32px;
    }
}

@media (max-width: 500px){
    .cookie-notice-popup-content{
        text-indent: 20px;
    }
}