.carousel-indicators{
    position: absolute;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding: 0px;
    margin-right: auto;
    margin-left: auto;
    width: 50%;
    bottom: 1%;
    z-index: 1;
}
.carousel-indicators li{
    padding: 10px;
}
.carousel-link{
    display: flex;
    align-items: center;
    justify-content: center;
}
.carousel-link:hover{
    box-shadow: 0 0 5px rgba(0,0,0,0.3); /* Тень */
}
.carousel-slide1-img{
    width: 100%;
    border-image-source: url("/public/Pictures/Rectangle3_1920px.png");
    border-image-slice: 0 fill;
}
