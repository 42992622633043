#Menu {
    /*position: sticky;*/
    /*top: -1px;*/
    padding: 5px 20px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    z-index: 2;
}
#menu-img{
    margin-right: 5px;
    height: 15px;
}
.menu-a{
    display: flex;
    align-items: center;
    justify-content: start;
    border: 1px solid #333333;
    border-radius: 12px;
    padding: 2px 8px;
    max-width: 200px;
    margin-left: auto;
}
#menu-p{
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
}
.menu-item{
    display: flex;
    height: 17px;
}
.cart-p{
    height: 8px;
    width: 8px;
    background-color: red;
    font-size: 8px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
    border-radius: 4px;
}
.menu-cash{
    margin-right: 21px;
    align-items: center;
}
.menu-cart{
    align-items: self-start;
}
#menu-buttons-div{
    display: flex;
}
.lupa-img{
    height: 15px;
    margin-right: 5px;
}
.find-div {
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: end;
}
.find-input {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 60%;
    display: flex;
    width: calc(100% - 50px);
    border: none;
    background-clip: padding-box;
    border-radius: 2px;
    padding: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    color: #A5A5A5;
    margin: 0 auto;
}
.find-input:focus{
    color: #212529;
    background: #FFFFFF;
    outline: 0;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.04);
}
