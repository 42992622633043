#CDayTd > span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 22px;
    border-radius: 4px;
    background: white;
    margin: 0 auto;
}

.CalendarSelected {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 22px;
    border-radius: 4px;
    background: #333333!important;
    color: white!important;
    margin: 0 auto;
}
.CalendarToday {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    width: 22px;
    border-radius: 4px;
    background: #33333350!important;
    color: white!important;
    margin: 0 auto;
}
.CalendarHoliday{
    color: #17A1FA!important;
}
.CalendarPastDays{
    color: #B3B3B3!important;
}
.order-choose-time-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #333333;
    border-radius: 12px;
    height: 30px;
    width:300px;
}
#order-choose-time-text{
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
}
.ofcModalWrapperOpen{
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: white;
    z-index: 15;
    width: 100%;
    padding: 0 20px;
    transition: bottom 0.5s ease-in-out;
}
.ofcModalWrapperClose{
    display: block;
    position: fixed;
    bottom: -100%;
    left: 0;
    background-color: white;
    z-index: 15;
    width: 100%;
    padding: 0 20px;
    transition: bottom 0.5s ease-in-out;
}
.ofcModalWindow{
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    /*padding: 15px 20px;*/
    padding: 15px 0px;
}
.ofcHeader{
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    padding: 0 20px 20px 20px;
}
#ofcCloseButton{
    overflow: hidden;
    width: 14px;
    height: 15px;
}
.ofcCloseAreaOpen{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.ofcCloseAreaClose{
    display: none;
}
.calendar{
    height: 220px;
    padding: 0 7px;
}
.ofcLineAfterCalendar{
    display: flex;
    justify-content: center;
    padding-top: 15px;
}
.ofcDeliveryTimeSelection{
    margin-right: auto;
    margin-left: auto;
    max-width: 550px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 10px 20px 0 20px;
}
#ofcTextChooseDelivery{
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}
/*.ofcTextChooseDeliveryWrapper1{*/
/*    padding-bottom: 20px;*/
/*}*/
/*.ofcTextChooseDeliveryWrapper2{*/
/*    padding-bottom: 10px;*/
/*}*/

.ofcTwoDropdowns{
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0;
}
.ofcTwoDropdowns span{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}
.ofcDropdown{
    box-shadow: 0 0 11px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}
.ofcDropdownWrapper{
    Width: 78px;
    /*Height: 21px;*/
}
.ofcDropdown option{
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}
.ofcTextOr{
    padding: 10px 0;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
}
.ofcTextOr span{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ofcButtonsDelivery{
    display: flex;
    font-size: 11px;
    line-height: 13px;
}
.ofcButton1DuringTheDay{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #333333;
    border-radius: 42px;
    margin: 0 auto;
    width:109px;
    height:23px;
}
.ofcButton2DuringTheDay{
    display: none;
}
.ofcButton1ChooseYourself{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #333333;
    border-radius: 42px;
    width:109px;
    height:23px;
}
.ofcButton2ChooseYourself{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 23px;
}
.ofcButton1DuringTheDay span{
    font-weight: 600;
}
.ofcButton1ChooseYourself{
    font-weight: 600;
    color: #FFFFFF;
}
.ofcButton2ChooseYourself{
    font-weight: 500;
    text-decoration-line: underline;
}
.ofcLabelWrapper1{
    padding: 15px 20px 15px 20px;
}
.ofcLabelWrapper2{
    padding: 10px 20px 15px 20px;
}
.ofcLabelInput{
    font-family: 'Montserrat', sans-serif;
    height: 43px;
    width:calc(100% - 28px);
    background: #F3F3F3;
    border-radius: 12px;
    border: none;
    padding: 0 14px;
}
.ofcDeliverySummaryWrapper{
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}
.ofcDeliverySummary{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 43px;
    width:100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
}
.ofcDeliverySummary span{
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
}
.ofcOkButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52.29px;
    height:43px;
    margin-left: 10px;
    border: 1px solid #333333;
    border-radius: 12px;
    box-shadow: rgb(1, 171, 162) 0px 0px 5px;
}
.ofcOkButton span{
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}


#CDayOfTheWeekTh{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin:0 auto;
    background: #333333;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: #FFFFFF;
}
#CNumberDay{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
}
.CTBody:before {
    content:"@";
    display:block;
    line-height:19.17px;
    text-indent:-99999px;
}
#CDayTd{
    width: 22px;
    height: 22px;
}


@media (max-width: 450px) {
    .ofcDeliveryTimeSelection{
        flex-direction: column;
    }
    .order-choose-time-button{
        width: 100%;
    }
}