.admin-home-main{

    display: flex;
    flex-direction: row;

}
.admin-home-header{
    color: #333;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    margin: 40px 0;
}
.admin-home-content{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.admin-home-content-stroke{
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
}
.admin-home-content-item{
    padding: 10px;
    display: flex;
    width: 47%;
    height: 176px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #333;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    line-height: 100%;
    gap: 10px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
}
.admin-home-content-item-text{
    color: #333;
    font-size: 20px;
    font-weight: 500;
    line-height: 100%;
}
.admin-home-content-item-val{
    color: #333;
    font-size: 40px;
    font-weight: 700;
    line-height: 100%;
}

.admin-navbar-main{
    width: 25%;
    max-width: 350px;
    padding: 20px;
}
.admin-content-main{
    width: 75%;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
}