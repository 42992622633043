.dialog_wrapper.active {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(128, 128, 128, 0.33);
    z-index: 1000;
    pointer-events: none;
}

.dialog_container.active {
    animation: none;
}

.dialog_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 292px;
    min-height: 100px;
    padding: 0;
    background-color: white;
    border-radius: 12px;
    z-index: 23;
    box-shadow: none;
    pointer-events: visible;
}

.dialog_body{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}

.dialog_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    margin: 10px 0;
    pointer-events: visible;
}

.dialog_close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    pointer-events: visible;
}

.dialog_close > img {
    width: 100%;
    height: 100%;
}

.dialog_title {
    margin-top: 13px;
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
    text-align: center;
}

.dialog_text {
    padding: 0 10px;
    margin-top: 7px;
    font-size: 12.5px;
    line-height: 15px;
    text-align: center;
    overflow: hidden;
    font-weight: 500;
}

.dialog_line {
    position: absolute;
    bottom: 33px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
}

.dialog_options {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.dialog_option_no, .dialog_option_yes {
    font-size: 12.5px;
    line-height: 15px;
    padding: 9px 0;
    width: 50%;
    text-align: center;
    cursor: pointer;
    pointer-events: visible;
    background: none;
    border: none;
}

.dialog_option_no {
    color: #27648F;
}

.dialog_option_yes {
    color: #333333;
}

.dialog_option_divider {
    border-left: 1px solid #D9D9D9;
    height: 33px;
}
