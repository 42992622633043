#wrapper_advertising{
    padding-left: 20px;
    padding-right: 20px;
}
#img_advertising{
    border-radius: 10px;
    height: 150px;
    width: 100%;
    object-fit: fill;
    border: 1px solid rgba(51, 51, 51, 0.2);
    box-sizing: border-box;
}
@media (max-width: 768px) {
    #img_advertising{
        height: 70px;
    }
}