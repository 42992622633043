.previewReklama{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.previewReklama-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    overflow-y: auto;
    background: #FFFFFF;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
}

.previewReklama-content[data-device="desktop"]{
    width: 100%;
}

.previewReklama-content[data-device="mobile"]{
    width: 100%;
    max-width: 450px;
}

.previewReklama-title{
    width: 100%;
    display: inline-grid;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    font-size: 24px;
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
}

.previewReklama-title img{
    width: 12px;
}

.previewReklama-title p{
    white-space: nowrap;
}

.previewReklama-image{
    all: unset;
    width: 100%;
    object-fit: fill;
    border: 1px solid #333333;
    border-radius: 12px;
    overflow: hidden;
    box-sizing: border-box;
}

.previewReklama-image[data-preview="0"][data-device="desktop"]{
    min-height: 500px;
    height: 500px;
    max-height: 500px;
}

.previewReklama-image[data-preview="0"][data-device="mobile"]{
    min-height: 200px;
    height: 200px;
    max-height: 200px;
}

.previewReklama-image[data-preview="1"][data-device="desktop"]{
    min-height: 150px;
    height: 150px;
    max-height: 150px;
}

.previewReklama-image[data-preview="1"][data-device="mobile"]{
    min-height: 70px;
    height: 70px;
    max-height: 70px;
}


.previewReklama-description{
    width: 100%;
    font-size: 18px;
    white-space: pre-wrap;
    box-sizing: border-box;
    flex: 1;
    overflow-y: auto;
}

.previewReklama-link{
    font-size: 18px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.previewReklama-link a{
    padding: 10px 20px;
    background: #333333;
    color: #FFFFFF;
    border-radius: 12px;
    border: 1px solid transparent;
}

.previewReklama-link a:hover{
    background: #FFFFFF;
    color: #333333;
    border: 1px solid #333333;
}