.home-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    margin: 15px -20px -12px;
}
#swiper-container{
    padding-top: 15px;
    padding-left: 20px;
    padding-bottom: 15px;
}

.categories-div {
    margin: 0;
    padding: 0;
    height: 27px;
    background: #FFFFFF;
    box-shadow: 2px 2px 0px rgb(0 0 0 / 25%), 0px 0px 10px rgb(0 0 0 / 25%), 0px 0px 5px rgb(255 255 255 / 25%);
    border-radius: 7px;
    max-width: 250px;
    min-width: 190px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    margin-top: -5px;
    margin-bottom: -5px;
}

.tag-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.categories-div:after{
    content:url("../../../../public/Pictures/strelochka.svg");
    transform: scale(-0.7, 0.6);
    margin-left: auto;
}