#orderformation-contaiter{
    padding-top: 10px;
}
#supplyer-contaiter{
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 9px;
    padding: 10px;
}
#supplyer-name{
    padding-left: 7px;
}
#supplyer-name-and-number{
    display: flex;
    justify-content: space-between;
    padding-top: 3px;
    padding-bottom: 10px;
}
#number-of-payment{
    display: flex;
    flex-direction: row;
    color: #C38989;
    font-size: 14px;
}
#positions-and-address-container{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}
#number-of-positions{
    padding-top: 3px;
}
#delivery-address{
    margin-top: 10px;
    width: 158px;
    height: 27px;
}
#delivery-address span{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    color: #FFFFFF;
    height: 27px;
}
#sum-of-order{
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 10px 10px;
    margin-top: 9px;
}
#create-an-order-button-wrapper{
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
#create-an-order-button{
    display: flex;
    justify-content: center;
    border-image: url("/public/Pictures/Rectangle 818.png") 0 fill / 0px / 0px space;
    width: 371px;
    height: 37px;
}
#create-an-order-button span{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    color: #FFFFFF;
}
#order-formation-title{
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
}
#order-formation-title-div{
    height: 32px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding-right: 20px;
    padding-left: 20px;
    background: #F7F7F7;
    margin-left: auto;
}
#return-to-cart{
    width: 8px;
    height: 16px;
}
#trash-icon-of{
    width: 10px;
    height: 12px;
 }
#img_dashed{
    width: 100%;
    height: 1px;
    margin-top: 12px;
}
.NumberOfOrderAndTrash{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.orderFormationItem-bonuses{
    margin-left: 10px;
    color: #01aba2;
}

