.productDetails-mainContainer {
    max-width: 1165px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
}

.productDetails-navigate-container {
    width: 100%;

    display: flex;
    justify-content: center;
    flex-direction: row;
    position: relative;
}

.productDetails-navigate-image {
    position: absolute;
    left: 0;
    width: 12px;
    height: 27px;
}

.productDetails-header {
    font-weight: 600;
    font-size: 32px;
    line-height: 100%;
}

.productDetails-hint {
    margin-top: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
}

.productDetails-firstContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.productDetails-patternCard-container {
    width: 20%;
    margin-right: 10px;
}

.productDetails-patternCard {
    height: 225px;
    background: #ffffff;
    box-shadow: 0 3.98362px 19.9181px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.productDetails-patternCard-image {
    width: 140px;
    height: 128px;
    background-color: #17a1fa;
}

.productDetails-patternCard-name {
    margin: 0 17px;
    font-weight: 500;
    font-size: 17.0886px;
    line-height: 21px;

    color: #2f2121;
}

.productDetails-productNames {
    width: 100%;
}

.productDetails-productNames-header {
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
}

.productDetails-inputName {
    display: flex;
    flex-direction: column;
}

.productDetails-hint-text {
    margin: 20px 0 10px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
}

.productDetails-categories-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.productDetails-dropDown {
    height: 60px;
}

.productDetails-category {
    width: 50%;
    margin-right: 5px;
}

.productDetails-subcategory {
    width: 50%;
    margin-left: 5px;
}

.productDetails-secondContainer {
    width: 100%;
    display: grid;
    flex-direction: row;
    align-items: flex-end;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
}

.productDetails-photo {
    display: flex;
    flex-direction: column;
}

.productDetails-photo-image {
    height: 200px;

    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b6babd;
    border-radius: 12px;
    position: relative;
}

.productDetails-photo-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.productDetails-photo-image-dragEnter {
    border-color: transparent;
    outline: 3px dashed black;
    outline-offset: 0;
}

.productDetails-photo-image-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: -1;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.productDetails-photo-button {
    margin-top: 10px;
    height: 60px;
    background: #efefef;
    border: 1px solid #b6babd;
    border-radius: 12px;
    background-image: url("/public/Pictures/reviewIcon.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    cursor: pointer;
}

.productDetails-photo-delete {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
}

.productDetails-description {
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.productDetails-description-input {
    flex: 1;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    width: 100%;
    background-color: #fff;
    border: 1px solid #b6babd;
    border-radius: 10px;
    color: #333333;
    padding: 15px 15px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 200px;
    position: relative;
    resize: none;
}

.productDetails-description-input::-webkit-input-placeholder {
    position: absolute;
    bottom: 45%;
    right: 50%;
}

.productDetails-description-input:-moz-placeholder {
    /* Firefox 18- */
    position: absolute;
    bottom: 50%;
    right: 50%;
}

.productDetails-description-input::-moz-placeholder {
    /* Firefox 19+ */
    position: absolute;
    bottom: 50%;
    right: 50%;
}

.productDetails-description-input:focus::-webkit-input-placeholder {
    visibility: hidden;
}

.productDetails-description-input:focus:-moz-placeholder {
    visibility: hidden;
}

.productDetails-description-input:focus::-moz-placeholder {
    visibility: hidden;
}

.productDetails-addSettings, .productDetails-addSettings-firstBuy {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    width: 100%;
    background-color: #fff;
    border: 1px solid #b6babd;
    border-radius: 10px;
    color: #333333;
    padding: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    position: relative;
    resize: none;
}

.productDetails-addSettings-firstBuy{
    border-color: #01aba2;
}

.productDetails-firstBuy{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.productDetails-firstBuy-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 50%;
}

.productDetails-firstBuy-input{

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 22px;
}

.productDetails-bonuses{
    color: #01aba2;
}

.productDetails-masks {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.productDetails-addSettings-left {
    width: 50%;
}

.productDetails-addSettings-right {
    width: 50%;
}

.productDetails-addSettings-left > span {
    font-size: 10px;
    color: #7a7a7a;
    margin-top: 10px;
}

.productDetails-addSettings-right > span {
    font-size: 10px;
    color: #7a7a7a;
    margin-top: 10px;
}

.productDetails-addSettings-check {
    display: flex;
    gap: 10px;
    /*margin-top: 10px;*/
}

.productDetails-addSettings-check > img {
    max-height: 20px;
}

.productDetails-pricesVip {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
}

.productDetails-priceVip, .productDetails-bonusesVip {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.productDetails-priceVip > .custom-input, .productDetails-bonusesVip > .custom-input{
    width: 100%;
}

.productDetails-bonusesVip > .custom-input > .custom-input-field{
    color: #01aba2;
}

.productDetails-priceInputContainer, .productDetails-bonusesInputContainer{
    width: 20%;
}

.productDetails-bonusesInputContainer > .custom-input > .custom-input-field{
    color: #01aba2;
}

.productDetails-pricesVip-hint{
    width: 50%;
    min-width: 500px;
    margin: 15px 15px 0 15px;
    list-style: initial;
    font-size: 11px;
    color: #7a7a7a;
}

.productDetails-thirdContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.productDetails-priceContainer {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: start;
    gap: 10px;
}

.productDetails-priceInputContainer[data-visible="true"] {
    opacity: 50%;
}

.productDetails-hint-image {
    margin-left: 5px;
}

.productDetails-button {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    width: 20%;
    height: 60px;
    background: #efefef;
    border: 1px solid #b6babd;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
}

.productDetails-statusContainer {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: start;
    gap: 10px;
}

.productDetails-status {
    display: flex;
    flex-direction: column;
    width: calc(40% + 10px);
}

.productDetails-status-input {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    height: 60px;
    background-color: #fff;
    border: 1px solid #b6babd;
    border-radius: 10px;
    color: #333333;
    padding-left: 21px;
    box-sizing: border-box;
}

.productDetails-storageContainer {
    width: calc(40% + 10px);
}

.productDetails-buttonSave {
    width: 100%;
    margin-top: 40px;
    height: 60px;

    border: 1px solid #333333;
    border-radius: 12px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    background-color: #333333;
    color: #ffffff;
}

.productDetails-buttonSave-2 {
    width: 47%;
    margin-top: 40px;
    height: 60px;
    border: 1px solid #333333;
    border-radius: 12px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    background-color: #333333;
    color: #ffffff;
}

.productDetails-buttonSave:hover {
    background-color: #ffffff;
    color: #333333;
    transition: 300ms;
}

.productDetails-buttonSave-2:hover {
    background-color: #ffffff;
    color: #333333;
    transition: 300ms;
}

.productDetails-previewContainer {
    overflow: hidden;
}

.productDetails-previewContainer[data-visible="firstLoad"] {
    max-height: 0;
}

.productDetails-previewContainer[data-visible="true"] {
    animation: animVis 5s forwards;
}

.productDetails-previewContainer[data-visible="false"] {
    animation: animHid 2s forwards;
}

@keyframes animVis {
    0% {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
    }
    50% {
        max-height: 400px;
        opacity: 0.5;
    }
    100% {
        overflow: visible;
        opacity: 1;
    }
}

@keyframes animHid {
    0% {
        max-height: 400px;
        overflow: hidden;
        opacity: 1;
    }
    50% {
        max-height: 0;
        opacity: 0;
    }
    100% {
        max-height: 0;
        overflow: hidden;
    }
}

.productDetails-errorPage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin: 90px auto 0px auto;
}

.productDetails-error-text {
    margin-top: 40px;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
}

.productDetails-error-button {
    width: 60%;
    margin-top: 40px;
    height: 60px;

    border: 1px solid #333333;
    border-radius: 12px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    background-color: #333333;
    color: #ffffff;
}

.productDetails-error-button:hover {
    background-color: #ffffff;
    color: #333333;
    transition: 300ms;
}

.productDetails-supplier-buttonSave,
.productDetails-supplier-buttonDelete {
    width: 100%;
    margin-top: 40px;
    height: 60px;

    border: 1px solid #333333;
    border-radius: 12px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    background-color: #333333;
    color: #ffffff;
}

.productDetails-supplier-buttonSave:hover {
    background-color: #ffffff;
    color: #333333;
    transition: 300ms;
}

.productDetails-supplier-buttonDelete[data-deleted="false"] {
    background-image: url("/public/Pictures/Trash-white.svg");
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: center center;
}

.productDetails-supplier-buttonDelete:hover[data-deleted="false"] {
    background-image: url("/public/Pictures/Trash-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #ffffff !important;
}

.productDetails-supplier-buttonDelete[data-deleted="true"] {
    background-image: url("/public/Pictures/Return-white.svg");
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: 98% center;
    width: 100%;
}

.productDetails-supplier-buttonDelete:hover[data-deleted="true"] {
    background-image: url("/public/Pictures/Return-black.svg");
    background-color: #ffffff !important;
    color: #333333;
}

.productDetails-supplier-buttonSave {
    width: 90%;
    /*flex: 1 1;*/
}

.productDetails-supplier-buttonDelete {
    width: 10%;
}
