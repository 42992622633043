.allMessages {
    padding: 0 10px 0 0;
    display: flex;
    flex-direction: column;
    gap: 7px;

}

.popup-messages {
    position: relative;
    background-color: #fff;
    padding: 5px 10px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}
.popupClose {
    cursor: pointer;
    text-align: center;
    /* margin-top: 10px; */
    color: #007bff;
}
.chat-header{
    display: grid;
    position: fixed;
    width: calc(100vw - 430px - 60px);
    background: lightgrey;
}
.chat-header-div{
    padding: 5px 10px;
}
.allMessages_empty {
    box-shadow: 0 3.98362px 19.9181px rgba(0, 0, 0, 0.22);
    border-radius: 100px;
    margin: 250px auto 0;
    width: 200px;
    padding: 17px 15px;
    font-size: 17px;
    text-align: center;
}
.message_date_notification{
    display: flex;
    justify-content: center;
    margin: 40px 0 10px 0;
    font-size: 14px;
}
.message_date_notification:first-child{
    margin-top: 0;
}