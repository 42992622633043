.aboutUs-div {
    padding: 13px 0 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.aboutUs-header {
    margin: 10px 0 0 0;
    font-size: 24px;
    line-height: 21px;
    text-align: start;
}
.aboutUs-text{
    margin-bottom: 1em;
}
.aboutUs-text-bold{
    font-weight: 700;
}
.aboutUs-contacts{
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 232px;
    height: 84px;
}

.aboutUs-div > h1{

    font-size: 24px;
    line-height: 21px;
    text-align: start;
}

.aboutUs-documents{
    display: flex;
    flex-direction: column;
    font-size: 20px;
    gap: 1.3em;
    margin: 1.3em 0;
}

.aboutUs-documents div{
    display: inline-flex;
    gap: 10px;
}

.aboutUs-documents-button{
    all: unset;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
    font-size: 20px;
}
