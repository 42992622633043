.catalog-supplier{
    display: block;
    height: 1000px;
    margin-top: 30px;
}
.catalog-navigate-header{
    margin-bottom: 40px;
}
.catalog-content{
    position: relative;
    display: block;
}

.catalog-content-dropdowns{
    display: flex;
    position: relative;
    width: 100%;
}
.catalog-dropdown-description{
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    margin-bottom: 10px;
}
.catalog-dropdown-container:first-child{
    position: absolute;
    left: 0;
    height: 52px;
    width: 49.5%;
}
.catalog-dropdown-container:last-child{
    position: absolute;
    right: 0;
    height: 52px;
    width: 49.5%;
}
.catalog-supplier-swiper{
    position: absolute;
    top: 88px;
    width: 100%;
    display: block;
}
.catalog-supplier-swiper-checkAll{
    position: absolute;
    top:0;
}
.catalog-supplier-empty{
    width: 650px;
    height: 100px;
    display: flex;
    margin: 100px auto 0 auto;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 34px;
    line-height: 30px;
    background-color: #FFFFFF;
    color: #333333;
    border-radius: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}