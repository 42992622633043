.flex_center {
    display: flex;
    align-items: center;
}
#menu-container {
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    width: 100%;
}
#menu-location-Auth {
    cursor: pointer;
    width: fit-content;
    gap: 5px;
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 14px;
    padding: 10px  0;
    white-space: nowrap;
    justify-content: center;
    font-size: 14px;
    margin-right: auto;
}
#menu-location-Auth > div:first-of-type{
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
}
#menu-profile{
    display: flex;
    align-items: center;
    gap: 5px;
}
#menu-profile-div{
    width: 33%;
}
#menu-profile span{
    max-width: 70px;
    overflow: hidden;
    white-space: nowrap;
}
#img-profile-menu {
    width: 35px;
    height: 35px;
    object-fit: cover;
}
#img-profile-container{
    width: 35px;
    height: 35px;
    border-radius: 50px;
    border-style: solid;
    border-color: #bbbbbb;
    border-width: 1px;
    overflow: hidden;
}


#orders-and-cart{
    display: flex;
    align-items: center;
    width: 33%;
    justify-content: end;
}
#img-menu-orders{
    width: 15px;
    height: 15px;
}
#menu-point{
    width: 6px;
    height: 6px;
    margin-top: -10px;
    margin-left: -4px;
}
#img-menu-cart{
    width: 15px;
    height: 15px;
    margin-left: 17px;
}
#header-link{
    display: flex
}
#default-profile {

    width: 35px;
    height: 35px;
    border-radius: 50px;
    border-style: solid;
    border-color: gray;
    border-width: 0;
}
.menuEmptyDiv{
    width: 33%;
}
.SearchbarLogoUserMenu {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-right: 0px;
    margin-right: -8px;
}