.login_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.Button_container_login {
  display: flex;
  gap: 20px;
}

.login_pass_container{
  position: relative;
}

.login_pass_input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 60px 12px 20px;
  gap: 10px;
  width: 290px;
  height: 40px;
  border: 1px solid #b6babd;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-bottom: 10px;
  font-size: 16px;

  position: relative;
}

.login_pass_input_eye{
  position: absolute;
  height: 70%;
  right: 10px;
  z-index: 10;
}

.login_button {
  width: 290px;
  height: 40px;
  background-color: #333333;
  color: white;
}

.reg_button {
  border: 1px solid #333333;
  border-radius: 8px;
  margin-bottom: 20px;
}

.login_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.login_label {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.login_label input {
  border: none;
  border-bottom: 1px solid #b8bcbf;
  outline: none;
}

.login_input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 16px 12px 20px;
  gap: 10px;
  width: 290px;
  height: 40px;
  border: 1px solid #b6babd;
  border-radius: 8px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-bottom: 10px;
  font-size: 16px;

}

.login_a {
  height: 15px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-decoration-line: underline;

  color: #333333;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.sign_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.logincomponent_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  border-radius: 8px;
  margin-top: 10px;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 600;
}

.greetings {
  width: 290px;
  height: 48px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  margin-bottom: 20px;
}

.greetings-h1 {
  margin-top: 25px;
  height: 29px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  color: #333333;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  margin-bottom: 20px;
}
/*.login_button:active{*/
/*  width:98%*/
/*}*/

.login_span {
  width: 123px;
  height: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #333333;
  white-space: nowrap;
}

.checkbox {
  width: 250px;
  height: 18px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.eye_img {
  margin-left: 15px;
}