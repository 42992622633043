@media only screen and (min-width : 800px){
    .aboutUs-header {
        font-size: 32px;
    }
    .aboutUs-div{
        padding: 30px 0 30px;
        font-size: 20px;
    }
    .aboutUs-text{
        margin-bottom: 1.3em;
    }
    .aboutUs-contacts{
        font-size: 20px;
        width: 347px;
        height: 84px;
    }

    .aboutUs-div > h1{
        font-size: 32px;
    }
}