.productSwiper > .swiper-wrapper > .swiper-slide{
    flex-shrink: unset!important;
    width: auto!important;
}
/*Swiper styles*/
#swiper-container{
    padding-bottom: 0px !important;
}

#swiper-container > .swiper{
    max-width: calc(100% + 20px);
    box-sizing: border-box;
    margin-left: -20px!important;
    margin-right: -20px!important;
}

#img_swiper{
    width: 193px;
    height: 35px;
    /*
    margin-top: 15px;
    margin-left: 10px;
    */
}
.swiper {
    /*87% 2.33333*/
    height: 100%;
    padding-top: 15px;
    padding-bottom: 20px;
    margin: 0px;
    /*
    margin-top: 0px;
    margin-left: -40px;
    padding-top: 10px;
    padding-left: 90px;
    padding-bottom: 52px;

     */
}

.swiper-slide {
    text-align: left;
    font-size: 10px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

/*.swiper-slide img {*/
/*    display: block;*/
/*    width: 140px;*/
/*    height: 185px;*/
/*    object-fit: contain;*/
/*}*/

.swiper-pagination-bullet {
    width: 40px;
    height: 6px;
    border-radius: 23px;
    display: none;
}

.swiper-pagination-bullet-active {
    background: #D9D9D9;
    width: 40px;
    height: 6px;
    border-radius: 23px;
    display: none;
}
.swiperPlusContainer{
    display: flex;
    align-items: center;
    width: 50px;
    margin: 0 5px;
    cursor:pointer;
}
.swiperPlusContainer>img{
    width: 100%;
}

.swiper-wrapper {
    align-items: center;
}
