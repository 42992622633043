@media only screen and (min-width : 800px){
    #cart-title-div {
        height: 72px;
    }
    .cart-title{
        font-size: 32px;
        line-height: 32px;
    }
    #arrow-img{
        height: 27px;
        width: 12px;
    }
}

@media screen and (max-width : 450px){
    .navigate-title-div{
        position: relative;
    }
    .cart-title{
        font-size: 18px;
    }
    #arrow-img{
        position: absolute;
        left: 25px;
        width: 10px;
    }
    #arrow-img::after{
    }
}