.admin_scph_main_container{
    padding: 0 9.5%;
}
.admin_scph_main_container .supplier_scph_second_page_container{
    margin-top: 40px;
    padding-top: 0;
}
.admin_scph_main_container .supplier_scph_second_page_container > img{
    top: 4px;
}
.admin_scph_img_container{
    right: 5px;
    top: 3px;
}
.admin_scph_table_container_2 > .table-container tbody .searchRow > .custom-tbf:nth-child(3) > div > div{
    display: inline;
    padding: 4px 8px;
    margin: 0 auto;
    background-color: #D7D7D7;
    border-radius: 100px;
}

.supplier-nh-title{
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    text-align: center;
    color: #333333;
    margin-left: auto;
    margin-right: auto;
}