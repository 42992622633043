.SupplierOrderPage-upper-title{
    margin-top: 25px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 29.26px;
}
.SupplierOrderPage-status-list-wrapper{
    display: block;
}
.SupplierOrderPage-status-list{
    display: flex;
    padding: 0 8px 0 8px;
    justify-content: space-between;
}
.SupplierOrderPage-status-card{
    display: block;
    width: 72px;
}
.SupplierOrderPage-status-logo{
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    margin: auto;
    text-align: center;
    height: 48px;
    width: 48px;
    border-radius: 48px;
    background-color: #C38989;
    margin-bottom: 9px;
}
.SupplierOrderPage-status-logo-picture{
    margin:auto;
    max-width: 22px;
    max-height: 22px;
    min-width: 16px;
    min-height: 16px;
}
.SupplierOrderPage-green-status{
    background-color: #508E46;
}
.SupplierOrderPage-red-status{
    background-color: #8E4646;
}
.SupplierOrderPage-black-status{
    background-color: #333333;
}
.SupplierOrderPage-status-title{
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    margin-bottom: 5px;
    text-align: center;
}
.SupplierOrderPage-status-time{
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
}
.SupplierOrderPage-main-div{
    position: relative;
    display: block;
    margin:0 138px 100px 138px;
}
.SupplierOrderPage-string{
    margin-top: 10px;
    display: flex;
    justify-content: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.5px;
}
.SupplierOrderPage-title-string{
    margin-right: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.5px;
}
.SupplierOrderPage-big-title-string{
    font-weight: 600;
    font-size: 18px;
    line-height: 21.94px;
}
.SupplierOrderPage-order-list{
    padding: 13.86px 33.29px 0 33.29px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    margin: 40px 0 40px 0;
}
.SupplierOrderPage-table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 13.86px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17.07px;
}
.SupplierOrderPage-columns{
    vertical-align: top;
    text-align: left;
}
.SupplierOrderPage-first-column{
    width: 40%;
}
.SupplierOrderPage-second-column{
    width: 15%;
    text-align: center;
}
.SupplierOrderPage-third-column{
    text-align: right;
    width: 22%;
}
.SupplierOrderPage-fourth-column{
    width: 23%;
    text-align: right;
    text-decoration: underline;
}
.SupplierOrderPage-fourth-column-red{
    color: #8E4646;
}
.SupplierOrderPage-comment{
    margin: 8px 0 20px 0;
    padding: 8px 15px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.07px;
}
.SupplierOrderPage-without-discount{
    text-align: right;
    text-decoration: line-through;
    text-decoration-color: #DB2F2F;
    color: #7A7A7A;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
}
#SupplierOrderPage-sum-of-order{
    display: flex;
    justify-content: center;
    position: relative;
    margin: 3px 0 2px 0;
    padding-left: 815px;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.94px;
}
#SupplierOrderPage-price-of-order{
    padding-left: 6px;
}
.SupplierOrderPage-discount{
    display: flex;
    justify-content: right;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    color: #C38989;
}
.SupplierOrderPage-discount-number{
    margin-left: 3px;
    padding: 0 2px 0 2px;
    background-color: #C38989;
    color: #FFFFFF;
}
#SupplierOrderPage-img_dashed{
    width: 100%;
    margin-top: 12px;
}