.flex_center {
    display: flex;
    align-items: center;
}

.menu-container_dealer {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    gap: 5px;
}

.menu-profile {
    display: flex;
    align-items: center;
    gap: 10px;
}

/*#menu-profile-div{*/
/*    width: 33%;*/
/*}*/
.menu-profile span {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

.img-profile-menu {
    width: 35px;
    object-fit: cover;
}

.img-profile-container_dealer {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    background-color: black;
    border: 1px solid #333333;
}

.img-profile-container_dealer:hover .img-profile-menu {
    opacity: 0.4;
}

.img-profile-menu {
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;
}

.overlay-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Центрирование */
    opacity: 0;
    transition: opacity 0.3s;
}

.img-profile-container_dealer:hover .overlay-image {
    opacity: 1;
}

.orders-and-cart {
    display: flex;
    align-items: center;
    width: 33%;
    justify-content: end;
}

.img-menu-orders {
    width: 15px;
    height: 15px;
}

.menu-point {
    width: 6px;
    height: 6px;
    margin-top: -10px;
    margin-left: -4px;
}

.img-menu-cart {
    width: 15px;
    height: 15px;
    margin-left: 17px;
}

.header-link {
    display: flex
}

.default-profile {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    border-style: solid;
    border-color: gray;
    border-width: 0;
}

.menuEmptyDiv {
    width: 33%;
}