#myorder-payment-upper-title{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24.38px;
}
#myorder-payment-title{
    font-weight: 600;
    font-size: 14px;
    line-height: 17.07px;
    padding: 20px;
}

.myorder-payment-userBonuses{
    padding: 0px 20px 5px 20px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: 16px;
}

.myorder-payment-userBonusesHold{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: 16px;
    padding: 5px 20px 15px 20px;
    color: #7A7A7A;
}

.myorder-payment-userBonuses-value{
    color: #01aba2;
}

#myorder-payment-status-list{
    padding:0 20px 0 20px;
}
.payment-option{
    position: relative;
    display: flex;
    padding: 24px 20px 23px 10px;
    margin-bottom: 10px;
    background: #F4F6F8;
    border-radius: 10px;
}
.payment-option-description{
    font-weight: 500;
    font-size: 14px;
    line-height: 17.07px;
    margin-left: 5px;
}
.payment-option-price{
    position: absolute;
    top:25px;
    right: 20px;
}
#myorder-payment-confirm{
    margin-top: 20px;
    text-align: center;
}