/* Первая страница */

.supplier_scph_main{
    position: relative;
    padding: 0px 0 90px 0;
}
.supplier_scph_first_page{
    max-height: 900px;
}
.supplier_scph_first_page > .Searchbar{
    width: calc(100% - 30px);
}
.myorderclosed-div{
    display:grid;
    max-width: 520px;
    grid-template-columns:1fr 1fr;
    gap: 10px;
    margin: 0 auto;
}
.myorderclosed-div .profile-datePicker input{
    margin-left:0;
}
.supplier_scph_first_page_table{
    overflow: auto;
    max-height: 700px;
}

.supplier_scph_first_page > #navigate-title-div{
    margin-bottom: 2.75%;
}
.supplier_scph_balance{
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 250px;
}
.supplier_scph_balance_title{
    font-size: 28px;
}
.supplier_scph_total{
    margin-left: 2%;
    font-size: 28px;
    font-weight: 300;
    text-wrap: nowrap;
}
.supplier_scph_empty{
    box-shadow: 0 3.98362px 19.9181px rgba(0, 0, 0, 0.32);
    display: flex;
    justify-content: center;
    width: 400px;
    margin: 150px auto;
    background-color: #333333;
    color: white;
    padding: 60px 60px;
    font-size: 30px;
    text-wrap: nowrap;
    border-radius: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* стили первой таблицы */
.supplier_scph_first_page > .supplier_scph_first_page_table > .table-container{
    width: 100%;
    margin: 15px 0;
}
.supplier_scph_first_page > .supplier_scph_first_page_table .TableLongInfo{
    display: flex;
    justify-content: center;
}
.supplier_scph_first_page > .supplier_scph_first_page_table .TableLongInfo > .TableLongText{
    overflow: auto;
    text-overflow: unset;
}
.supplier_scph_first_page > .supplier_scph_first_page_table .TableLongInfo ::-webkit-scrollbar{
   height: 8px;
}
/* хедер */
.supplier_scph_first_page > .supplier_scph_first_page_table > .table-container thead{
    height:40px;
}
.supplier_scph_first_page  > .supplier_scph_first_page_table > .table-container thead > tr, .supplier_scph_first_page > .supplier_scph_first_page_table > .table-container thead > tr th{
    height: inherit;
}
.supplier_scph_first_page > .supplier_scph_first_page_table > .table-container thead > tr .custom-thf > div{
    height: inherit;
    display:flex;
    align-items: center;
    justify-content: center;
}
.supplier_scph_first_page > .supplier_scph_first_page_table > .table-container thead > tr > th:not(.custom-thf) > div{
    height: inherit;
}
.supplier_scph_first_page > .supplier_scph_first_page_table > .table-container .table-head-line{
    width: 2px;
    height:22px;
    background-color:#A7A7A7;
    border-radius:1px;
}
/* тело */
.supplier_scph_first_page > .supplier_scph_first_page_table > .table-container > .custom-table{
    border-color: black;
}
.supplier_scph_first_page > .supplier_scph_first_page_table > .table-container tbody > tr > td:nth-child(odd){
    width: 18%;
    overflow: hidden;
}
.supplier_scph_first_page > .supplier_scph_first_page_table > .table-container tbody > tr > td:nth-child(even){
    width: 2.5%;
    overflow: hidden;
}
.supplier_scph_drop_img {
    position: absolute;
    right: 50px;
    top: -12px;
    transition: 400ms;
}
.supplier_scph_drop_img_checkbox{
    position: absolute;
    cursor: pointer;
    height: 30px;
    top: -15px;
    left: -20px;
    width: 110%;
    opacity: 0;
    z-index: 3;
}
.supplier_scph_drop_img_checkbox:checked + .supplier_scph_drop_img{
    rotate: 360deg;
}

.supplier_scph_first_page > .supplier_scph_first_page_table > .table-container tbody .searchRow > .custom-tbf:nth-child(3) > div > div{
    display: inline;
    padding: 4px 8px;
    margin: 0 auto;
    background-color: #D7D7D7;
    border-radius: 100px;
}




/* Вторая страница */
.supplier_scph_second_page_container{
    z-index: 3 !important;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    position: absolute;
    top: 0;
    background-color: #FFFFFF;
}

.supplier_scph_second_page_container.entering{
    animation: Show-Table .5s forwards;
}
.supplier_scph_second_page_container.exiting{
    animation: Show-Table .5s reverse;
}


.supplier_scph_second_page_description{
    display: flex;
    font-size: 20px;
    flex-direction: column;
}
.supplier_scph_second_page_description_date{
    font-weight: 400;
    margin-left: 10px;
}
.supplier_scph_second_page_description > div{
    margin-bottom: 20px;
    display: flex;
}

.supplier_scph_second_page > .Searchbar{
    margin: 30px auto;
}

.supplier_scph_second_page_container > img{
    position: absolute;
    top: 43px;
    width: 25px;
}
.supplier_scph_empty_search {
    width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    font-size: 28px;
    box-shadow: 0 3.98362px 19.9181px rgba(0, 0, 0, 0.12);
    border-radius: 100px;
    padding: 60px 60px;
}

/* Вторые таблицы */

.supplier_scph_table_container_2{
    position: relative;
    margin-bottom: 20px;
}
.supplier_scph_table_container_2 .TableLongInfo{
    display: flex;
    justify-content: center;
}
.supplier_scph_table_container_2 .TableLongInfo > .TableLongText{
    overflow: auto;
    text-overflow: unset;
}
.supplier_scph_table_container_2 .TableLongInfo ::-webkit-scrollbar{
    height: 8px;
}
.supplier_scph_table_header{
    margin-left: 10px;
}
.supplier_scph_table_container_2 > .table-container{
    width: 100%;
    margin: 0;
}

/* хедеры */
.supplier_scph_table_container_2 > .table-container thead {
    height: 40px;
}
.supplier_scph_table_container_2> .table-container thead > tr, .supplier_scph_table_container_2 > .table-container thead > tr th{
    height: inherit;
}
.supplier_scph_table_container_2 > .table-container thead > tr .custom-thf > div{
    height: inherit;
    display:flex;
    align-items: center;
    justify-content: center;
}
.supplier_scph_table_container_2 > .table-container thead > tr > th:not(.custom-thf) > div{
    height: inherit;
}
.supplier_scph_table_container_2 > .table-container .table-head-line{
    width: 2px;
    height:22px;
    background-color:#A7A7A7;
    border-radius:1px;
}
/* тела таблиц */
.supplier_scph_table_container_2 > .table-container > .custom-table{
    border-color: black;
}
.supplier_scph_table_container_2 > .table-container tbody > tr > td:nth-child(odd){
    width: 18%;
    overflow: hidden;
}

.supplier_scph_table_container_2 > .table-container tbody > tr > td:nth-child(even){
    width: 2.5%;
    overflow: hidden;
}



@keyframes Show-Table {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    50%{
        opacity: 1;
    }
    100% {
        transform: translateX(0);
    }
}

