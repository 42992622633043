#navigate-title-div {
    display: flex;
    align-items: center;
    background: white;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
}
.cart-title{
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
}
#trash-icon {
    margin-left: -30px;
    padding-left: 30px;
    padding-top: 15px;
    margin-top: -15px;
    padding-bottom: 15px;
    margin-bottom: -15px;
    height: 20px;
}
#arrow-img{
    cursor: pointer;
    box-sizing: content-box;
    position: absolute;
    left: 25px;
    height: 27px;
    width: 12px;
    padding: 5px 15px 5px 5px;
}

.navigate-title-close{
    cursor: pointer;
    box-sizing: content-box;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    height: 70%;
    max-height: 30px;
    padding: 5px 15px 5px 5px;
}