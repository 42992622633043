.supplier_chat_window {
    display: flex;
    flex-direction: row;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0px 5% 0px;
    height: calc(100vh - 98px);
}
.supplier_left_menu_chat {
    width: 25%;
    min-width: 260px;
}
.supplier_left_menu_chat_title {
    margin: 10px 0 10px;
    text-transform: uppercase;
    font-size: 20px;
    padding: 15px 17px;
    background-color: rgb(236, 236, 236);
    border-radius: 10px;
    line-height: 1;
    width: 200px;
}
.supplier_left_menu_chat_title:first-child{
    margin: 30px 0 10px;
}
.supplier_ChatWindowItem_container {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
    overflow:auto;
    height: 80vh;
}
.supplier_chat_window_left {
    width: 100%;
}
.supplier_chat_right_container {
    height: inherit;
    width: 75%;
}
.supplier-chatwindow-container {
    width: 100%;
}
#supplier-chat-scrollbar::-webkit-scrollbar{
    width: 4px;
    background-color: white;
}