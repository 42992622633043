.register_form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.register_button {
    background-color: #ffffff;
    cursor: pointer;
}

/*.register_button:hover {*/
/*    background-color: #333333;*/
/*    color: white;*/
/*}*/

.register_input {
    box-sizing: border-box;
    font-size: 16px;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #bdbdbd;
    appearance: auto;
    border-radius: 10px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 10px;
    padding-left: 0px;
    width: 100% !important;
    padding-left: 25px;

    margin-bottom: 10px;
}
.register_input_text {
    display: flex;
    justify-content: start;
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    margin-bottom: 6px;
    margin-top: 15px;
}

.register_select_top_text {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
}

.register_select_text {
    display: flex;
    justify-content: start;
    font-size: 15px;
    font-weight: 500;
    width: calc(50% - 10px);
    margin-bottom: 6px;
    margin-top: 15px;
}

.register_select_component {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.register_select_component > .dropdown-internal-container > .dropdown-list-div{
    margin-top: 20px;
}

.register_select_component > .dropdown-internal-container > .DropdownDisplay {
    border: 1px solid #bdbdbd;
    border-radius: 10px;
}

.persolan_data_text {
    width: 100%;
    display: inline-flex;
    align-items: center;
    text-align: left;
    font-weight:lighter;
}

.register-persolan_data_button-container{
    display: flex;
    flex-direction: column;
}

.persolan_data_text button{
    all: unset;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
}

.eye_img {
    position: absolute;
    transform: translate(-50%, 50%);
    right: 15px;
}
.eye_img_2 {
    position: relative;
    right: 45px;
    top: 4px;
    height: 30px;
}
.input-file-container {
    position: relative;
    width: 225px;
}

.register_input-file-trigger {
    display: flex;
    padding: 14px 20px;
    background: #D0E2F2;
    color: #5e7293;
    font-size: 1em;
    transition: all .4s;
    cursor: pointer;
    border: 1px;
    border-radius: 10px;
    margin-left: auto;
}

.input-file {
    position: absolute;
    /*    top: 0;
    left: 0;
    width: 225px;*/
    opacity: 0;
    /*    padding: 14px 0;*/
    cursor: pointer;
    width: 100%;
    display: none;
}

.input-file:hover + .input-file-trigger,
.input-file:focus + .input-file-trigger,
.input-file-trigger:hover,
.input-file-trigger:focus {
    background: #8798A8;
    color: #00235e;
}

.file-return {
    margin: 0;
}

.file-return:not(:empty) {
    margin: 1em 0;
}

.file-return {
    font-style: italic;
    font-size: .9em;
    font-weight: bold;
}

.file-return:not(:empty):before {
    content: "��������� ����: ";
    font-style: normal;
    font-weight: normal;
}

.row {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 15px;
}

.diploma_Text {
    width: 50%;
    display: flex;
    margin: 15px;
}

.diploma_Text2 {
    text-overflow: ellipsis;
    overflow: hidden;
    margin: auto;
    width: 100%;
}

.register_clip_image {
    padding-right: 5px;
}

.btn_register {
    cursor: pointer;
    width: 100%;
    display: flex;
    padding: 14px 45px;
    background-color: #000;
    color: #fff;
    border: 1px;
    border-radius: 10px;
    margin: 30px;
    justify-content: center;
    font-weight: 600;
    /*position: relative;*/
    /*top:0;*/
}
.btn_register:hover{
    background-color: #00000080;
}

.btn_register[disabled]{
    cursor: not-allowed;
    background-color: #7A7A7A!important;
}
@keyframes buttonClick {
    0%{margin-top:30px}
    50%{margin-top: 34px}
    100%{margin-top:30px}
}
.row[data-clicked="yes"] > .btn_register {
    /*opacity: 70%;*/
    /*box-shadow: 0 5px #666;*/
    /*transform: translateY(2px);*/
    animation-duration: 0.5s;
    animation-name: buttonClick;
    animation-direction: alternate;
}
.col_line {
    width: 100%;
    margin-bottom: 15px;
}

.invalid-feedback {
    color: red;
    text-align: left;
    font-weight: 500;
    line-height: 15px;
}

.invalid_pass_feedback {
    color: red;
    text-align: center;
    margin-top: 0px;
}

.preview{
    height: 130px;
    width: 100px;
    border: solid 1px black;
    border-radius: 1em;
}
.preview[data-style="big"]{
    position: fixed;
    min-width: 100vw;
    min-height: 100vh;
    z-index: 100000;
    top: 0;
    left: 0;
    border: none;
    object-fit:contain;
}

.register_file_button_box {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
}

.register-popup-personal-data-bg{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.4);
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.register-popup-personal-data{
    max-width: 800px;
    width: 80%;
    /*height: 70dvh;*/
    max-height: 550px;
    margin: auto;
    background-color: #FFFFFF;
    border-radius: 15px;
    padding: 25px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.register-popup-header{
    width: 100%;
    display: inline-grid;
    grid-template-columns: 1fr 80% 1fr;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.register-popup-header div:nth-child(2){
    font-size: 16px;
    text-align: center;
}

.register-popup-header div img{
    cursor: pointer;
    width: 120%;
}

.register-popup-content{
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: stretch;
    align-items: stretch;
    flex: 1;
    font-size: 14px;
}

.register-popup-content ul{
    all: unset;
    list-style-type: disc;
}

.register-popup-content ul li{
    margin-left: 20px;
}

.register-captcha{
    margin-top: 10px;
}

@media screen and (max-width: 450px){
    .regionAndCity_container{
        display: flex!important;
        flex-direction: column!important;
    }
    .regionAndCity_container > .register_select_component{
        width: 100%;
        box-sizing: border-box;
    }
    .register_select_top_text{
        gap: 0;
    }
}