/*Swiper item styles*/

.product_wrap-slider {
    cursor: pointer;
    background: #ffffff;
    filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.12));
    /*box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);*/
    /*box-shadow: 4px 4px 1px rgb(0 0 0 / 25%), inset -2px -2px 10px rgb(255 255 255 / 25%), inset 1px 1px 5px rgb(0 0 0 / 10%);*/
    border-radius: 9px;
    min-width: 150px;
    width: 150px;
    max-width: 150px;
    height: 200px;
    font-size: 10px;
    line-height: 12px;
    text-align: left;
    padding: 10px 8px 8px;
    margin-right: 10px;
}
.product_wrap {
    background: #ffffff;
    box-shadow: 4px 4px 1px rgb(0 0 0 / 25%),
        inset -2px -2px 10px rgb(255 255 255 / 25%),
        inset 1px 1px 5px rgb(0 0 0 / 10%);
    border-radius: 9px;
    width: 126px;
    min-height: 165px;
    font-size: 10px;
    line-height: 12px;
    text-align: left;
    padding-left: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 8px;
    padding-right: 6px;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
}
.product_wrap:last-child {
    margin-right: auto;
}

#product_and_heart {
    position: relative;
    display: flex;
    justify-content: space-between;
    /*padding-left: 6.2px; /* (26-8) */
    /*padding-right: 6.2px;*/
    justify-content: center;
    overflow: hidden;
}

#productCard-1SConnected-img {
    width: 20px;
    height: 20px;
    object-fit: fill;
    /*position: absolute;*/
    z-index: 1;
}

#img_of_product {
    /* width: 80px; */
    object-fit: fill;
    height: 90px;
    position: relative;
}
#description_containter {
    padding-top: 5px;
    /*height: 38px;*/
    overflow: hidden;
}
#description_of_product {
    display: block;
    font-weight: 500;
    height: 37px;
    line-height: 12px;
    font-size: 12px;
}
#price1_and_amount {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    width: 100%;
    position: relative;
    top: -10px;
}
.price1 {
    display: block;
    font-size: 8px;
    color: #b8b8b8;
    line-height: 10px;
    padding-top: 3px;
    position: relative;
    top: 22px;
    text-decoration: line-through;
}
#amount {
    display: block;
    font-size: 9px;
    color: #6667ab;
}
.price2 {
    display: block;
    color: #6667ab;
    position: relative;
    top: -8px;
}

.productCard-price-amount-container {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.productCard-price-amount {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    color: #6667ab;
}

.productCard-price{
    display: flex;
    flex-direction: column;
}

.productCard-discount {
    color: #b8b8b8;
    font-size: 10px;
    text-decoration: line-through;
}

.productCard-amount-bonuses-container{
    display: flex;
    flex-direction: column;
    align-items: end;
}

.productCard-bonuses{
    color: #01aba2;
}

#text_supply_company {
    display: block;
    font-weight: 500;
    text-overflow: ellipsis;
    max-width: 105px;
    white-space: nowrap;
    overflow: hidden;
}
#img_supply_company {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.27);
    height: 20px;
    width: 20px;
    border-radius: 2px;
}
#button_cart {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
#add_to_cart {
    width: 94px;
    height: 24.08px;
}
#supply-company-div {
    box-sizing: border-box;
    display: grid;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    gap: 5px;
    grid-template-columns: 20px 1fr 20px;
    font-size: 12px;
}
.price-div {
    height: 26px;
}
.company-hidden {
    overflow: hidden;
    width: 82px;
}
.company-hidden:before {
    content: "…";
    position: absolute;
    margin-left: 82px;
}
.button_to_cart {
    background-color: #333333;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25),
        inset 1px 1px 4px rgba(255, 255, 255, 0.25),
        inset -1px -1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 34px;
}


@media screen and (max-width: 450px){
    .product_wrap-slider{
        margin-right: 5px;
    }
}