.popup-trainingCart-wrapper {
    width: 100%;
    position: fixed;
    top:0;
    left: 0;
    background-color: rgba(51, 51, 51, 0.63);
    z-index: 50;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.popup-trainingCart-container {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: 20px 5px;
    background-color: #FFFFFF;
    width: 90%;
    max-width: 600px;
    max-height: 90dvh;
    overflow-y: hidden;
    position: relative;
}

.popup-trainingCart-content{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: hidden;
    height: inherit;
    position: relative;
}

.popup-trainingCart-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

}
.popup-trainingCart-top b{

}
.popup-trainingCart-top-block {
    width: 250px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    column-gap: 5px;
}

.popup-trainingCart-top-block p {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
}

.popup-trainingCart-top-block > label > div {
    display: flex;
    align-items: center;
}

.popup-trainingCart-top-block label {
    width: 100%;
    display: flex;
    gap: 2px;
    flex-direction: column;
    line-height: 14px;
    font-weight: 500;
    margin-left: 5px;
    font-size: 14px;
}

.popup-trainingCart-top-block:first-child img {
    border: 1px solid #333333;
    border-radius: 50%;
    margin-right: 5px;
    padding: 2px;
    height: 16px;
    width: 16px;
}
.popup-trainingCart-top-block-buttons{
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap: 10px;
    align-items: center
}
.popup-trainingCart-buttonEdit,
.popup-trainingCart-buttonDelete,
.popup-trainingCart-buttonBlock,
.popup-trainingCart-buttonUnBlock,
.popup-trainingCart-buttonAttenders{
    width: 9%;
    height: 100%;
    aspect-ratio: 1;
    border: 1px solid #333333;
    border-radius: 5px;
    background-size: 50%;
    background-color: #333333;
}
.popup-trainingCart-buttonAttenders {
    background-image: url("/public/Pictures/UserIconForTable-white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}
.popup-trainingCart-buttonAttenders:hover {
    background-image: url("/public/Pictures/UserIconForTable.svg");
    background-color: #FFFFFF;
}
.popup-trainingCart-buttonEdit{
    background-image: url("/public/Pictures/Pencil-white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}

.popup-trainingCart-buttonClose{
    background-image: url("/public/Pictures/Close.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width:40px;
    height:40px;
    background-size: 50%;

    cursor: pointer;
}
.popup-trainingCart-buttonEdit{
    background-image: url("/public/Pictures/Pencil-white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}
.popup-trainingCart-buttonEdit:hover{
    background-image: url("/public/Pictures/Pencil.svg");
    background-color: #FFFFFF;
}
.popup-trainingCart-buttonDelete{
    background-image: url("/public/Pictures/Trash-white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}
.popup-trainingCart-buttonDelete:hover{
    background-image: url("/public/Pictures/Trash-black.svg");
    background-color: #FFFFFF;
}
.popup-trainingCart-buttonBlock{
    background-image: url("/public/Pictures/Blocking-a-white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    background-size: 90%;
}
.popup-trainingCart-buttonBlock:hover{
    background-image: url("/public/Pictures/Blocking-a-black.svg");
    background-color: #FFFFFF;
}
.popup-trainingCart-buttonUnBlock{
    background-image: url("/public/Pictures/Blocking-b-white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    background-size: 90%;
}
.popup-trainingCart-buttonUnBlock:hover{
    background-image: url("/public/Pictures/Blocking-b-black.svg");
    background-color: #FFFFFF;
}

.popup-trainingCart-bottom {
    width: 100%;
    display: inline-flex;
    position: relative;
}

.popup-trainingCart-bottom-content{
    width: 100%;
    overflow-y: auto;
    height: 700px;
    padding: 0 15px;
}

.popup-trainingCart-bottom-content[data-show-scroll="false"]{
    margin: 0;
}

.popup-trainingCart-bottom-content::-webkit-scrollbar{
    display: none;
}

.popup-trainingCart-bottom-scrollbar {
    position: absolute;
    top: 0;
    z-index: 10;
    right: 0;
    width: 10px;
    height: calc(100% - 40px);
    background-color: transparent;
    border-radius: 5px;
}

.popup-trainingCart-bottom-thumb{
    position: absolute;
    top: 0;
    width: 100%;
    height: 40px;
    background-color: #333333;
    border-radius: 5px;
}

.popup-trainingCart-image-container{
    position: relative;
}

.popup-trainingCart-image-increase{
    z-index: 10;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.58);
    border-radius: 12px;
    pointer-events: none;
}

.popup-trainingCart-bottom-img {
    box-sizing: border-box;
    min-width: 125px;
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.popup-trainingCart-bottom-block {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr auto;
}

.popup-trainingCart-bottom-block p {
    display: flex;
    flex-direction: column;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    margin: 10px 0;
}

.popup-trainingCart-bottom-block p label {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 10px;
    cursor: text;
}

.popup-trainingCart-bottom-block label {
    white-space: pre-wrap;
    line-height: 18px;
    font-weight: 400;
    font-size: 18px;
    cursor: text;
}

.popup-trainingCart-bottom-block a,
.popup-trainingCart-bottom-block div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #333333;
    width: 50%;
    border-radius: 8px;
    line-height: 16px;
    font-weight: 600;
    margin-top: 10px;
    font-size: 16px;
    cursor: pointer;
    color: #fff;
    height: 40px;
    aspect-ratio: 8;
}

@media (max-width: 1200px){
    .popup-trainingCart-container{
        max-width: 50%;
    }
}

@media (max-width: 768px) {
    .popup-trainingCart-container{
        max-width: 80%;
    }
    .popup-trainingCart-bottom-block label{
        -webkit-line-clamp: 3;
        font-size: 20px;
        line-height: 20px;
    }
    .popup-trainingCart-bottom-block p{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        font-size: 26px;
        line-height: 26px;
    }
}

@media (max-width: 500px) {
    .popup-trainingCart-bottom-block label{
        -webkit-line-clamp: 3;
        font-size: 22px;
        line-height: 22px;
    }
    .popup-trainingCart-bottom-block p{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        font-size: 28px;
        line-height: 28px;
    }
    .popup-trainingCart-container{
        max-width: 85%;

    }
    .popup-trainingCart-bottom-content{
        height: 450px;
    }

    .popup-trainingCart-bottom-block a,
    .popup-trainingCart-bottom-block div {
        width: 100%;
        margin-top: 10px;
        font-size: 16px;
        cursor: pointer;
        color: #fff;
        height: 32px;
        aspect-ratio: 8;
        margin-bottom: 10px;
    }
}
