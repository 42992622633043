#loader-container{
    position: fixed;

    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: auto;

    top: 0; left: 0; bottom: 0; right: 0;
    z-index: 20;
    background-color: white;
    opacity: 50%;
}