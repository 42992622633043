.container{
  width: 100%;
  position: fixed;
  top:0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.63);
  z-index: 20;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.content{
  width: 90%;
  max-width: 450px;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.selectFilter{
  width: 100%;
  font-size: 18px;
  line-height: 20px;
}

.inputContainer{
  margin: 0 auto;
  width: 100%;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 100%;
  height: 48px;
  margin: 0 auto;
  background: #333333;
  border: 1px solid #333333;
  border-radius: 12px;
  color: #FFFFFF;
  transition: 300ms;
}

.button:hover{
  background: #FFFFFF;
  color: #333333;
}