.emailConfirm-textblock {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    line-height: 16px;
}
.emailConfirm-inputField{

}
.emailConfirm-sendEmailButton {
    padding: 10px 20px;
    width: fit-content;
    background: #000;
    color: #fff;
    border: 1px;
    border-radius: 10px;
    margin: 20px 0px;
    justify-content: center;
    font-weight: 600;
    text-align: center;
}
.emailConfirm-sendEmailButton:hover{
    background-color: black;
    color: white;
}