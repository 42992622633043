.myorders-container{
    display: block;
}

.myorders-order-element {
    display: block;
    padding: 10px;
    margin: 20px 0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
}
.myorders-title{
    margin-top: 20px;
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
    size: 14px;
    line-height: 17.07px;
    font-weight: 600;
}
.myorders-continue-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    margin: 25px 0 20px;
    padding: 0 12px 0 10px;
    height: 39px;
    background: #FFFFFF;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
}
.myorders-continue-number{
    position: relative;
}
.myorders-number-ellipse{
    position: absolute;
    top: -2px;
    right: -3px;
    width: 6px;
    height: 6px;
}