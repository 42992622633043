.admin-navbar-menu{
    width: 100%;
    height: 1195px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
}
.admin-navbar-menu hr{
    width: 100%;
    margin: 10px 0 0 0;
    padding: 0;
    height: 0;
    border: none;
    border-top: 1px solid #D9D9D9;
}
.admin-navbarProfile{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.admin-navbarProfilePictures {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    object-fit: cover;
    /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.04), 0 1px 2px 0 rgba(0, 0, 0, 0.04), 0 3px 3px 0 rgba(0, 0, 0, 0.04), 0 7px 4px 0 rgba(0, 0, 0, 0.02), 0 13px 5px 0 rgba(0, 0, 0, 0.01), 0 20px 6px 0 rgba(0, 0, 0, 0.00); */
}
.admin-navbar-admin {
    color: #333;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
}
.admin-navbar-AdminName{
    color: #333;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 10px;
}
.admin-navbar-stroke{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    border-radius: 10px;
    background: #F5F5F5;
    padding: 0 12px;
    margin-top: 10px;
    justify-content: space-between;
}
.admin-navbar-stroke-container{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.admin-navbar-stroke-container p{
    font-size: 14px;
    white-space: nowrap;
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
}
.admin-navbar-stroke-image{
    width: 24px;
    height: 24px;
    object-fit: cover;
}

.admin-navbar-adSettings{
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-top: 10px;
}
.admin-navbar-techSupport{
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-top: 10px;
}

.admin-navbar-stroke-midlle{
    justify-content: center;
    font-size: 14px;
}


