#mop-PayM-close-wrapper{
    position:fixed;
    background-color:grey;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.33;
}
#mop-PayM-wrapper{
    display: flex;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 292px;
    height: 128px;
    z-index: 13;
    opacity: 1;
    padding: 0;
    background-color: white;
    border-radius: 12px 12px 12px 12px;
}
#mop-PayM-container{
    display: block;
    position: relative;
    padding: 0;
    width: 100%;
}
#mop-PayM-text-ty-for-order{
    margin-top: 13px;
    margin-bottom: 7px;
    align-items: center;
    font-weight: 600;
    font-size: 15.1034px;
    line-height: 18px;
    text-align: center;
}
#mop-PayM-text-notification{
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
    max-height: 50px;
    font-weight: 400;
    font-size: 12.5862px;
    line-height: 15px;
    text-align: center;
}
.mop-PayM-line {
    position: absolute;
    bottom: 33px;
    padding: 0;
    left:0;
    border-bottom: 1px solid #D9D9D9;
    width: 100%;
}
.mop-PayM-choice-element{
    padding-top: 7px;
    width: 292px;
    height: 25px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12.5862px;
    line-height: 15px;
    text-align: center;
    position: absolute;
    bottom: 0;
}