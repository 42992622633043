


#img_swiper{
    width: 193px;
    height: 35px;
    /*
    margin-top: 15px;
    margin-left: 10px;
    */
}
.swiper {
    /*87% 2.33333*/
    height: 100%;
    padding: 20px;
    margin: 0px;
    /*
    margin-top: 0px;
    margin-left: -40px;
    padding-top: 10px;
    padding-left: 90px;
    padding-bottom: 52px;

     */
}

.supplier-swiper-slide{
    text-align: left;
    font-size: 10px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

/*.swiper-slide img {*/
/*    display: block;*/
/*    width: 140px;*/
/*    height: 185px;*/
/*    object-fit: contain;*/
/*}*/

.swiper-pagination-bullet {
    width: 40px;
    height: 6px;
    border-radius: 23px;
    display: none;
}

.swiper-pagination-bullet-active {
    background: #D9D9D9;
    width: 40px;
    height: 6px;
    border-radius: 23px;
    display: none;
}


.supplier-swiper-tag-name-div{
    margin-top:32px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}
.supplier-swiper-tag-name{
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
}
.supplier-swiper-checkAll{
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    text-align: right;
    text-decoration-line: underline;
}