.ordersPage{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.orders-table-orderId{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.orders-table-actionImg{
    cursor: pointer;
}

.orders-table-bonuses{
    color: #01aba2;
}