.admin-customers {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
}

.admin-customers-main{
    flex: 1;
    margin-top: 50px;
    width: 72%;
    margin-right: 136px;
    margin-left: 136px;
}
.admin-customers-main > #navigate-title-div{
    width: 100%;
    box-sizing: border-box;
}
.admin-customers-main > .Searchbar{
    box-sizing: border-box;
    width: 100%;
    margin: 30px 0;;
}
.admin-customers-main > .table-container{
    width: 100%;
    max-height: 70vh;
    margin: 0;
    border-radius: 10px;
    overflow: auto;
}
.table-iconUser{
    cursor: pointer;
    margin-right: 10px;
}

/* стили таблицы */
/* хедер */
.admin-customers-main > .table-container{

}
.admin-customers-main > .table-container thead{
    height:20px;
    font-size:12px;
}
.admin-customers-main > .table-container thead > tr, .admin-customers-main > .table-container thead > tr th{
    height: inherit;
}
.admin-customers-main > .table-container thead > tr .custom-thf > div{
    height: inherit;
    display:flex;
    align-items: center;
    justify-content: center;
    font-weight:600;
}
.admin-customers-main > .table-container thead > tr > th:not(.custom-thf) > div{
    height: inherit;
    display:flex;
    align-items: center;
    justify-content: center;
}
.admin-customers-main > .table-container .table-head-line{
    width: 2px;
    height:32px;
    background-color:#A7A7A7;
    border-radius:1px;
}
/* тело */
/*.admin-customers-main > .table-container tbody .custom-rowAddition td div{*/
/*    display: none;*/
/*}*/
/*.admin-customers-main > .table-container tbody .custom-rowAddition:first-child{*/
/*    height: 13px;*/
/*}*/
/*.admin-customers-main > .table-container .table-body-line{*/
/*    width: 2px;*/
/*    height:25px;*/
/*    border-radius:1px;*/
/*    background-color: #A7A7A7;*/
/*}*/
/*.admin-customers-main > .table-container tbody .searchRow > .custom-tbf > div{*/
/*    border-color: transparent;*/
/*    padding: 8px 0;*/
/*    font-weight:500;*/
/*    font-size:10px;*/
/*}*/
/*.admin-customers-main > .table-container tbody > .searchRow > td:not(.custom-tbf) > div{*/
/*    height: inherit;*/
/*    display:flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/

#CustomersTable .custom-table-body .searchRow > td > div{
    height: 27px;

}
#CustomersTable .custom-table-body .searchRow > td > div .table-body-line{
    height: 27px;
}

#CustomersTable .custom-table-head tr th > div{
    height: 27px;
}
#CustomersTable .custom-table-head tr th > div .table-head-line{
    height: 27px!important;
}
#CustomersTable .custom-table-head > tr .custom-thf > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    white-space: pre-wrap;
    height: 27px;
}