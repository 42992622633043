#checkbox-label{
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    align-items: center;
    display: flex;
}
.real-checkbox{
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    z-index: -1;
}
.custom-checkbox{
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: sub;
    margin-right: 5px;
    border: 1px solid #333333;
    border-radius: 4px;
}
.custom-checkbox:before{
    content: '';
    width: 20px;
    height: 20px;
    background-image: url(/public/Pictures/checkbox.svg);
    background-size: auto;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50%, 50%) scale(0);
    transition: 0.2s ease-in;
    margin-top: -14px;
    margin-left: -2px;
    border-radius: 4px;
}
.real-checkbox:checked + .custom-checkbox:before{
    transform: translate(-50%, 50%) scale(1);
}
/*.real-checkbox:focus + .custom-checkbox{*/
/*    box-shadow: 0px 0px 0px 1px #38577a, 0px 0px 10px #7e9bbd;*/
/*}*/