.regionsSelect{
    box-sizing: border-box;
    position: relative;
}

.regionsSelect-placeHolder{
    cursor: pointer;
    border-radius: 12px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.25);
    padding: 15px;
    font-size: 16px;
}

.regionsSelect_box{
    margin-top: 20px;
    position: absolute;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,.25);
    max-height: fit-content;
    padding: 20px;
    width: 100%;
}

.regionsSelect-items{
    display: inline-flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 10px;
    max-height: 200px;
    overflow-y: auto;
    scroll-behavior: smooth;
    width: 100%;
}

.RegionDisabled{
    cursor: default;
    border: none;
    color: #33333380;

}