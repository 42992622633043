#form-an-order-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 11px;
    padding-bottom: 20px;
    position: fixed;
    bottom: 0px;
    z-index: 10;
    left: 0;
}
#form-an-order{
    display: flex;
    justify-content: center;
    width: 351px;
    height: 40px;
    border-color: #01ABA2;
    background-color: #01ABA2;

    border-radius: 50px;
    margin-top: 20px;
}
#form-an-order-passive{
     cursor: pointer;
     display: flex;
     justify-content: center;
     width: 351px;
     height: 40px;
     border-color: #B3B3B3;
     background-color: #B3B3B3;
     border-radius: 50px;
 }
