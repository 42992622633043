/* General button style */
.btn {
    font-size: inherit;
    color: inherit;
    background: none;
    cursor: pointer;
    display: inline-block;
    letter-spacing: 1px;
    outline: none;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.btn:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

/* Pseudo elements for icons */
.btn:before {
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    position: relative;
    -webkit-font-smoothing: antialiased;
}


/* Button 1 */
.btn-1 {
    background: #2f2f2f;
    color: #fff;
    border-radius: 50px;
    padding: 5px 10px;
    font-family: 'Montserrat';
    font-size: 14px;
    border: none;
}

.btn-1:hover {
    background: #4f4f50;
    border-color: #4f4f50;
}

.btn-1:active {
    background: #3c3c3f;
    border-color: #3c3c3f;
    top: 2px;
}

.btn-1:before {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    line-height: 3;
    font-size: 140%;
    width: 60px;
}

.btn-1:disabled{
    background: #646667;
    border-color: #646667;
}