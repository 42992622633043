.message {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
    display: flex;
}
.messages_notification{
    display: flex;
    justify-content: center;
    margin: 30px 0;
    color: gray;
    font-size: 12px;
    font-weight: 400;
    transition: all 0.3s linear;
}
.messages_notification:hover{
    text-decoration: underline;
    color: #333333;
}
.message_span {
    margin-top: 3px;
    background: #D9D9D9;
    padding: 7px 20px 20px 20px;
    word-break: break-word;
    max-width: 65%;
    min-width: 20px;
}

.message_link{
    all: unset;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
}

@media only screen and (min-width : 300px) and (max-width : 800px) {
    .message_span{
        max-width: 90%;
    }
}

.userMessage {
    justify-content: right;
}

.userMessage span {
    border-radius: 20px 20px 0 20px;
    white-space: pre-wrap;
}

.userMessage .name_message {
    align-items: end;
}

.supplierMessage span {
    border-radius: 20px 20px 20px 0;
}

.supplierMessage .name_message {
    align-items: start;
}

.name_message {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.userMessage_time{
    font-size: 10px;
    text-align: center;
    position: absolute;
    width: 25px;
    bottom: 2px;
    right: 7px;
}
.supplierMessage_time{
    font-size: 10px;
    text-align: center;
    position: absolute;
    width: 20px;
    bottom: 2px;
    left: 7px;
}