/*Swiper styles*/

#swiper-container{
    padding-bottom: 0px;
}

.swiper {
    height: 100%;
    padding-top: 15px;
    padding-bottom: 20px;
    margin: 0;
}

.supplier .swiper-slide {
    margin-left: -10px;

}

.swiper-pagination-bullet {
    width: 40px;
    height: 6px;
    border-radius: 23px;
    display: none;
}

.swiper-pagination-bullet-active {
    background: #D9D9D9;
    width: 40px;
    height: 6px;
    border-radius: 23px;
    display: none;
}

