.admin-categories-main{
    width: 100%;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1165px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.admin-categories-add-container{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.admin-categories-add-topCategory,
.admin-categories-add-subCategory{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    box-sizing: border-box;
}
.admin-categories-add-firstContainer{
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}
.admin-categories-add-secondContainer{
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px
}
.admin-categories-add-topCategory > .custom-input:first-child{
    width: 60%;
}
.admin-categories-add-button{
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    width: 80%;
    border: 1px solid #333333;
    border-radius: 12px;
    background: #333333;
    color: #FFFFFF;
    cursor: pointer;
    transition: 300ms;
}
.admin-categories-add-button:hover{
    background: #FFFFFF;
    color: #333333;
}

.admin-categories-add-firstContainer > .dropdown-internal-container {
    width: 40%;
}
.admin-categories-add-firstContainer > .custom-input{
    width: 60%;
}

.admin-categories-listCategories{
    display: flex;
    flex-direction: column;
}

.admin-categories-item{
    display: flex;
    flex-direction: column;
}
.admin-categories-item > hr{
    width: 100%;
    margin: 30px 0;
    padding: 0;
    height: 0;
    border: none;
    border-top: 1px solid #D9D9D9;
}

.admin-categories-item-topCategory{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px
}
.admin-categories-item-topCategory > .custom-input{
    width: 70%;
}
.admin-categories-item-secondContainer{
    width: 30%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
}

.admin-categories-item-buttons{
    width: 50%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}
.admin-categories-item-buttons button{
    width: 60px;
    height: 60px;
    border: 1px solid #333333;
    border-radius: 12px;
    background-color: #333333;
    transition: 300ms;
}
.admin-categories-item-buttonEdit{
    background-image: url("/public/Pictures/Edit-white1.svg");
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}
.admin-categories-item-buttonEdit:hover{
    background-image: url("/public/Pictures/Edit-black1.svg");
    background-color: #FFFFFF;
}
.admin-categories-item-buttonDelete{
    background-image: url("/public/Pictures/Trash-white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    position: relative;
}
.admin-categories-item-buttonDelete:hover{
    background-image: url("/public/Pictures/Trash-black.svg");
    background-color: #FFFFFF;
}
.admin-categories-item-buttonDelete[data-canDelete = "false"]{
    background-image: url("/public/Pictures/Trash-white.svg");
    background-color: #33333370;
}
.admin-categories-item-buttonDelete-canDelete{
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 110%;
    right: 0;
    max-width: 250px;
    background-color: #FFFFFF;
    padding: 5px 10px;
    color: #333333;
    border-radius: 6px;
    z-index: 1;
    text-align: center;
    visibility: hidden;
    border: 1px solid #db2020;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    white-space: nowrap;
}
.admin-categories-item-buttonDelete[data-canDelete = "false"]:hover .admin-categories-item-buttonDelete-canDelete{
    visibility: visible;
}

.admin-categories-item-subCategory-id{
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: 40px;
}

.admin-categories-item-subCategory{
    margin-left: 40px;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}
.admin-categories-item-firstContainer{
    width: calc(70% - 26px);
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
}
.admin-categories-item-subCategory > .admin-categories-item-secondContainer{
    width: calc(30% + 6px);
}
.admin-categories-item-firstContainer > .dropdown-internal-container {
    width: 40%;
}
.admin-categories-item-firstContainer > .custom-input{
    width: 60%;
}
.admin-multi-Item{
    display: flex;
    font-size: 16px;
    padding: 10px;
    background-color: #333333;
    color: white;
    width: fit-content;
    border-radius: 10px;
    margin-bottom: 5px;
}
.admin-categories-filter-button{
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
}
.filter-button-image{
    width: 15px;
    height: 15px;
    padding-top: 2px;
}