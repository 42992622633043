.Searchbar {
    width: 100%;
    border: 1px solid rgb(220 220 220);
    border-radius: 13px;
    padding: 3px;
    background-color: white;
}
.SearchbarInner{
    box-sizing: border-box;
    width: 100%;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background-color: white;
    border-radius: 15px;
    padding: 5px;
}
.SearchbarInput {
    height: 20px;
    margin-left: 10px;
    position: relative;
    border-style: none;
    width: calc(100% - 50px);
    font-size: 16px;
}

.SearchbarInput::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
}

.SearchbarInput::-moz-search-clear-button {
    display: none;
}

.SearchbarInput::-ms-clear {
    display: none;
}

.SearchbarInput:focus{
    outline: 0;
}

.SearchbarLogo1 {
    width: 15px;
}

.SearchbarLogo2{
    width: 20px;
 }