.register_form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.register_button {
    background-color: #ffffff;
    cursor: pointer;
}

/*.register_button:hover {*/
/*    background-color: #333333;*/
/*    color: white;*/
/*}*/

/*.register_input {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border: none;
    border-bottom: 1px solid #b8bcbf;
    outline: none;
}*/

.register_justify_conten_center {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}

.register_img_buyer {
    margin: 10px;
    width: 130px;
    height: 130px;
    background-image: url(/public/Pictures/byer_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border:none;
    outline:none;
    border-radius:14px;
}

.register_img_buyer:hover {
    margin: 10px;
    width: 130px;
    height: 130px;
    background-image: url(/public/Pictures/byer_icon_b.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: none;
    outline: none;
    border-radius: 14px;
}
.register_img_buyer_company {
    margin: 10px;
    width: 130px;
    height: 130px;
    background-image: url(/public/Pictures/byer_comp_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border:none;
    outline:none;
    border-radius:14px;
}

.register_img_buyer_company:hover {
    margin: 10px;
    width: 130px;
    height: 130px;
    background-image: url(/public/Pictures/byer_comp_icon_b.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: none;
    outline: none;
    border-radius: 14px;
}

.register_img_seller {
    margin: 10px;
    width: 130px;
    height: 130px;
    background-image: url(/public/Pictures/seller_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: none;
    outline: none;
    border-radius: 14px;
}

    .register_img_seller:hover {
        margin: 10px;
        width: 130px;
        height: 130px;
        background-image: url(/public/Pictures/seller_icon_b.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border: none;
        outline: none;
        border-radius: 14px;
    }

.register_header_bold {
    text-align: center;
    padding-bottom: 5px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #656666;
    margin-top: 30px;
}
.register_top_header_bold {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #333333;
}

.register_title {
    font-weight: 500;
}

.register_header {
    text-align: center;
    padding-bottom: 5px;
    font-weight:400;
}

.register_back_button {
    font-size: xx-large;
    padding-left: 25px;
    padding-top:25px;
}
