/*сам ордер*/
.array-of-orders{
    display: block;
    padding: 10px;
    padding-bottom: 180px;
}
/*ордер элемент*/
.order-element{
    display: block;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 5px 2px #E9E0DE;
    border-radius: 10px;
}
.order-header{
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
}
/*ордер дропдаун*/
table{
    width: 100%;
    justify-content: center;
}
td{
    justify-content: center;
    text-align: center;
}
.first-stylized-column{
    width: 2%;
}
.second-stylized-column{
    text-align: left;
}
.third-stylized-column{
    min-width: 30px;
}
.fourth-stylized-column{
    text-align: right;
    padding-left: 20px;
}
.order-dropdown-wrapper {
    margin-top: 10px;
    min-height: 30px;
    display: flex;
    gap: 5px;
    justify-content: space-between;
}
.order-positions{
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 30px;
}

.order-header-item{
    display: block;
    float: left;
}
.order-header-button-wrapper{
    display: flex;
    align-items: center;
}
.order-header-button{
    width: 10px;
    height: 10px;
    color: #C38989;
    padding-left: 3px;
    background-color: transparent;
    border-color: transparent;
}

.order-main-wrapper{
    font-size: smaller;
    font-weight: 400;
}
#order-address-deliver-text{
    font-weight: 500;
}
.order-header-item-positions{
    font-weight: 500;
}

@media screen and (max-width: 450px) {
    .order-dropdown-wrapper{
        flex-direction: column;

    }
}