.admin_rp_container{
    padding: 40.5px 9.65% 40px 9.65%;
    max-height: 1146.4px;
}
.admin_rp_container > #navigate-title-div{
    margin-bottom: 40px;
}
.admin_rp_main_container{
    display: flex;
    flex-direction: column;
}
.admin_rp_main_container > .Searchbar{
    width: 100%;
    box-sizing: border-box;
}

.admin_rp_main_container > .Searchbar > .SearchbarInner > .SearchbarInput{
    font-size: 16px;
}

.admin_rp_dropdowns{
    margin: 30px 0;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.admin_rp_dropdowns > .admin_rp_dropdown{
    width: 47.5%;
}
.admin_rp_dropdown > p{
    font-size: 18px;
    margin-bottom: 15px;
}
.admin_rp_dropdown .DropdownDisplay{
    min-height: 20px;
    border-radius: 20px;
    font-size: 14px;
    padding: 10px 5px 10px 13px;
}
.admin_rp_dropdown .DropdownDisplay > .dropDown-image{
    margin-right: 7%;
}

.admin_rp_swiper > p{
    font-size: 16px;
    font-weight: 400;
}
.admin_rp_swiper_empty{
    width: 100%;
    display: flex;
    justify-content: left;
}
.admin_rp_swiper_empty_block{
    display: flex;
    color: white;
    font-size: 18px;
    background-color: #333333;
    padding: 0 8%;
    height: 100px;
    border-radius: 20px;
    align-items: center;
}
.admin_rp_button_to_create{
    width: 225px;
    height: 48px;
    margin-top: 20px;
}
.admin_rp_button{
    width: 205px;
    height: 48px;
    font-size: 14px;
    color: white;
    background-color: #333333;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 10px;
    border-radius: 12px;
    transition: all 0.25s ease-in-out;
    margin-bottom: 9px;
}
.admin_rp_button > div > img{
    position: relative;
    top:2px;
    background-color: #333333;
    border-radius: 5px;
}
.admin_rp_button:hover{
    color: black;
    background-color: white;
    border: 1px solid #333333;
    transform: scale(1.02);
}