.myorderpage-upper-title{
    margin-bottom: 15px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
}
.myorderpage-status-list-wrapper{
    display: block;
}
.myorderpage-status-list{
    display: flex;
    justify-content: space-between;
}
.myorderpage-status-logo {
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    margin: auto;
    text-align: center;
    height: 38px;
    width: 38px;
    border-radius: 48px;
    background-color: #C38989;
    margin-bottom: 9px;
}
.myorderpage-status-logo-picture {
    margin: auto;
    width: 18px;
    height: 18px;
}
.myorderpage-green-status{
    background-color: #508E46;
}
.myorderpage-red-status{
    background-color: #8E4646;
}
.myorderpage-black-status{
    background-color: #333333;
}
.myorderpage-status-title {
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    margin-bottom: auto;
    text-align: center;
    max-width: 38px;
}
.myorderpage-status-time {
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
}
.myorderpage-main-div{
    position: relative;
    display: block;
    margin-top: 20px;
}
.myorderpage-string{
    margin-top: 10px;
    display: flex;
    justify-content: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}
.myorderpage-title-string{
    margin-right: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
}
.myorderpage-order-list{
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    margin: 10px 0 15px 0;
}
.myorderpage-table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
}

.myorderpage-header-table{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.myorderpage-cancel-order{
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    color: #8E4646;
    text-decoration: underline;
}

.myorderpage-columns{
    vertical-align: top;
    text-align: left;
}
.myorderpage-first-column{
    width: 50%;
}
.myorderpage-second-column{
    width: 10%;
    text-align: center;
}
.myorderpage-third-column{
    white-space: nowrap;
    text-align: right;
    width: 20%;
}

.myorderpage-bonuses{
    color: #01aba2;
}

.myorderpage-fourth-column{
    width: 20%;
    text-align: right;
}
.myorderpage-fourth-column-red{
    cursor: pointer;
    color: #8E4646;
}
.myorderpage-comment{
    margin: 5px 0 5px 0;
    padding: 11px 10px 10px 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
}
.myorderpage-without-discount{
    text-align: right;
    text-decoration: line-through;
    text-decoration-color: #DB2F2F;
    color: #7A7A7A;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
}
#myorderpage-sum-of-order{
    display: flex;
    justify-content: center;
    position: relative;
    margin: 5px 0 20px 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
}
#myorderpage-price-of-order{
    padding-left: 6px;
}
.myorderpage-discount{
    display: flex;
    justify-content: right;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    color: #C38989;
}
.myorderpage-discount-number{
    margin-left: 3px;
    padding: 0 2px 0 2px;
    background-color: #C38989;
    color: #FFFFFF;
}
.myorderpage_bottom_buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
}
.myorderpage-payment-button{
    font-weight: 700;
    max-width: 205px;
    margin: 0;
}
.myorderpage-confirm-receiving{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin: 0;
    color: #FFFFFF;
    border: 1px solid #333333;
    background-color: #333333;
    border-radius: 12px;
    padding: 10px 10px;
}

@media screen and (max-width: 450px) {
    .myorderpage-string{
        font-size: 13px;
        margin-top: 5px;
    }
    .myorderpage-title-string{
        font-size: 13px;
    }
    .myorderpage-title-address{
        flex-direction: column;
    }
    .myorderpage-confirm-receiving{
        order: 2;
    }
    .myorderpage-order-list{
        padding: 6px;
        margin: 5px 0 10px 0;
    }
    #myorderpage-sum-of-order{
        font-size: 14px;
        margin: 0 0 10px 0;
    }
    .myorderpage-comment{
        padding: 6px;
    }
    .myorderpage_bottom_buttons{
        flex-wrap: wrap;
        justify-content: center;
    }
}