.trainingCart-wrapper {
    width: 100%;
}

.trainingCart-container {
    display: grid;
    grid-template-rows: auto 1fr;
    border: 1px solid #000;
    grid-auto-flow: row;
    border-radius: 12px;
    padding: 15px;
    aspect-ratio: 20/5;
    margin: 10px 0;
}

.trainingCart-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    height: 40px;
}

.trainingCart-top-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    column-gap: 5px;
}

.trainingCart-top-block p {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
}

.trainingCart-top-block > label {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    line-height: 14px;
    font-weight: 500;
    margin-left: 5px;
    font-size: 16px;
}

.trainingCart-top-block > label > div{
    display: flex;
    align-items: center;
}

.trainingCart-dates{
    margin-left: 10px;
    font-size: 16px;
}

.trainingCart-top-block:first-child img {
    border: 1px solid #333333;
    border-radius: 50%;
    margin-right: 5px;
    padding: 2px;
    height: 16px;
    width: 16px;
}
.trainingCart-top-block-buttons{
    width: 10%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    gap: 10px;
}
.trainingCart-buttonEdit,
.trainingCart-buttonDelete,
.trainingCart-buttonBlock,
.trainingCart-buttonUnBlock,
.trainingCart-buttonAttenders{
    width: 100%;
    aspect-ratio: 1;
    border: 1px solid #333333;
    border-radius: 5px;
    background-size: 50%;
    background-color: #333333;
}
.trainingCart-buttonAttenders {
    background-image: url("/public/Pictures/UserIconForTable-white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}
.trainingCart-buttonAttenders:hover {
    background-image: url("/public/Pictures/UserIconForTable.svg");
    background-color: #FFFFFF;
}
.trainingCart-buttonEdit{
    background-image: url("/public/Pictures/Pencil-white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}

.trainingCart-buttonEdit:hover{
    background-image: url("/public/Pictures/Pencil.svg");
    background-color: #FFFFFF;
}
.trainingCart-buttonDelete{
    background-image: url("/public/Pictures/Trash-white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}
.trainingCart-buttonDelete:hover{
    background-image: url("/public/Pictures/Trash-black.svg");
    background-color: #FFFFFF;
}
.trainingCart-buttonBlock{
    width: 20%;
    background-image: url("/public/Pictures/Blocking-a-white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    background-size: 90%;
}
.trainingCart-buttonBlock:hover{
    background-image: url("/public/Pictures/Blocking-a-black.svg");
    background-color: #FFFFFF;
}
.trainingCart-buttonUnBlock{
    background-image: url("/public/Pictures/Blocking-b-white.svg");
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    background-size: 90%;
}
.trainingCart-buttonUnBlock:hover{
    background-image: url("/public/Pictures/Blocking-b-black.svg");
    background-color: #FFFFFF;
}

.trainingCart-bottom {
    display: inline-flex;
    width: 100%;
    /*height: 350px;*/
}

.trainingCart-image-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width:33%;
    min-width: 33%;
    max-width: 300px;
    max-height: 300px;
    overflow: hidden;
}
.trainingCart-bottom img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    border-radius: 12px;
    object-fit: contain;
}

.trainingCart-bottom-block {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr auto;
    margin-left: 10px;
    flex: 1;
}

.trainingCart-bottom-block p {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 10px;
}

.trainingCart-bottom-block label {
    height: 250px;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 18px;
    font-weight: 400;
    font-size: 18px;
    cursor: text;
    position: relative;
}

.trainingCart-bottom-block label::after{
    content: "";
    text-align: right;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1.2em;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%);
    pointer-events: none;
}

.trainingCart-bottom-block a,
.trainingCart-bottom-block div{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #333333;

    border-radius: 8px;
    line-height: 16px;
    font-weight: 600;
    margin-top: 20px;
    font-size: 16px;
    cursor: pointer;
    color: #fff;
    max-height: 50px;
    aspect-ratio: 8;
    padding: 5px 40px;
}
@media (max-width: 1024px){
    .trainingCart-container{
        /*aspect-ratio: 0;
        max-height: 290px;*/
    }
}
@media (max-width: 768px) {
    .trainingCart-bottom{
        grid-template-columns: min-content;
    }
    .trainingCart-container{
        /*height: 240px;*/
    }
    .trainingCart-bottom-block label{
        -webkit-line-clamp: 5;
    }
    .trainingCart-bottom-block a,
    .trainingCart-bottom-block div{
        /*width: 45%;*/
    }
    .trainingCart-bottom-block label{
        -webkit-line-clamp: 3;
        font-size: 20px;
        line-height: 22px;
    }
    .trainingCart-bottom-block p{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        font-size: 26px;
        line-height: 26px;
    }
}

@media (max-width: 500px) {
    .trainingCart-top-block > label{
        flex-direction: column;
        align-items: start;
    }
    .trainingCart-container{
        padding: 10px;
    }
    .trainingCart-top{
        margin-bottom: 5px;
    }
    .trainingCart-top-block{

    }
    .trainingCart-container{
        /*height: 170px;*/
    }
    .trainingCart-bottom-block label{
        -webkit-line-clamp: 3;
        font-size: 22px;
        line-height: 22px;
        flex: 1;
        max-height: 100px;
    }
    .trainingCart-bottom-block p{

        -webkit-line-clamp: 1;
        font-size: 20px;
        line-height: 20px;
        margin: 0;
    }
    .trainingCart-bottom-block a, .trainingCart-bottom-block div{
        /*margin-top: 5px;*/
    }
    .trainingCart-bottom-block a,
    .trainingCart-bottom-block div{

        box-sizing: border-box;
    }

    .trainingCart-top-block label{
        font-size: 14px;
    }
    .trainingCart-top b{
        font-size: 12px;
    }
    .trainingCart-top-block:first-child img{
        width: 14px;
        height: 14px;
        padding: 3px;
    }
    .trainingCart-image-container{
        width: 100%;
        height: auto;
        margin-bottom: 15px;
    }
    .trainingCart-bottom-block div{
        margin-top: 10px;
    }
}
