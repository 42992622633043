.seller-header{
    top: 0;
    position: sticky;
    z-index: 30;
    /*padding-bottom: 7px;*/
    background-color: #fff;
}
.seller-content{
    padding: 6px 138px 15px 138px
    /*margin-top: -95px;*/
}
.seller-layout{
    min-width: 800px;
    zoom: 100%;
}

/*костыли*/

@media only screen and (max-device-width : 1500px) {
    .seller-layout{
        zoom: 85%;
    }
}

@media only screen and (max-device-width : 1200px) {
    .seller-layout{
        zoom: 75%;
    }
}

@media only screen and (max-device-width : 450px) {
    .seller-layout{
        zoom: 30%;
    }
    .seller-content{
        padding: 6px 20px 15px 20px;
    }
}