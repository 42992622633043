#cart-container{
    min-height: 694px;
    background-color: white;
}

.discount{
    background: #C38989;
    border-radius: 48px;
    width: 40px;
    height: 15px;
    font-style: italic;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
    text-shadow: 1px 0px 2px rgb(0 0 0 / 10%);
    display: flex;
    justify-content: center;
    align-items: center;
}
.dostavka{
    margin-left: 19px;
}
.clock-img{
    margin-right: 6px;
}
.discount-container{
    display: flex;
}
.suppliers-cards-checkall{
    margin-bottom: 15px;
    display: flex;
    justify-content: end;
}
#sum_cart_containter{
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 98px;
}
#sum1-of-cart{
    text-align: right;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-decoration: line-through;
    text-decoration-color: #DB2F2F;
}
#sum2-of-cart{
    display: flex;
    justify-content: space-between;
    padding-top: 3px;
}
.sum-price-bonuses{
    display: flex;
    flex-direction: column;
    align-items: flex-end
}
.sum-bonuses{
    white-space: nowrap;
    margin-left: 10px;
    color: #01aba2;
}
#img_dashed-cart{
    width: 100%;
    height: 1px;
    margin-top: 12px;
}
#sum3-of-cart{
    display: flex;
    justify-content: right;
    padding-top: 2px;
    color: #C38989;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;;
}
#number_of_saving-background{
    background-color: #C38989;
}
#number_of_saving{
    color: white;
    margin-left: 2px;
    margin-right: 2px;
}
#form-an-order span{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    color: #FFFFFF;
}
#form-an-order-passive span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    color: #FFFFFF;
}


.check-all-button{
    border: 1px solid #333333;
    border-radius: 29px;
    background-color: #FFFFFF;
    width: 128px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check-all-button-black{
    background-color: #333333;
    color: #FFFFFF;
}

/*.check-all-button:hover{*/
/*    background-color: #333333;*/
/*    color: #FFFFFF;;*/
/*}*/