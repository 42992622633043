.burgerIcon{
    overflow: hidden;
    width: 23px;
    height: 23px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.burgerBackendTransparent{
    background-color:grey;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    animation-name: toTransparent;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
}
.burgerBackendGrey{
    position:fixed;
    background-color:grey;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    animation-name: toGrey;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
}
@keyframes toGrey {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.33;
    }
}
@keyframes toTransparent {
    0% {
        opacity: 0.33;
        z-index: 3;
    }
    99% {
        opacity: 0;
        z-index: 3;
    }
    100% {
        z-index: -1;
        background-color:white;
    }
}
.burgerWrapperClose {
    display: block;
    width: 292px;
    background-color: white;
    position: absolute;
    top: 0px;
    left: -292px;
    z-index: 3;
    list-style-type: none;
    transition: transform 0.5s;
    height: 100vh;
}
.burgerWrapperOpen {
    all: unset;
    display: block;
    width: 292px;
    background-color: white;
    position: fixed;
    top: 0px;
    left: -292px;
    z-index: 30;
    list-style-type: none;
    transform: translate(100%, 0);
    transition: transform 0.5s;
}
.burgerWrapperOpen li{
    font-weight: 400;
    font-size: 17px;
    line-height: 40px;
}
.burgerWrapperClose li{
    font-weight: 400;
    font-size: 17px;
    line-height: 40px;
}
.burgerPadding > div {
    overflow-y: scroll;
    height: calc(100dvh - 52px);
}
.burgerPadding{
    padding: 52px 20px 40px 20px;
}
#user-burger-scroll::-webkit-scrollbar{
    width: 0px;
    background-color: white;
}
#user-burger-scroll > li:last-child{
    margin-bottom: 120px;
}
#burger-close {
    position: absolute;
    left: 24px;
    top: 16px;
    cursor: pointer;
    height: 12.5px;
}
#burger-close-img{
    width: 12.5px;
    height: 12.5px;
}
.burgerProfile{
    display: flex;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 21px;
}
.burgerFullName{
    display: block;
    margin-left: 15px;
}
.burgerNameAndOtchestvo{
    font-size: 10px;
    display: flex;
}
#BurgerSpanOtchestvo{
    margin-left: 5px;
}
.burgerMyReviewsBg{
    background-image: url(/public/Pictures/Rectangle3.png);
    width: 292px;
    height: 90px;
    background-size: 100%;
    border-radius: 0 0 30px 0;
}
.burgerMyReviewsMarkup{
    padding: 27px 0 28px 20px;
    display: flex;
}
.burgerMyReviewsMarkup span{
    font-weight: 700;
    font-size: 20px;
    line-height: 35px;
    color: #FFFFFF;
    margin-right: 6px;
}
.burgerLinkMyReviews{
    display: flex;
}
#burgerProfilePictures{
    width: 40px;
    height: 40px;
    border-radius: 30px;
}