.supplier-card-div {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
    border-radius: 9px;
    margin-bottom: 10px;
}
.suppliers-cards-div {
    padding: 20px 0 0px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.supplier-title-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%), 0px 2px 0px rgb(0 0 0 / 5%);
    border-radius: 9px;
}
.supplier-title-div-await {
    background: #333333;
    color: #FFFFFF;
}

.logo-text-amount{
    display: flex;
}
#logo-text-amount-p{
    min-width: 90%;
}
.logo {
    margin-right: 5px;
    height: 20px;
    width: 20px;
}
.price-arrow{
    display: flex;
}
.arrow{
    margin-left: 10px;
}
#products-container{
    border-radius: 9px;
    margin-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    transition: transform 1.3s linear;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.21);
}

.all-price-old{
    font-size: 10px;
    text-decoration: line-through;
    text-decoration-color: #DB2F2F;
    text-align: right;
}

.all-price-bonuses-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.all-price-bonuses{
    font-size: 15px;
    text-align: right;
    display: flex;
    flex-direction: column;

}
.all-price-text{
    font-size: 15px;
    text-align: left;
}
.all-price, .all-price-text{
    display: inline-block;
    width: 50%;
}


