.admin-header{
    position: sticky;
    top: 0;
    z-index: 30;
}
.admin-layout{
    min-width: 800px;
    zoom: 35%;
}
@media only screen and (min-device-width : 1200px) {
    .admin-layout{
        zoom: 100%;
    }
}