.bonuses-mainContainer{
    max-width: 1165px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto;
    box-sizing: border-box;
}
.bonusesPageInput > .custom-input{
    width:680px;
}
.bonuses-navigate-container{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    position: relative;
}
.bonuses-navigate-image{
    position: absolute;
    left: 0;
    width: 12px;
    height: 27px;
    cursor: pointer;
}
.bonuses-navigate-title{
    font-weight: 600;
    font-size: 32px;
    line-height: 100%;
    margin-bottom: 40px;
}

.bonuses-firstContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.bonuses-quantity-rules-container {
    width: 40%;
    min-width: 458px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}
.bonuses-quantityCard{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 300px;
    background: url("/public/Pictures/bonusCardImage.svg") no-repeat center center;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}
.bonuses-quantityCard-quantity{
    margin-top: 40px;
    margin-right: 40px;
    text-align: right;
    font-weight: 600;
    font-size: 36px;
    line-height: 120%;
    color: #FFFFFF;
}

.bonuses-quantityCard-freeBonuses{
    margin-top: 10px;
    margin-right: 40px;
    text-align: right;
    font-weight: 600;
    font-size: 36px;
    line-height: 120%;
    color: #FFFFFF;
}

.bonuses-quantityCard-text{
    margin-top: 0;
    margin-right: 40px;
    text-align: right;
    font-weight: 400;
    font-size: 36px;
    line-height: 120%;
    color: #FFFFFF;
}
.bonuses-quantityCard-button{
    width: 156px;
    height: 40px;
    margin-top: 20px;
    margin-right: 40px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    background: #FFFFFF;
    border: 0;
    border-radius: 12px;
    cursor: pointer;
}
.bonuses-rulesDeposit{
    margin-top: 20px;
}
.bonuses-rulesDeposit-title{
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    margin-top: 20px;
}
.bonuses-rulesDeposit > div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    line-height: 100%;
    margin-bottom: 16px;
}
.bonuses-rulesDeposit > div > div:first-child{
    font-weight: 400;
}
.bonuses-rulesDeposit > div > div:last-child{
    font-weight: 500;
}

.bonuses-historyOperations-container{
    width: 60%;

}
.bonuses-historyOperations-header{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
}
.bonuses-historyOperations-buttons{
    display: flex;
    flex-direction: row;
    gap:10px
}
.bonuses-historyOperations-button{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    background: #FFFFFF;
    border: 1px solid #333333;
    border-radius: 100px;
    cursor: pointer;
    transition: 500ms;
}
.bonuses-historyOperations-button[data-isActive = "true"]{
    transition: 500ms;
    background: #333333;
    border-color: #FFFFFF;
    color: #FFFFFF;
}
.bonuses-historyOperations-button[data-isActive = "true"] img{
    margin-left: 10px;
    animation: aniButtonIcon 0.5s forwards;
}
@keyframes aniButtonIcon {
    0%{max-width: 0}
    100%{max-width: 20px}
}

.bonuses-historyOperations-operations{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.bonuses-historyOperations-operation{
    animation: aniOperations 2s forwards;
    overflow: hidden;
    width: 100%;
    height: 60px;
    background: #F3F3F3;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;

}
@keyframes aniOperations{
    0%{opacity: 0;max-height: 0;}
    100% {opacity: 1; max-height: 200px;}
}
.bonuses-historyOperations-operation-date{
    margin-left: 20px;
}
.bonuses-historyOperations-operation-name{
    text-align: end;
}
.bonuses-historyOperations-operation-name-bonus{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-right: 20px;
}
.bonuses-historyOperations-operation-bonus{
    font-weight: 500;
    text-align: end;
}
.bonuses-historyOperations-operation-noOperation{
    width: 100%;
    text-align: center;
}
.bonuses-historyOperations-watchAll{
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    width: 100%;
    margin-top: 20px;
    text-align: center;
}
.bonuses-historyOperations-watchAll a{
    text-decoration-line: underline;
    cursor: pointer;
}
.bonuses-historyOperations-watchAll a:hover{
    text-decoration-line: none;
}

.bonuses-secondContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
}

.bonuses-manual-requisites{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}
.bonuses-manual{
    width: 40%;
    height: min-content;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    font-size: 22px;
}
.bonuses-manual-header{
    margin: 20px;
}
.bonuses-manual-text{
    margin: 20px;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
}
.bonuses-requisites-container{
    width: 60%;
    display: flex;
    flex-direction: column;
}
.bonuses-requisites{

    height: min-content;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    font-size: 22px;
}
.bonuses-requisites-header{
    margin: 20px;
}
.bonuses-requisites-text{
    display: flex;
    flex-direction: column;
    margin: 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}
.bonuses-requisites-text b{
    font-weight: 500;
}
.bonuses-requisites-text-heading{
    font-weight: 500;
    margin-top: 20px;
    gap: 10px
}
.bonuses-requisites-text-headingInline{
    display: flex;
    flex-direction: row;
    font-weight: 500;
    margin-top: 20px;
    gap: 10px
}
.bonuses-requisites-text-headingInline div{
    font-weight: 400;
}
.bonuses-requisites-text-heading:first-child{
    margin-top: 0;
}
.bonuses-requisites-button{
    margin-top: 20px;
    width: 100%;
    height: 60px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;

    box-shadow: 0px 13px 5px rgba(0, 0, 0, 0.01), 0px 7px 4px rgba(0, 0, 0, 0.02), 0px 3px 3px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    border: 1px solid #333333;
    background: #333333;
    color: #FFFFFF;
    cursor: pointer;
}
.bonuses-requisites-button:hover{
    transition: 300ms;
    background: #FFFFFF;
    color: #333333;
}
.bonus_calc_header{
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
}
.bonus_calc_text{
    font-size: 18px;
    margin-bottom: 10px;
}
.bonus_calc_input_box{
    display: flex;
    width:100%
}
.bonus_calc_input{
    width: 70%;
}
.bonus_calc_input > .custom-input{
    width: 100%;
}
.bonus_calc_button{
    width:30%;
}
.bonuses_calculator_button{
    margin-left: 20px;
    width: calc(100% - 20px);
    height: 58px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;

    box-shadow: 0px 13px 5px rgba(0, 0, 0, 0.01), 0px 7px 4px rgba(0, 0, 0, 0.02), 0px 3px 3px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    border: 1px solid #333333;
    background: #333333;
    color: #FFFFFF;
    cursor: pointer;
}
.bonus_calc_answer1{
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}
.bonus_calc_answer2{
    width: 100%;
    text-align: center;
    display: flex;
    font-size: 24px;
    font-weight: 400;
}
.bonus_calc_answer2_1{
    margin-left: auto
}
.bonus_calc_answer2_2{
    margin-left: 10px;
    font-weight: 600;
    margin-right: auto;
}

.bonusesDebtsTable{
    width: 100%;
}

.bonusesDebtsTable-header{
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 30px;
}

@media screen and (max-width: 450px){
    .bonuses-mainContainer{
        margin-top: 10px;
    }
    .bonuses-navigate-title{
        margin-bottom: 10px;
    }
    .bonuses-historyOperations-operation{
        font-size: 16px;
        height: 48px;
    }

    .bonuses-historyOperations-operation-date{
        margin-left: 10px;
        width: 75px;
    }
    .bonuses-historyOperations-operation-name-bonus{
        margin-right: 10px;
        gap: 5px;
    }
}