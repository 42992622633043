.customer-table-icons{
    margin:-1px 8px 0 8px ;
    padding:0px;
    width:14px;
    height:14px;
    box-sizing: unset;
}
.custom-thf{
    padding:0px;
    font-weight: 500;
}
.custom-tbf{
    font-weight: 400;
    position: relative;
}
.table-head-line {
    width: 1px;
    height: 16px;
    background-color: black;
    padding: 0px !important;
}
.table-body-line{
    width:1px;
    height:16px;
    background-color: rgb(220 220 220);
    padding: 0px;
}
.custom-table{
    white-space: nowrap;
    border-collapse: collapse;
    width: 100%;
}
.table-container{
    overflow:auto;
    margin:10px;
    width: calc(100% - 20px);
    padding-bottom:5px;
}
.custom-table-head {
    font-size: 16px;
}
.custom-table-body{

}
.custom-rowAddition {
    height: 1px;
}
.custom-table-head tr th {
    padding: 0;
}

.custom-table-head tr th > div {
    background-color: rgb(243 243 243);

    width: auto;
    height:auto;
    padding:10px;
}

.custom-table-head tr th:first-child > div {
    border-radius: 10px 0 0 10px;
}

.custom-table-head tr th:last-child > div {
    border-radius: 0 10px 10px 0;
}

.custom-table-body tr td {
    padding: 0;
}

.custom-table-body tr td > div {
    width: auto;
    height: 16px;
    padding: 10px;
    box-sizing: unset;
}
.custom-table-body tr td:first-child > div:first-child {
    border-color: rgb(220 220 220);
    border-style: solid;
    border-width: 0 0 0 1px;
}
.custom-table-body tr td:last-child > div{
    border-color: rgb(220 220 220);
    border-style:solid;
    border-width: 0 1px 0 0;
}

.custom-table-body tr:first-child td > div:first-child {
    margin-top: 10px;
    border-color: rgb(220 220 220);
    border-style: solid;
    border-width: 1px 0 0 0;
}
.custom-table-body tr:first-child td:first-child > div:first-child {
    border-radius: 10px 0 0 0;
    border-color: rgb(220 220 220);
    border-style: solid;
    border-width: 1px 0 0 1px;
}
.custom-table-body tr:first-child td:last-child > div:first-child {
    border-radius: 0 10px 0 0;
    border-color: rgb(220 220 220);
    border-style: solid;
    border-width: 1px 1px 0 0;
}
.custom-table-body tr:last-child td > div:first-child {
    border-color: rgb(220 220 220);
    border-style: solid;
    border-width: 0 0 1px 0;
}
.custom-table-body tr:last-child td:first-child > div:first-child {
    border-radius: 0 0 0 10px;
    border-color: rgb(220 220 220);
    border-style: solid;
    border-width: 0 0 1px 1px;
}
.custom-table-body tr:last-child td:last-child > div:first-child {
    border-radius: 0 0 10px 0;
    border-color: rgb(220 220 220);
    border-style: solid;
    border-width: 0 1px 1px 0;
}
.custom-rowAddition td div{
    height: 9px !important;
    max-height: 9px !important;
    padding: 0px !important;
}
.display_none{
    display: none;
}