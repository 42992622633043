.supplier-profile-container {

    margin: 0 auto;
    padding: 96px 0;
    max-width: 930px;
    color: #333333;
    box-sizing: border-box;
}

.supplier-profile-Info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.supplier-navigate{
    position: absolute;
    left: -50px;
    top: 10px;
}
.supplier-navigate-image{
    width: 15px;
    height: 30px;
}
.supplier-profile-names{
    position: relative;
    width: 49%;
}
.supplier-profile-name{
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
}
.supplier-profile-id{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 20px;

    color: #9B9B9B;
}
.supplier-profile-role{
    margin-top: 14px;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
}

.supplier-profile-images{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 49%;
}
.supplier-profile-image-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 49%;
    border: 1px solid #B6BABD;
    border-radius: 12px;
}
.supplier-images-text{
    margin-top: 14px;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
}
.supplier-image-button{
    width: 100%;
    height: 60px;

    border: 0;
    border-radius: 0 0 11px 11px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    background: #333333;
    color: #F1F1F1;
}

.supplier-image{
    width: 140px;
    height: 140px;
    filter: drop-shadow(0px 4px 20px rgba(51, 51, 51, 0.08));
    border-radius: 50%;
    object-fit: cover;
}

.supplier-select-component-storages{
    width: 100%;
    margin-top: 30px;
}

.supplier-select-component-input{
    height: 60px
}
.supplier-dropDown-storage{
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0;
    max-height: 56px;
    height: 56px;
    background-image: url('/public/Pictures/storageIcon.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 10px;
    box-sizing: border-box;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #bdbdbd;
    appearance: auto;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.supplier-hr{
    margin: 30px 0;
    background-color: #9B9B9B;
}

.supplier-personal-data{
    display: flex;
    align-items: stretch;
    flex-direction: column;
}
.supplier-header{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
}
.supplier-inputs{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.supplier-input-text{
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap
}
.supplier-full-input{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.supplier-half-inputs{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.supplier-half-input{
    display: flex;
    flex-direction: column;
    width: 49%;
}

.supplier-one-half-input-left{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.supplier-one-half-input-right{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
}
.supplier-half-input{
    width: 49%;
    display: flex;
    flex-direction: column;
}


.supplier-datePicker{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}
.supplier-datePicker input{
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    height:60px;
    background-color: #fff;
    border: 1px solid #B6BABD;
    border-radius: 10px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    padding: 0 21px;
    width: 100%;
    font-family: "Montserrat", sans-serif;
}
.supplier-datePicker input::-webkit-calendar-picker-indicator{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
    cursor: pointer;
}
.supplier-datePicker input::-webkit-inner-spin-button,
.supplier-datePicker input::-webkit-clear-button {
    z-index: 1;
}

.supplier-datePicker-image{
    position: absolute;
    right: 0;
    margin-right: 21px;
    color: #333333;
    pointer-events: none;
}
.supplier-saveChanges-container{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.supplier-saveChanges-status{
    text-align: center;
    position: absolute;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}
.supplier-saveChanges-button{
    margin-top: 30px;
    height: 60px;
    box-sizing: border-box;
    border: 1px solid #333333;
    border-radius: 12px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    background-color: #FFFFFF;
    color: #333333;
}

.supplier-saveChanges-button:hover{
    background-color: #333333;
    color: #FFFFFF;
    transition: 300ms;
}
.supplier-saveChanges-button:active{
    background-color: #FFFFFF;
    color: #333333;
}

.supplier-organization-data{
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.supplier-address-inputs{
    width: 49%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
}
.supplier-address-input-street{
    width: 73%;
}
.supplier-address-input-numHome{
    width: 25%;
}

.supplier-password{
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.supplier-select-component-storages-add-store{
    background-color: black;
    color: white;
    width: fit-content;
    padding: 10px 50px 10px 50px;
    border-radius: 20px;
    margin: 10px auto;
    font-weight: 700;
    font-size: 16px;
}

@media (max-width: 1024px) {
    .supplier-profile-container {
        width: 80%;
    }
}

@media (max-width: 768px) {
    .supplier-profile-container {
        width: 80%;
        margin: 30px auto;
        max-width: 930px;
        color: #333333;
    }

    .supplier-profile-Info{
        flex-direction: column;
    }
    .supplier-navigate{
        left: -30px;
    }
    .supplier-profile-fullName{
        display: flex;
        flex-direction: row;
    }
    .supplier-profile-names{
        width: 100%;
    }
    .supplier-profile-name{
        max-width: 500px;
    }

    .supplier-select-component-storages{
        width: 100%;
        margin: 20px 0;
    }

    .supplier-profile-images{
        width: 100%;
    }
    .supplier-profile-image-container{
        gap: 10px;
    }
    .supplier-image{
        width: 120px;
        height: 120px;
    }

    .supplier-half-inputs{
        flex-direction: column;
        width: 100%;
        gap: 20px;
    }
    .supplier-half-input{
        width: 100%;
    }
    .supplier-address-inputs{
        width: 100%;
    }
}
