.warehouse-filter-box {
    width: fit-content;
    font-family: Arial, sans-serif;
    padding: 16px;
}

.warehouse-filter-box-title{
    font-size: 24px;
    font-weight: 400;
    margin: 0 0 10px 0;
}

.selection-display {
    display: flex;
    align-items: center;
}

.selected-city {
    font-weight: bold;
    margin-right: 10px;
    position: relative; /* Чтобы крестик был в правильном месте */
}

.reset-icon {
    margin-left: 5px;
    cursor: pointer;
    color: red;
    font-weight: bold;
}

.city-filter,
.warehouse-filter {
    row-gap: 10px;
    display: flex;
    flex-wrap: wrap;
}

.city-item,
.warehouse-item {
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-style: solid;
    border-width: 1px;
    font-size: 16px;
    font-weight: 400;
    width: fit-content;
}

.city-item:hover,
.warehouse-item:hover {
    background-color: lightgray;
}

.city-item.active,
.warehouse-item.active {
    color: white;
    background-color: #333333;
}
/* WarehouseFilterBox.css */

.warehouse-filter-box {
    width: fit-content;
    font-family: Arial, sans-serif;
    padding: 16px;
}

.selection-display {
    display: flex;
    align-items: center;
}

.selected-city {
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-style: solid;
    border-width: 1px;
    font-size: 16px;
    font-weight: 400;
    width: fit-content;
    background-color: #333333;
    color: white;
    position: relative; /* Чтобы крестик был в правильном месте */
}

.reset-icon {
    position: absolute;
    right: -10px;
    top: -5px;
    margin-left: 5px;
    cursor: pointer;
    color: #333333;
    border-radius: 100%;
    border: 1px solid #333333;
    background-color: #FFFFFF;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}

.city-filter,
.warehouse-filter {
    display: flex;
    flex-wrap: wrap;
}

.city-item,
.warehouse-item {
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.city-item:hover,
.warehouse-item:hover {
    background-color: lightgray;
}

.city-item.active,
.warehouse-item.active {
    color: white;
    background-color: #333333;
}
