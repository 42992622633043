.unprocessedProducts-wrapper {
    padding: 0 138px 0;
}

.unprocessedProducts-container {
}

.unprocessedProducts-container > #navigate-title-div {
    margin: 20px 0 0 0;
}

.unprocessedProducts-container > #navigate-title-div img {
    cursor: pointer;
}

.unprocessedProducts-container > .Searchbar {
    width: calc(100% - 20px);
    margin: 20px 0;
}

.unprocessedProducts-container > .table-container {
    width: 100%;
    margin: 0;
}

@media only screen and (min-width: 800px) {
    .unprocessedProducts-container > #navigate-title-div .cart-title {
        font-size: 26px;
    }
}