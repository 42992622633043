/*Swiper item styles*/

.product_card_container {
    width: 177px;
    height: 190px;

    background: #FFFFFF;
    /*background: #FFFFFF;*/
    box-shadow: 0px 2.79739px 13.9869px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    display: flex;
    align-items: center;
}
.product_card{
    margin: 0 auto;

    width: 126px;
    height: 150px;
    align-items: center;
}

#img_of_card{
    object-fit: contain;
    width: 98px;
    height: 90px;
    margin: 0 auto;
}
#text_cont{
    margin-top: 20px;
    width: 125px;
    height: 42px;

    overflow: hidden;
}

#text_of_card {
    text-align: left;
    width: 125px;

    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11.2395px;
    line-height: 14px;

    color: #2F2121;
}

.img_cont{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product_card_isUse{
    position: absolute;
    top: -15px;
    right: -25px;
    background: #FFFFFF90;
    border-radius: 12px 12px 0 12px;
    box-shadow: rgb(1, 171, 162) 0px 0px 5px;
    padding: 2px 3px;
    font-size: 12px;
}

.productCardSupplier-popupMagazines-container{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 50;
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #33333350;
}

.productCardSupplier-popupMagazines{
    width: 80%;
    max-width: 700px;
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    gap: 10px;
}

.productCardSupplier-popupMagazines > #navigate-title-div{
    margin-bottom: 10px;
}

.productCardSupplier-popupMagazine-items{
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px 0;
}

.productCardSupplier-popupMagazine-item{
    font-size: 18px;
    padding: 20px 30px;
    border-radius: 12px;
    background-color: rgb(245, 245, 245);
}

.productCardSupplier-popupMagazines-button{
    cursor: pointer;
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 12px;
    border: 1px solid #333333;
    width: fit-content;
    margin: 10px auto 0 auto;
}

.productCardSupplier-popupMagazines-button:hover{
    background-color: #333333;
    color: #fff;
}