.header-div {
    display: grid;
    grid-template-columns: 100px max-content 100px;
    align-items: start;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 18px;
    background: white;
}
/*hamberher menu*/
#Hamburger-and-share {
    display: flex;
    align-items: center;
    justify-content: start;
}

#menuToggle {
    display: block;
    position: relative;
    z-index: 3;
    -webkit-user-select: none;
    user-select: none;
    border-image: url("/public/Pictures/Бургер.png") 0 fill / 0px / 0px space;
    height: 14px; width: 16px;
}

#menuToggle a {
    text-decoration: none;
    color: #232323;
    transition: color 0.3s ease;
}

#menuToggle a:hover {
    color: tomato;
}

#menuToggle input {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -7px;
    left: -5px;
    cursor: pointer;
    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */
    -webkit-touch-callout: none;
}

#menuToggle span {
    display: block;
    width: 13px;
    height: 2px;
    margin-bottom: 2px;
    position: relative;
    background: #000000;
    border-radius: 20px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
}

#menuToggle span:first-child {
    transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
}

#menuToggle input:checked ~ ul {
    transform: none;
}
#menu2 {
    position: absolute;
    top: -24px;
    left: -20px;
    padding: 46px 20px 20px 50px;
    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-500%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}
#menu2 li {
    padding: 10px 0;
    font-size: 22px;
}
/*buttons*/
#header-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 13px;
}

#header-buttons > a{
    overflow: visible;
}

.header-link{
    height: 20px;
    margin-right: 16px;
}
.header-link:hover{
    box-shadow: 0 0 5px rgba(0,0,0,0.3); /* Тень */
}
.header-notification:hover{
    box-shadow: 0 0 5px rgba(0,0,0,0.3); /* Тень */
}
#img-share{
    overflow: hidden;
    width: 23px;
    height: 23px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 15px;
}
#logo-and-homeButton{
    display: flex;
    margin-left: -25px;
}
#img-logo-header {
    overflow: hidden;
    width: 140.92px;
    height: 34.51px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 9px;
}
#img-home-header{
    overflow: hidden;
    align-items: center;
    display: flex;
    justify-content: center;
}
.headerOrders{
    width: 23px;
    height: 23px;
    align-items: flex-start;
    display: flex;
    justify-content: center;
}
.headerCart{
    overflow: hidden;
    width: 23px;
    height: 23px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 3px;
}
.headerNotification{
    overflow: hidden;
    width: 23px;
    height: 23px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.cash-p{
    margin-left: 6px;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
}
.header-p {
    position: absolute;
    top: 15px;
    right: 54px;
    background-color: #FB7070;
    border-radius: 20px;
    font-size: 8px;
    justify-content: center;
    display: flex;
    color: white;
    align-items: center;
    width: 13px;
    height: 13px;
}