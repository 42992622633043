.input_street {
    width: calc(75% - 10px);
}
.input_number_box {
    width: calc(25% - 10px);
}
.register_select_component .DropdownDisplay {
    padding-left:23px;
}
.register_select_component .DropdownDisplay {
    padding-left:23px;
}
.register_select_component .dropdown-item-name{
    padding-left:21px;
}