
/*Swiper styles*/
#swiper-container{
    padding-bottom: 0px !important;
}

#swiper-container > .swiper{
    max-width: calc(100% + 20px);
    box-sizing: border-box;
    margin-left: -20px!important;
    margin-right: -20px!important;
}

#img_swiper{
    width: 193px;
    height: 35px;
    /*
    margin-top: 15px;
    margin-left: 10px;
    */
}
.swiper {
    /*87% 2.33333*/
    height: 100%;
    padding-top: 15px;
    padding-bottom: 20px;
    margin: 0px;
    /*
    margin-top: 0px;
    margin-left: -40px;
    padding-top: 10px;
    padding-left: 90px;
    padding-bottom: 52px;

     */
}

.swiper-slide {

    text-align: left;
    font-size: 10px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

/*.swiper-slide img {*/
/*    display: block;*/
/*    width: 140px;*/
/*    height: 185px;*/
/*    object-fit: contain;*/
/*}*/

.swiper-pagination-bullet {
    width: 40px;
    height: 6px;
    border-radius: 23px;
    display: none;
}

.swiper-pagination-bullet-active {
    background: #D9D9D9;
    width: 40px;
    height: 6px;
    border-radius: 23px;
    display: none;
}

.swiperSeeMoreContainer {
    background: #FFFFFF;
    filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.12));
    border-radius: 9px;
    min-width: 150px;
    width: 150px;
    max-width: 150px;
    height: 200px;
    font-size: 10px;
    line-height: 12px;
    margin-right: 5px;
    text-align: center;
    padding: 10px 8px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    cursor: pointer;
}

.swiperSeeMoreContainer__text {
    font-size: 14px;
    line-height: 150%;
}

.swiperSeeMoreContainer__button {
    width: 80%;
    padding: 10px;
    color: white;
    background-color: #0193A3;
    border: none;
    border-radius: 10px;
    font-size: 14px;
}