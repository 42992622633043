#logo{
    display: flex;
    align-items: center;
    justify-content: center;
}
#usermenu-container {
    background-color: white;
    display: grid;
    grid-template-columns: max(100px) 1fr max(100px);
    justify-content: space-between;
    padding: 9px 20px 5px;
    margin: 0;
}
#usermenu-container li:first-child{
}
#usermenu-container li:last-child{
    display: flex;
    justify-content: right;
}
#menu-location {
    width: inherit;
    gap: 5px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    white-space: nowrap;
    overflow: hidden;
    justify-content: center;
}
#menu-location:hover{

}
#menu-location > div:first-of-type{
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 150px;
}
.button {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px;
    border-radius: 6px;
    height: 24px;
}
.login {
    color: white;
    background-color: #333333;
    width: 95px;
}
.login:hover{
    color: white;
    background-color: #333333;
}
.register {
    color: #333333;
    background-color: white;
    width: 105px;
}