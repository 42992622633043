.admin-suppliers-main{
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
}
.admin-suppliers-content-main{
    flex: 1;
    margin-top: 50px;
    width: 72%;
    margin-right: 136px;
    margin-left: 136px;

}

.admin-suppliers-content-main > .Searchbar{
    box-sizing: border-box;
    width: 100%;
    margin: 30px 0;
}
.admin-suppliers-content-main > .table-container{
    margin: 0;
    width: 100%;
    max-height: 70vh;
}
.table-iconUser{
    cursor: pointer;
}
.admin-supplier-days-red{
    width: calc(100% + 20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #FFD0D0;
    position: absolute;
}
#TableSuppliers .custom-table-body .searchRow .custom-tbf > div{
    position: relative;
    height: 27px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#TableSuppliers .custom-table-body .searchRow > td > div{
    height: 27px;

}
#TableSuppliers .custom-table-body .searchRow > td > div .table-body-line{
    height: 27px;
}

#TableSuppliers .custom-table-head tr th > div{
    height: 27px;
    padding: 0 10px!important;
}
#TableSuppliers .custom-table-head tr th > div .table-head-line{
    height: 27px!important;
}
#TableSuppliers .custom-table-head > tr .custom-thf > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    white-space: pre-wrap;
    height: 27px;
}
.admin-supplier-ActivateDeactivate{
    width: calc(100% + 10px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.admin-supplier-bonusesCell{
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.admin-supplier-bonuses-cell{
    display: flex;
    flex-direction: column;
}

.admin-supplier-ActivateDeactivate img{
    cursor: pointer;
}

.admin-supplier-bonusesCell img {
    cursor: pointer;
}

.addBonuses-popup-close-wrapper{
    position:fixed;
    background-color:grey;
    top:0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0.33;
    z-index: 12;
}
.addBonuses-popup-wrapper{
    display: flex;
    justify-content: center;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 13;
    opacity: 1;
    padding: 0;
    width: 800px;
    height: 633px;
    border-radius: 15px;
    background: #FFF;
}
.addBonuses-popup-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;
    padding: 40px 90px;
    width: 100%;
}
.addBonuses-popup-navigate-title-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}
.addBonuses-popup-navigate-title-div p{
    color: #333;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
}
.addBonuses-popup-navigate-title-div img{
    cursor: pointer;
}
.addBonuses-popup-title{
    align-items: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    color: #333333;
    max-width: 375px;
    overflow: hidden;
    text-align: center;
}

.addBonuses-popup-stroke{
    width: 100%;
}

.addBonuses-popup-stroke-bonuses{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.addBonuses-popup-freeBonuses{
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: inherit;
    font-size: 16px;
    font-weight: 500;
    gap: 10px;
}

.addBonuses-popup-freeBonuses-checkBox > .ant-checkbox > .ant-checkbox-inner{
    width: 20px!important;
    height: 20px!important;
}

.addBonuses-popup-name{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.addBonuses-popup-hint-text{
    color: #333;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
}

.addBonuses-popup-fio{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.addBonuses-popup-bonuses{
    width: 37%;
    display: flex;
    flex-direction: column;
}
.addBonuses-popup-description-input{
    border-radius: 12px;
    border: 1px solid #B6BABD;
    height: 100px;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    background-color: #fff;
    color: #333333;
    padding: 15px 15px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    position: relative;
    resize: none;
}

.addBonuses-popup-description-input[disabled]{
    color: #7A7A7A;
}

.addBonuses-popup-apply{
    padding: 15px 30px;
    border: 1px solid #333;
    border-radius: 8px;
    background: #333;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    transition: 300ms;
    cursor: pointer;
}
.addBonuses-popup-apply-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.addBonuses-popup-apply-disabled{
    background: rgb(142, 142, 142) !important;
    color: #FFF !important;
    border-color: #333 !important;
}
.addBonuses-popup-apply:hover{
    background: #FFF;
    color: #333;
}
