.product1_wrap-slider {
    position: relative;
    display: block;
    background: #FFFFFF;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    width: 225px;
    height: 306px;
    font-size: 10px;
    line-height: 12px;
    text-align: left;
}
#img_of_product1{
    padding: 20px 42px 28px 43px;
    width: 140px;
    height: 128px;
}
#product1-description_containter{
    padding-left: 21px;
    width: 179px;
    height: 40px;
    overflow: hidden;
}
#product1-description_of_product {
    display: block;
    font-weight: 500;
    font-size: 16.0714px;
    line-height: 20px;
}
.product1-price-div{
    width: 179px;
    padding-left: 21px;
    padding-right: 21px;
}
#product1-price1_and_amount{
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    width: 100%;
}
.product1-price1{
    display: block;
    color: #B8B8B8;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding-top: 3px;
}
#product1-amount{
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #6667AB;
}
.product1-price2{
    display:block;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #6667AB;
}
#product1-supply-company-div{
    position: absolute;
    bottom: 20px;
    display: flex;
    width: inherit;
    margin-top: 5px;
}
#product1-text_supply_company {
    display: block;
    padding-top: 8px;
    padding-left: 21px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-overflow: ellipsis;
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
}
#product1-img_supply_company {
    padding-right: 20px;
    height: 26px;
    width: 26px;
    margin-left: auto;
}
