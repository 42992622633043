.magazin-edit-container {
    width: 100%;
    margin: 96px auto;
    max-width: 930px;
    color: #333333;

}

.magazin-edit-Info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.magazin-edit-navigate{
    position: absolute;
    left: -50px;
    top: 10px;
}
.magazin-edit-navigate-image{
    width: 15px;
    height: 30px;
}
.magazin-edit-names{
    position: relative;
    width: 49%;
}
.magazin-edit-name{
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
}
.magazin-edit-id{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-top: 20px;

    color: #9B9B9B;
}
.magazin-edit-role{
    margin-top: 14px;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
}

.magazin-edit-images{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 49%;
}
.magazin-edit-image-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 49%;
    border: 1px solid #B6BABD;
    border-radius: 12px;
}
.magazin-edit-images-text{
    margin-top: 14px;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
}
.magazin-edit-image-button{
    width: 100%;
    height: 56px;

    border: 0;
    border-radius: 0 0 11px 11px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    background: #333333;
    color: #F1F1F1;
}

.magazin-edit-image{
    width: 140px;
    height: 140px;
    filter: drop-shadow(0px 4px 20px rgba(51, 51, 51, 0.08));
    border-radius: 50%;
    object-fit: cover;
}

.magazin-edit-select-component-storages{
    width: 100%;
    margin-top: 30px;
    height: 86px;
}
.magazin-edit-select-component-storages-add-store{
    background-color: black;
    color: white;
    width: fit-content;
    padding: 10px 50px 10px 50px;
    border-radius: 20px;
    margin: 10px auto;
    font-weight: 700;
    font-size: 16px;
}
.magazin-edit-select-component-input{
    height: 52px
}
.magazin-edit-hr{
    margin: 30px 0;
    background-color: #9B9B9B;
}

.magazin-edit-personal-data{
    display: flex;
    align-items: stretch;
    flex-direction: column;
}
.magazin-edit-header{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
}
.magazin-edit-inputs{
    width: 100%;
    display: flex;
    flex-direction: column;
}
    .magazin-edit-input-text{
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        white-space: nowrap
    }.magazin-edit-input-text{
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        white-space: nowrap
    }
.magazin-edit-full-input{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.magazin-edit-half-inputs{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.magazin-edit-half-input{
    display: flex;
    flex-direction: column;
    width: 49%;
}

.magazin-edit-one-half-input-left{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.magazin-edit-one-half-input-right{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
}
.magazin-edit-half-input{
    width: 49%;
    display: flex;
    flex-direction: column;
}


.magazin-edit-datePicker{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}
.magazin-edit-datePicker-input{
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    height:60px;
    background-color: #fff;
    border: 1px solid #B6BABD;
    border-radius: 10px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    padding: 0 21px;
    width: calc(100% - 43.6px);
}
.magazin-edit-datePicker-image{
    position: absolute;
    right: 0;
    margin-right: 21px;
    color: #333333;
}
.magazin-edit-saveChanges-container{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.magazin-edit-saveChanges-status{
    text-align: center;
    position: absolute;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}
.magazin-edit-saveChanges-button{
    margin-top: 30px;
    height: 60px;

    border: 1px solid #333333;
    border-radius: 12px;

    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    cursor: pointer;
    background-color: #FFFFFF;
    color: #333333;
}

.magazin-edit-saveChanges-button:hover{
    background-color: #333333;
    color: #FFFFFF;
    transition: 300ms;
}
.magazin-edit-saveChanges-button:active{
    background-color: #FFFFFF;
    color: #333333;
}

.magazin-edit-organization-data{
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
.magazin-edit-address-inputs{
    width: 49%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
}
.magazin-edit-address-input-street{
    width: 73%;
}
.magazin-edit-address-input-numHome{
    width: 25%;
}

.magazin-edit-password{
    display: flex;
    flex-direction: column;
    align-items: stretch;
}



