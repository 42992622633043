.admin-productDetails-mainContainer {
    max-width: 1165px;
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px auto;
    padding: 0 138px 30px;
    box-sizing: border-box;
}

.productDetails-navigate-container{
    width: 100%;

    display: flex;
    justify-content: center;
    flex-direction: row;
    position: relative;
}

.productDetails-navigate-image{
    position: absolute;
    left: 0;
    width: 12px;
    height: 27px;
}

.productDetails-header{
    font-weight: 600;
    font-size: 32px;
    line-height: 100%;
}

.productDetails-hint{
    margin-top: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
}

.productDetails-firstContainer{
    display: flex;
    flex-direction: column;
    width: 100% ;
}
.productDetails-patternCard-container{
    width: 20%;
    margin-right: 10px;
}
.productDetails-patternCard{
    height: 225px;
    background: #FFFFFF;
    box-shadow: 0 3.98362px 19.9181px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.productDetails-patternCard-image{
    width: 140px;
    height: 128px;
    background-color: #17A1FA;
}
.productDetails-patternCard-name{
    margin: 0 17px;
    font-weight: 500;
    font-size: 17.0886px;
    line-height: 21px;

    color: #2F2121;
}
.productDetails-productNames{
    width: 100%;
}
.productDetails-productNames-header{
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
}

.productDetails-inputName{
    display: flex;
    flex-direction: column;
}

.productDetails-hint-text{
    margin: 20px 0 10px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.productDetails-categories-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.productDetails-dropDown{
    height: 60px;
}
.productDetails-category{
    width: 50%;
    margin-right: 5px;
}
.productDetails-subcategory{
    width: 50%;
    margin-left: 5px;
}

.productDetails-secondContainer {
    width: 100%;
    display: grid;
    flex-direction: row;
    align-items: flex-end;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
}

.productDetails-photo{
    display: flex;
    flex-direction: column;
}

.productDetails-photo-image{
    height: 200px;

    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #B6BABD;
    border-radius: 12px;
    position: relative;
}
.productDetails-photo-image img{
     width: 100%;
     height: 100%;
     object-fit: contain;
 }
.productDetails-photo-image-dragEnter{
    border-color: transparent;
    outline: 3px dashed black;
    outline-offset: 0;
}
.productDetails-photo-image-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: -1;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}
.productDetails-photo-button{
    margin-top: 10px;
    height: 60px;
    background: #EFEFEF;
    border: 1px solid #B6BABD;
    border-radius: 12px;
    background-image: url('/public/Pictures/reviewIcon.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    cursor: pointer;
}

.productDetails-photo-delete{
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
}

.productDetails-description{
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.productDetails-description-input{
    flex: 1;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    width: 100%;
    background-color: #fff;
    border: 1px solid #B6BABD;
    border-radius: 10px;
    color: #333333;
    padding: 15px 15px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    height: 200px;
    position: relative;
    resize: none;
}
.productDetails-description-input::-webkit-input-placeholder {
    position: absolute;
    bottom: 45%;
    right: 50%;
}
.productDetails-description-input:-moz-placeholder { /* Firefox 18- */
    position: absolute;
    bottom: 50%;
    right: 50%;
}
.productDetails-description-input::-moz-placeholder {  /* Firefox 19+ */
    position: absolute;
    bottom: 50%;
    right: 50%;
}
.productDetails-description-input:focus::-webkit-input-placeholder{
    visibility: hidden;
}
.productDetails-description-input:focus:-moz-placeholder{
    visibility: hidden;
}
.productDetails-description-input:focus::-moz-placeholder{
    visibility: hidden;
}

.productDetails-thirdContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.productDetails-priceContainer{
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: start;
    gap: 10px;
}
.productDetails-priceInputContainer, .productDetails-bonusesInputContainer{
    width: 20%;
}

.productDetails-bonusesInputContainer > .custom-input > .custom-input-field{
    color: #01aba2;
}

.productDetails-button{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    width: 20%;
    height: 60px;
    background: #EFEFEF;
    border: 1px solid #B6BABD;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
}
.productDetails-statusContainer{
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: start;
    gap: 10px;
}
.productDetails-status{
    display: flex;
    flex-direction: column;
    width: calc(40% + 10px);
}
.productDetails-status-input{
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    height: 60px;
    background-color: #fff;
    border: 1px solid #B6BABD;
    border-radius: 10px;
    color: #333333;
    padding-left: 21px;
    box-sizing: border-box;
}
.productDetails-storageContainer{
    width: calc(40% + 10px);
}
.productDetails-admin-buttonSave,
.productDetails-admin-buttonDelete{

    width: 100%;
    margin-top: 40px;
    height: 60px;

    border: 1px solid #333333;
    border-radius: 12px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    background-color: #333333;
    color: #FFFFFF;

}
.productDetails-admin-buttonSave:hover{
    background-color: #FFFFFF;
    color: #333333;
    transition: 300ms;
}
.productDetails-admin-buttonDelete[data-deleted = "false"]{
    background-image: url("/public/Pictures/Trash-white.svg");
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: center center;
}
.productDetails-admin-buttonDelete:hover[data-deleted = "false"]{
    background-image: url("/public/Pictures/Trash-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #FFFFFF!important;
}
.productDetails-admin-buttonDelete[data-deleted = "true"]{
    background-image: url("/public/Pictures/Return-white.svg");
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: 98% center;
    width: 100%;
}
.productDetails-admin-buttonDelete:hover[data-deleted = "true"]{
    background-image: url("/public/Pictures/Return-black.svg");
    background-color: #FFFFFF!important;
    color: #333333;
}

.admin-productDetails-preview-main{
    width: 100%;
    display: flex;
    justify-content: start;
}
.productDetails-previewContainer{
    overflow: hidden;
}

.productDetails-previewContainer[data-visible = "firstLoad"]{
    max-height: 0;
}
.productDetails-previewContainer[data-visible = "true"]{
    animation: animVis 5s forwards;

}
.productDetails-previewContainer[data-visible = "false"]{
    animation: animHid 2s forwards;

}
@keyframes animVis {
    0%  {max-height: 0; overflow: hidden; opacity: 0}
    50% {max-height: 400px; opacity: 0.5}
    100%{overflow: visible; opacity: 1}
}
@keyframes animHid {
    0% {max-height: 400px; overflow: hidden; opacity: 1}
    50%{max-height: 0; opacity: 0}
    100%{max-height: 0; overflow: hidden;}
}

.productDetails-errorPage-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin: 90px auto 0px auto;
}
.productDetails-error-text{
    margin-top: 40px;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
}
.productDetails-error-button{
    width: 60%;
    margin-top: 40px;
    height: 60px;

    border: 1px solid #333333;
    border-radius: 12px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    background-color: #333333;
    color: #FFFFFF;
}
.productDetails-error-button:hover{
    background-color: #FFFFFF;
    color: #333333;
    transition: 300ms;
}
/**/
.productDetails-buttons-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.productDetails-admin-buttonSave{
    width: 90%;
    flex: 1 1;
}
.productDetails-admin-buttonDelete{
    width: 10%;
}
.productDetails-inputName[data-deleted = "true"] .custom-input .custom-input-field,
.productDetails-dropDown[data-deleted = "true"] .dropdown-internal-container .DropdownDisplay,
.productDetails-description-input[data-deleted = "true"]{
    opacity: 70%;
}

