.user-layout{

}

.user-header{
    position: sticky;
    top: 0;
    z-index: 30;
    height: 52.5px;
    overflow: hidden;
}

.user-content{
    padding: 6px 130px 15px;
}

@media only screen and (max-width: 1200px){
    .user-content{
        padding: 5px 20px;
    }
}

