.deliveryPointPopup-container{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: rgba(51, 51, 51, 0.63);
    z-index: 50;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.deliveryPointPopup-content{
    width: 50%;
    max-width: 700px;
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 12px;
}

.deliveryPointPopup-input-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.deliveryPointPopup-street-number-container{
    display: inline-grid;
    grid-template-columns: 1fr 100px;
    gap: 20px;
}

.deliveryPointPopup-buttons-container{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.deliveryPointPopup-button, .deliveryPointPopup-button-delete{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #333333;
    border-radius: 12px;
    max-width: 50%;
    height: 48px;
    font-family: "Montserrat", sans-serif;
    line-height: 22px;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
    color: #fff;
    border: 1px solid #333333;
    transition: 300ms;
}

.deliveryPointPopup-button:hover{
    color: #333333;
    background: #FFFFFF;
}

.deliveryPointPopup-button-delete{
    width: 50px;
    background-color: #da0000;
    background-image: url("/public/Pictures/Trash-white.svg");
    background-repeat: no-repeat;
    background-size: 25px;
    background-position: center center;
}

.deliveryPointPopup-button-delete:hover{
    background-image: url("/public/Pictures/Trash-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #ffffff !important;
}