.cabinet-wrapper {
    padding: 10px 1rem;
    background: #fff;
    margin: -6px -20px -15px -20px;
}
@media only screen and (min-device-width : 1200px) {
    .cabinet-wrapper {
        padding: 10px 1rem;
        background: #fff;
        margin: -6px -138px -15px -138px;
    }
}

.cabinet-container {
    display: grid;
    grid-template-columns: minmax(250px, 343px) auto;
    flex-wrap: wrap;
}

.cabinet-left {
    display: grid;
    grid-template-rows: repeat(3, auto);
    gap: .5rem;
    padding: .5rem;
    background: #fff;
    border-radius: 11px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    height: min-content;
}

.cabinet-sidebar-provider {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-self: center;
    align-self: center;
}

.cabinet-sidebar-provider img {
    width: 107px;
    height: 107px;
    border-radius: 50%;
    margin-bottom: 5px;
    filter: drop-shadow(0px 13px 5px rgba(0, 0, 0, 0.01))
    drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.02))
    drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.04))
    drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.04));
    border-radius: 100px;
}

.cabinet-sidebar-provider p {
    color: #333333;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
}

.cabinet-sidebar-provider span {
    color: #333333;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.cabinet-sidebar {
    width: 100%;
}

.cabinet-sidebar hr {
    margin: 12px 20px;
    background: #d9d9d9;
}

.cabinet-sidebar-block {
    display: grid;
    margin: 10px;
}

.cabinet-sidebar-item {
    display: grid;
    grid-template-columns: 32px auto 24px;
    align-items: center;
    border-radius: 10px;
    padding: 0 10px;
    height: 38px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.cabinet-sidebar-item P {
    padding-left: 5px;
}

.cabinet-sidebar-item {
    background: #f5f5f5;
}

.cabinet-sidebar-block-helps {
    display: flex;
    flex-direction: column;
    row-gap: 11px;
}

.cabinet-sidebar-block-helps p {
    color: #000;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
}

.cabinet-sidebar-block-helps a {
    font-style: normal;
    background: #f5f5f5;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 38px;
    color: #333333;
}

.cabinet-right {
    display: grid;
    gap: 20px;
    margin: 0 0 0 117px;
}

.cabinet-right p {
    display: inline-block;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 100%;
    color: #333333;
}

.cabinet-provider-card {
    display: grid;
    grid-template-columns: repeat(auto-fit, 194px);
    gap: 20px;
}

.cabinet-provider-card-item {
    gap: 10px;
    display: grid;
    align-items: center;
    height: 194px;
    padding: 15px 5px;
    border-radius: 20px;
    background: #ffffff;
    grid-template-rows: repeat(auto-fit, auto);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}

.cabinet-provider-card .cabinet-provider-card-item > * {
    text-align: center;
    justify-self: center;
}

.cabinet-provider-card .cabinet-provider-card-item p {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    color: #333333;
}

.cabinet-provider-card .cabinet-provider-card-item span {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
}

.cabinet-provider-order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    max-width: 370px;
    min-width: 200px;
    height: 88px;
    background: #333333;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
}

.cabinet-provider-order-info {
    display: flex;
    flex-direction: column;
}

.cabinet-provider-order-info span {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 180%;
    color: #fff;
}

.cabinet-provider-order-info p {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 180%;
}

.cabinet-provider-money-card {
    display: grid;
    grid-template-columns: repeat(auto-fit, 194px);
    gap: 20px;
}

.cabinet-provider-money-card .cabinet-provider-card-item span {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
}
.cabinet-provider-money-card .cabinet-provider-card-item p {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 100%;
    width:154px;
    text-align: center;
}
.cabinet-provider-money-card .cabinet-provider-card-item p[data-size="large"]{
    font-size: 20px;
}