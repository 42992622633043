.profile-container {
    width: 100%;
    box-sizing: border-box;
}
.profile-container[data-isadmin = "true"]{
    padding: 16px 130px 15px;
}
.profile-nameInfo {
    width: 100%;
    margin-top: 20px;
    display: flex;
    height:100px;
}
.profile-image {
    width: 100px;
    height: 100px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 60px;
}
.profile-names {
    position: relative;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    /*padding-top: 15px;*/
}
.profile-name {

}
.profile-id {
    font-size: 10px;
    color: gray;
    font-weight:600;
    position: absolute;
    bottom: 0px;
    width: 260px;
}
.input-header {
    font-size: 25px;
    font-width:600;
    margin: 0 auto 15px auto;
    width: fit-content;
}
.input_text {
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 8px;
    margin-bottom: 5px;
}
.input-field {
    box-sizing: border-box;
    font-size: 14px;
    color: #212529;
    height:42px;
    background-color: #fff;
    border: 1px solid #bdbdbd;
    appearance: auto;
    border-radius: 7px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: inherit;
    padding-left: 9px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}
.select_component {
    box-sizing: border-box;
    width: 100%;
    height: 42px;
}
    .select_component > select {
        margin-top:-3px;
    }
.input_pencil{
    position:relative;
    top:-20px;
    right:40px;
}
.dom-postCode-box {
    display: flex;
}
.postCode-box {
    width: 40%;
}
.dom-box {
    width: 60%;
}
.file-container {
    display:flex;
    padding: 12px 0px 10px 30px;
}
.file-button-box{
    margin:auto;
    width:200px;
}
.diplom-image {
    width: 100px;
    height: 150px;
}
.diplom-Text {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 90%;
    margin: 0px auto 15px auto;
}
.input-file-trigger {
    display: flex;
    padding: 14px 45px;
    background: #D0E2F2;
    color: #5e7293;
    font-size: 1em;
    transition: all .4s;
    cursor: pointer;
    width: min-content;
    max-width: fit-content;
    border: 1px;
    border-radius: 10px;
    padding-left: 30px;
}
.clip_image {
    padding-right: 15px;
}
.upload_button {
    font-size: 17px;
    margin: 5px auto 15px auto;
    width: -moz-fit-content;
    width: fit-content;
    padding: 13px 15px 13px 15px;
    border-radius: 35px;
    color: white;
    text-transform: uppercase;
    border: solid;
    border-top-color: currentcolor;
    border-top-width: medium;
    border-right-color: currentcolor;
    border-right-width: medium;
    border-bottom-color: currentcolor;
    border-bottom-width: medium;
    border-left-color: currentcolor;
    border-left-width: medium;
    border-width: 1px;
    border-color: rgb(51, 51, 51);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.31);
    cursor: context-menu;
}
#upload-message{
    color:green;
    text-align: center;
}

.profile-datePicker{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: inherit;
    box-sizing: border-box;
}
.profile-datePicker input{
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    background-color: #fff;
    border: 1px solid #B6BABD;
    border-radius: 7px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    padding: 3px 2px 3px 9px;
    width: 100%;

    font-family: "Montserrat", sans-serif;
    height: 42px;

}
.profile-datePicker input::-webkit-calendar-picker-indicator{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
    cursor: pointer;
}
.profile-datePicker input::-webkit-inner-spin-button,
.profile-datePicker input::-webkit-clear-button {
    z-index: 1;
}

.profile-datePicker-image{
    position: absolute;
    right: 0;
    margin-right: 21px;
    color: #333333;
    pointer-events: none;
}

.profile-deleteAccount{
    box-sizing: border-box;
    cursor: pointer;
    margin-top: 40px;
    width: 100%;
    background-color: #f35757;
    border: 1px solid #f35757;
    font-size: 16px;
    padding: 5px 10px;
    text-align: center;
    border-radius: 12px;
    color: #FFFFFF;
}

.profile-deleteAccount:hover{
    background-color: #FFFFFF;
    color: #f35757;
}

.user-select-component-input > .dropdown-internal-container > .DropdownDisplay {
    border: 1px solid #bdbdbd;
}

.profileAddress-street-home-container{
    box-sizing: border-box;
    display: grid;
    flex-direction: row;
    width: 100%;
    grid-template-columns: 1fr 20% 20%;
    gap: 5px;
}

.profileAddress-street{
    width: 100%;
}

.profileAddress-input-text{
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 8px;
    margin-bottom: 5px;
}

.profileAddress-home, .profileAddress-apartNum{
    width: 100%;
}

.profileAddress-comment-container{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.profileAddress-comment-input{

    box-sizing: border-box;
    font-size: 14px;
    color: #212529;
    height:84px;
    background-color: #fff;
    border: 1px solid #bdbdbd;
    appearance: auto;
    border-radius: 7px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: inherit;
    padding-left: 9px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}