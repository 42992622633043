
.bdproTab_edit{
    font-weight: 500;
    padding: 7px 10px;
    border-color: black;
    border-style: solid;
    border-radius: 20px;
    border-width: 1px;
}
.bdproTab_edit:hover{
    background:lightgray;
}
.bdproTab_delete{
    margin-left: 10px;
}
.bdproTab_delete > img:last-child{
    display: none;
}
.bdproTab_delete:hover > img:first-child{
    display: none;
}
.bdproTab_delete:hover > img:last-child{
    display: block;
}
.bdproTab_disField{
    height:28px;
    min-width:85px;
    line-height: 26px;
}

.bdproTab_subHeader{
    margin-left:30px;
    margin-bottom:5px;
    font-size: 16px;
    line-height: 20px;
    font-weight:500;
}

.TableLongPopUpStart{
    position:absolute;
    overflow: hidden;
    max-width:200px;
    text-overflow: ellipsis;
    margin-left:auto;
    margin-right:auto;
    font-weight: 300;
}
.TableLongPopUpStart:hover{
    position: absolute;
    top:-16px;
    left:0px;
    max-width:300px;
    width:400px;
    padding:10px;
    border-radius: 15px;
    border-style: solid;
    border-width: 1px;
    overflow: visible;
    text-overflow: unset;
    background-color: white;
    white-space: normal;
    height: auto;
}
.SearchbarTitle{
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left:10px;
}
.hoverChild{
    position: relative;
    overflow: hidden;
    max-width: 120px;
    margin: auto;
}
.hoverChild:hover > .child4hover{

    -webkit-transition: right 0.5s ease-in-out;
    -moz-transition: right 0.5s ease-in-out;
    -o-transition: right 0.5s ease-in-out;
    transition: right 0.5s ease-in-out;
    right:0px;

}
.child4hover{
    position: absolute;
    overflow: hidden;
    top: 0px;
    right: -120px;
    height: 28px;
    width: 100%;
    text-align: center;
    line-height: 26px;
    cursor: pointer;
    color: white;
    font-weight: 600;
}
.backgroundGreen{
    background-color: #50B02E;
}
.backgroundRed{
    background-color: #D43737;
}

.supplierProducts-table-buttonEdit{
    height: 20px;

}

.supplierProducts-supplier-buttonDelete{
    background-color: #FFFFFF;
    /*color: #333333;*/
    transition: 300ms;
    border-color: black;
    border-style: solid;
    border-radius: 50%;
    width: 24px!important;
    border-width: 1px;
    
}

.supplierProducts-supplier-buttonDelete:hover[data-deleted = "false"]{
    background-image: url("/public/Pictures/Trash-white.svg");
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center center;
    background-color: #333333!important;
}
.supplierProducts-supplier-buttonDelete[data-deleted = "false"]{
    background-image: url("/public/Pictures/Trash-black.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10px;
    /*background-color: #FFFFFF!important;*/
}

.supplierProducts-supplier-buttonDelete[data-deleted = "true"]{
    background-image: url("/public/Pictures/Return-white.svg");
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 98% center;
    width: 100%;
}
.supplierProducts-supplier-buttonDelete[data-deleted = "true"]{
    background-image: url("/public/Pictures/Return-black.svg");
    background-color: #FFFFFF!important;
    color: #333333;
}

.supplierProducts-supplier-buttonDelete{
    width: 10%;
}

.supplierProducts-amount{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.supplierProducts-price{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.supplierProducts-price > div{
    display: flex;
    align-items: center;
    justify-content: center;
}

.supplierProducts-table-bonuses{
    color: #01aba2;
}

.suppliersProducts-topCatName{
    font-size: 20px;
    margin: 20px 0 10px 0;
}



.suppliersProducts-serviceNewClient{
    margin: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    width: min-content;
    background-color: #fff;
    border: 1px solid #b6babd;
    border-radius: 10px;
    color: #333333;
    padding: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    position: relative;
    resize: none;

}

.suppliersProducts-serviceNewClient-title{
    position: absolute;
    top: -10px;
    padding: 1px;
    font-weight: 500;
    font-size: 14px;
    background: #FFFFFF;
}

.suppliersProducts-serviceNewClient-buttons{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.suppliersProducts-serviceNewClient-button{
    cursor: pointer;
    width: min-content;
    white-space: nowrap;
    background: #FFFFFF;
    padding: 2px 10px;
    color: #333333;
    border: 1px solid #333333;
    border-radius: 12px;
    transition: 300ms;
}

.suppliersProducts-serviceNewClient-button:hover{
    color: #FFFFFF;
    background: #333333;
}

.suppliersProducts-serviceNewClient-description {
    width: 50%;
    min-width: 500px;
    list-style: initial;
    font-size: 11px;
    color: #7a7a7a;
}