.customerDialogPage{
    width: 100%;
    height: calc(100dvh - 52.5px);
    left: 0;
    position: absolute;
    top: 52.5px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
}

.chat-list {
    padding: 0;
    margin: 20px -20px;
}
.Customer-Chat > .allMessages .chatInput_form {
    margin: 0 -20px 0;
}
.Customer-Chat > .chat_container{
    margin-top: 40px;
}
.chat-list .chat-window-item-div{
    box-shadow: rgba(51, 51, 51, 0.3) 0 2px 2px;
    margin-bottom: 3px;
}
.Customer-Chat > .chat_container > .chatInput_container > .chatInput_form > .message-text-container {
    position: fixed;
    bottom: 0;
    left: 0;
}

.chat-card {
    display: flex;
    align-items: center;
    padding: 6px;
    background: #b1b1b1;
    color: #000000;
    box-sizing: border-box;
    min-width: 370px;
}

.chat-card svg {
    margin-left: auto;
}

.chat-card-unread {
    display: flex;
    align-items: center;
    padding: 6px 6px;
    background: white;
    color: #FFFFFF;
}

.chat-card-unread svg {
    margin-left: auto;
}





.time-read-chat {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
    align-items: center;
}

.chat-time {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
}

.chat-unread-msg {
    width: 19px;
    height: 19px;
    background: #FFFFFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #88E891;
    margin-left: auto;
}

.chat-read {
    margin-left: auto;
}
/*.Customer-Chat .chat_container .chatInput_form{*/
/*    position: unset;*/
/*}*/
.Customer-Chat > .navigate-container-from-chat{
    margin-bottom: 20px;
}
.customer_menu_chat_title{
    margin: 50px 0 20px 0;
    text-transform: uppercase;
    font-size: 20px;
    padding: 15px 17px;
    background-color: rgb(236, 236, 236);;
    border-radius: 10px;
}
