.gallery-thumbnail {
	width: 85px;
	height: 85px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	border: 1px solid #e2e2e2;
	cursor: pointer;
	transition: all .2s;
}

.gallery-thumbnail:hover {
	/*border: 2px solid #0098a3;*/
	box-shadow: rgb(1, 171, 162) 0px 0px 5px;
}

.gallery-thumbnail_active {
	box-shadow: rgb(1, 171, 162) 0px 0px 5px;
	/*border: 2px solid #0098a3;*/
}

.gallery-thumbnail img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}