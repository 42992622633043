.sticky {
	z-index: 28;
	position: sticky;
	top: 52.5px;
	margin: -6px -20px 0px;
}

.container {
	background-color: white;
	display: grid;
	grid-template-columns: 1fr 1fr;
	justify-content: space-between;
	align-items: center;
	padding-left: 15px;
	padding-right: 15px;
	font-weight: 500;
	font-size: 11px;
	line-height: 13px;
	width: 100%;
}

.location {
	cursor: pointer;
	width: fit-content;
	gap: 5px;
	display: flex;
	align-items: center;
	font-weight: 500;
	line-height: 14px;
	padding: 10px 0 15px;
	white-space: nowrap;
	justify-content: center;
	font-size: 14px;
	margin-right: auto;
	
	& > div:first-of-type {
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 200px;
	}
}

.bonuses {
	justify-self: flex-end;
	color: #01aba2;
	font-size: 14px;
	font-weight: 600;
}