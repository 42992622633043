.wrapper {
  width: 95%;
  max-width: 1440px;
  padding: 15px;
}

.container{
  margin: 0 auto -10px auto;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: calc(100dvh - 65px);


  @media screen and (max-width: 450px){
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
    display: unset;
  }
}

.topBlock{
  padding-left: 20px;

  @media screen and (max-width: 450px){
    all: unset;
    display: block;
    padding: 0 20px;
    position: sticky;
    top: 90px;
    left: 0;
    right: 0;
    z-index: 20;
    background: #ffffff;
    width: 100%;
    margin-left: -20px;
    margin-right: -20px;
  }

}

.topBlock:has(.filterPopup.open){
  z-index: 40;
}

.content{
  z-index: 10;
  display: flex;
  flex-direction: column;
  height: min-content;
  flex: 1;
  overflow: hidden;

  @media screen and (max-width: 450px){
    padding: 0;
    height: min-content;
    overflow: unset;
    display: unset;
  }
}

.products, .categories{
  overflow: auto;
  width: 100%;
  padding-top: 15px;
  padding-left: 20px;
  min-height: calc(100dvh - 150px);
  height: min-content;
  max-height: calc(100dvh - 150px);

  @media screen and (max-width: 450px){
    min-height: unset;
    max-height: unset;
    padding: 0;
    height: min-content;
    overflow: unset;
    margin-top: 20px;
  }
}

.categories{
  @media screen and (max-width: 450px) {
    & > div {
      overflow: visible;
    }
  }
}

.products{
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  row-gap: 10px;

}

.productsLoader{
  padding-top: 15px;
  padding-left: 20px;
  width: 100%;
  min-height: calc(100dvh - 200px);
  height: min-content;
  max-height: calc(100dvh - 200px);
  display: flex!important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.categoriesContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
}
