#emptycart-container {
    min-height: 694px;
    margin: -6px -20px -12px;
}

#emptycart-text-div{
    padding-top: 22px;
    padding-bottom: 11px;
    background-color: white;
}
#epty-cart-title-div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /*width: 100%;*/
    align-items: center;
    padding: 7px 20px;
    background-color: white;
    position: sticky;
    top: 52.5px;
    margin: -6px -20px -15px;
    z-index: 10;
}
.emty-cart-menu-a{
    display: flex;
    align-items: center;
    justify-content: center;
}
#empty-h{
    font-size: 14px;
    line-height: 17px;
    text-align: center;
}
#empty-title{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    width: 234px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}
#korzina{
    background: url("/public/Pictures/korzina.png");
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width: 220px;
    display: block;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 34px;
}
.cartempty-button{
    display: flex;
    width: 244px;
    height: 41px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    border-radius: 47px;
    font-size: 14px;
    line-height: 17px;
}
#find-button{
    background: #333333;
    color: #FFFFFF;
}
#katalog-button{
    margin-top: 15px;
    color: #333333;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}

.emptycart-container-clearFilter{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.emptycart-container-clearFilter > button {
    all: unset;
    margin-top: 15px;
    font-size: 18px;
    cursor: pointer;
    background-color: #FFFFFF;
    border-radius: 12px;
    color: #333333;
    border: 1px solid #333333;
    padding: 10px 15px 10px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.emptycart-container-clearFilter > button > img{
    width: 20px;
    margin-right: 10px;
}