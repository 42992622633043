.message-area-popup {
    font-size: 14px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 6px 10px 5px 10px;
    border: none;
    resize: none;
    line-height: 1.5;
    box-sizing: border-box;
    height:85px;
}
.chat-buttonBox{
    display: flex;
    gap: 5px;
    position: relative;
}

.chat-buttonBox > div{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.message_popup_border{
    border-radius: 20px 20px 0 20px;
    width: 25vw;
}
@media only screen and (min-width: 300px) and (max-width: 800px) {
    .message_popup_border {
        width: 45vw;
    }
}
.chat_update_button{

}
.chat_delete_button{
    position: absolute;
    bottom: 0px;
}

.chat_update_button > img{
    width: 25px;
    cursor: pointer;
}
.chat_delete_button > img {
    width: 22px;
    cursor: pointer;
}