.home-cookie-popup{
    box-sizing: border-box;
    padding: 20px;
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FFFFFF;
    font-size: 1.1em;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    gap: 20px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    align-items: center;
    justify-content: center;
}

.home-cookie-popup div:first-child{
    text-align: justify-all;
    max-width: 900px;
}

.home-cookie-popup div:nth-child(2) button{
    all: unset;
    cursor: pointer;
    padding: 10px 30px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    font-family: "Montserrat", sans-serif;
}

.home-cookie-popup div:last-child{
    position: relative;
}

.home-cookie-popup img{
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

@media (max-width: 768px) {
    .home-cookie-popup{
        flex-direction: column;
    }
}