.order-status-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.order-status-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 48px;
}

.order-status-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin-bottom: 8px;
    text-align: center;
}

.order-status-logo img {
    width: 18px;
    height: 18px;
}

.order-status-title {
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    margin-bottom: 4px;
    max-width: 48px;
}

.order-status-time {
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #666;
    text-align: center;
}

.order-status-content{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.order-status-grey {
    background-color: #333333;
}

.order-status-green {
    background-color: #508E46;
}

.order-status-red {
    background-color: #8E4646;
}

.order-status-container {
    position: relative;
    display: block;
    padding-bottom: 100px;
}

.order-status-string {
    margin-top: 10px;
    display: flex;
    justify-content: left;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.order-status-title-string {
    margin-right: 4px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
}

.order-status-upper-title {
    margin-bottom: 15px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
}

@media screen and (max-width: 450px){
    .order-status-list{
        margin: 0 -5px;
    }
}
