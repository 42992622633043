.button-to-cart-div{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
}
.button-to-cart{
    cursor: pointer;
    width: 110px;
    height: 26px;
    border-radius: 34px;
    font-weight: 700;
    font-size: 11px;
    line-height: 26px;
    background-color: #333333;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25),
    inset 1px 1px 4px rgba(255, 255, 255, 0.25),
    inset -1px -1px 4px rgba(0, 0, 0, 0.25);
    color:white;
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;
}