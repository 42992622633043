.chatInput_form {

    display: flex;
    justify-content: center;
    bottom: 0;
}
.supplier-width {
    position: unset;
    width: 100%;
}
.customer-width {
    width: 100%;

}

.message-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    /*position: fixed;*/
    /*bottom: 0;*/
    width: 100%;
    padding: 13px 27px 12px 20px;
    box-sizing: border-box;

    background-color: #333333;
}


.message-area {
    /*width: 304px;*/
    font-size: 14px;
    width: 100%;
    height: 32px;
    background: #FFFFFF;
    border-radius: 10px;
    /*line-height: 32px;*/
    padding: 6px 10px 5px 10px;
    border: none;
    resize: none;
    line-height: 1.5;
    box-sizing: border-box;
    max-height: calc(1.5em * 3);
}

.message-area:focus {
    outline: none;
}

.send-message-button {
    width: 31px;
    height: 32px;
    background-color: #333333;
    border: none;
    cursor: pointer;
    padding: 0;
}

.send-message-button svg {
    width: 100%;
    height: 100%;
}